import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";

type RemoveContactDialogProps = {
    contact: any,
    customerAccount: any,
    handleCloseDialog: () => {}
}

const RemoveContactDialog: React.FC<RemoveContactDialogProps> = (props) => {
    const contact = useFragment(
        graphql`
            fragment RemoveContactDialog_contact on Contact {
                id
                __typename
                ... on PersonContact {
                    givenName
                    familyName
                }
                ... on TeamContact {
                    teamName
                }
            }
        `,
        props.contact
    );

    const customerAccount = useFragment(
        graphql`
            fragment RemoveContactDialog_customerAccount on CustomerAccount {
                id
                customerName
            }
        `,
        props.customerAccount
    );

    const relayEnvironment = useRelayEnvironment();
    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation RemoveContactDialogMutation($input: RemoveContactInput!) {
                        removeContact(input: $input) {
                            contacts {
                                totalCount
                                edges {
                                    cursor
                                    roles {
                                        roleIdentifier
                                        label {
                                            en
                                        }
                                    }
                                    node {
                                        id
                                        __typename
                                        ... on PersonContact {
                                            givenName
                                            familyName
                                        }
                                        ... on TeamContact {
                                            teamName
                                        }
                                        emailAddress
                                        phoneNumber
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        customerAccountIdentifier: customerAccount.id,
        contactIdentifier: contact.id
    }

    const validationSchema = yup.object().shape({});

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} validationSchema={validationSchema} headline="Remove Contact" submitLabel="Remove" dangerous>
            <Section>
                <Row>
                    <p>Remove <strong>{contact.teamName ?? (contact.givenName + " " + contact.familyName)}</strong> from <strong>{customerAccount.customerName}</strong>?</p>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default RemoveContactDialog;
