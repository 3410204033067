import * as React from "react";
import {useState} from "react";
import Panel from "./presentational/Panel";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import {graphql} from "react-relay";
import Headline from "./presentational/Headline";
import {faPlusCircle} from "@fortawesome/pro-regular-svg-icons";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import ActionButton from "./presentational/button/ActionButton";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import ListSearch from "./presentational/ListSearch";
import ProjectList from "./ProjectList";
import ProjectsQuery from "../__relay_artifacts__/ProjectsViewProjectsQuery.graphql";
import CreateProjectDialog from "./dialog/CreateProjectDialog";
import type {ProjectsViewProjectsQuery as ProjectsViewProjectsQueryType} from "../__relay_artifacts__/ProjectsViewProjectsQuery.graphql";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type Props = {
    prepared: any
};

type ProjectsViewProps = ConnectedProps<typeof connector> & Props;

const ProjectsView: React.FC<ProjectsViewProps> = (props) => {
    const {dispatchShowModal, dispatchHideModal} = props
    const [searchTerm, setSearchTerm] = useState('');

    const query = usePreloadedQuery<ProjectsViewProjectsQueryType>(
        graphql`
            query ProjectsViewProjectsQuery {
                ...ProjectList_query
            }
        `,
        props.prepared.projectsQuery,
    );

    const searchAPIDebounced = AwesomeDebouncePromise(
        searchTerm => {
            setSearchTerm(searchTerm)
        },
        500
    );

    const handleSearchTermChange = async (searchTerm) => {
        await searchAPIDebounced(searchTerm);
    };

    const showCreateProjectDialog = () => {
        dispatchShowModal({
            body: (<CreateProjectDialog handleCloseDialog={dispatchHideModal} query={query}/>)
        })
    };

    return (
        <div className="lg:mt-6 mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <Panel>
                <div className="px-6 pt-6 pb-4">
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <Headline>Projects</Headline>
                        </div>
                        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                            <ActionButton icon={faPlusCircle} title="Create project" onClick={() => {
                                showCreateProjectDialog()
                            }}/>
                        </div>
                    </div>
                    <ListSearch handleSearchTermChange={handleSearchTermChange} placeholder="Search Projects"/>
                </div>
                <ProjectList queryRef={query} searchTerm={searchTerm}/>
            </Panel>
        </div>
    )
}


export default connector(ProjectsView);

function prepare_ProjectsView(params, relayEnvironment) {
    return {
        projectsQuery: loadQuery(
            relayEnvironment,
            ProjectsQuery,
            {},
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_ProjectsView};
