/**
 * @generated SignedSource<<f179333c01fd259b0ee7cefda6ebf45d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CancelSubscriptionDialog_subscription$data = {
  readonly customerAccount: {
    readonly customerName: string;
  };
  readonly id: string;
  readonly subscriptionService: {
    readonly name: string;
  };
  readonly subscriptionServiceReferenceLabel: string | null;
  readonly " $fragmentType": "CancelSubscriptionDialog_subscription";
};
export type CancelSubscriptionDialog_subscription$key = {
  readonly " $data"?: CancelSubscriptionDialog_subscription$data;
  readonly " $fragmentSpreads": FragmentRefs<"CancelSubscriptionDialog_subscription">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CancelSubscriptionDialog_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriptionService",
      "kind": "LinkedField",
      "name": "subscriptionService",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionServiceReferenceLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};

(node as any).hash = "7e5cfdd2973a18bd10f105668d3de9d4";

export default node;
