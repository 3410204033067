import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";

type SetDefaultPaymentMethodDialogProps = {
    customerAccount: any,
    paymentMethod: any,
    handleCloseDialog: () => {}
}

const SetDefaultPaymentMethodDialog: React.FC<SetDefaultPaymentMethodDialogProps> = (props) => {

    const customerAccount = useFragment(
        graphql`
            fragment SetDefaultPaymentMethodDialog_customerAccount on CustomerAccount {
                id
                customerName
            }
        `,
        props.customerAccount
    );

    const paymentMethod = useFragment(
        graphql`
            fragment SetDefaultPaymentMethodDialog_paymentMethod on PaymentMethod {
                id
                paymentMethodType
                cardBrand
                lastFourDigits
            }
        `,
        props.paymentMethod
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation SetDefaultPaymentMethodDialogMutation(
                        $input: SetDefaultPaymentMethodInput!
                    ) {
                        setDefaultPaymentMethod(input: $input) {
                            defaultPaymentMethod {
                                id
                            }
                            paymentMethods {
                                id
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        customerAccountIdentifier: customerAccount.id,
        paymentMethodIdentifier: paymentMethod.id
    }

    let paymentMethodType;
    switch (paymentMethod.paymentMethodType) {
        case 'Card': {
            switch (paymentMethod.brand) {
                case 'visa':
                    paymentMethodType = 'Visa credit card';
                    break;
                case 'mastercard':
                    paymentMethodType = 'Mastercard credit card';
                    break;
                default:
                    paymentMethodType = 'credit card';
                    break;
            }
            break;
        }
        case 'SepaDebit': {
            paymentMethodType = 'SEPA direct debit';
            break;
        }
        default: {
            paymentMethodType = 'unknown payment method';
        }
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Set Default Payment Method" submitLabel="Set as default">
            <Section>
                <Row>
                    <div className="text-gray-900 font-medium">
                        Set {paymentMethodType} ending with {paymentMethod.lastFourDigits} as the default payment method for {customerAccount.customerName}?
                    </div>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default SetDefaultPaymentMethodDialog;
