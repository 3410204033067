import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";

type CustomerAccountHeaderProps = {
    customerAccount: {
        id: string
        customerName: string
    }
}

const CustomerAccountHeader: React.FC<CustomerAccountHeaderProps> = ({customerAccount}) => {
    return (
        <div className="lg:max-w-6xl lg:mx-auto">
            <div className="py-4 md:flex md:items-center md:justify-between">
                <div className="flex-1 min-w-0">
                    <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                        <div className="flex items-center space-x-5">
                            <div className="shrink-0">
                                <span className="inline-flex items-center justify-center h-12 w-12 sm:h-18 sm:w-18 rounded-full bg-red-600">
                                   <span className="text-2xl sm:text-4xl -mt-1 font-bold leading-none text-white">{customerAccount.customerName.substring(0, 1)}</span>
                                </span>
                            </div>
                            <div>
                                <h1 className="text-xl sm:text-2xl font-bold text-gray-900">{customerAccount.customerName}</h1>
                                <p className="text-sm font-medium text-gray-500">{customerAccount.id}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default createFragmentContainer(CustomerAccountHeader, {
        customerAccount: graphql`
            fragment CustomerAccountHeader_customerAccount on CustomerAccount {
                id
                customerName
                contacts {
                    totalCount
                }
            }
        `
    }
);
