import * as React from "react";
import {Field as FormikField} from "formik";


const Checkbox = ({name, ...attributes}) => {
    return (
        <FormikField type="checkbox" name={name} {...attributes} className="focus:ring-red-500 h-4 w-4 text-red-600 border-gray-300 rounded"/>
    );
}

export default Checkbox;
