/**
 * @generated SignedSource<<d47efd4bb2c6037649eae938252ba780>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateTeamContactDialogProps_contact$data = {
  readonly emailAddress: string | null;
  readonly id: string;
  readonly phoneNumber: string | null;
  readonly teamName: string;
  readonly " $fragmentType": "UpdateTeamContactDialogProps_contact";
};
export type UpdateTeamContactDialogProps_contact$key = {
  readonly " $data"?: UpdateTeamContactDialogProps_contact$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdateTeamContactDialogProps_contact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdateTeamContactDialogProps_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teamName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "TeamContact",
  "abstractKey": null
};

(node as any).hash = "c9ee012a48d6bf6dcb8be89b94d53335";

export default node;
