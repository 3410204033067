import * as React from "react";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../../store/actions";
import CreatePersonContactDialog from "./CreatePersonContactDialog";
import CreateTeamContactDialog from "./CreateTeamContactDialog";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type CreateContactDialogProps = {
    handleCloseDialog: () => {}
}

type ConnectedCreateContactDialogProps = CreateContactDialogProps & ConnectedProps<typeof connector>;

const CreateContactDialog: React.FC<ConnectedCreateContactDialogProps> = ({dispatchShowModal, dispatchHideModal}) => {
    const showCreatePersonContactDialog = () => {
        dispatchShowModal({
            body: (<CreatePersonContactDialog handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const showCreateTeamContactDialog = () => {
        dispatchShowModal({
            body: (<CreateTeamContactDialog handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* Overlay which darkens the background below the modal */}
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"/>
                </div>
                {/* The following span is a trick to center the modal dialog vertically */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="dialog-headline">
                    <div>
                        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 text-4xl">
                            <span role="img" aria-label="question">🙋🏻</span>
                        </div>
                        <div className="mt-3 text-center sm:mt-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                                Create Contact
                            </h3>
                            <div className="mt-2">
                                <p className="text-sm text-gray-500">
                                    Is the new contact a real person or for a team or department?
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                        <button type="button" onClick={showCreatePersonContactDialog} className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm">
                            Person
                        </button>
                        <button type="button" onClick={showCreateTeamContactDialog} className="mt-6 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-2 sm:text-sm sm:mt-0">
                            Team
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default connector(CreateContactDialog);
