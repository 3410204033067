/**
 * @generated SignedSource<<f93a88d649c1595d2dde077158f5362d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactViewQuery$variables = {
  id: string;
};
export type ContactViewQuery$data = {
  readonly contact: {
    readonly __typename: string;
    readonly familyName?: string;
    readonly givenName?: string;
    readonly id: string;
    readonly teamName?: string;
    readonly " $fragmentSpreads": FragmentRefs<"ContactDataList_contact" | "ContactHeader_contact">;
  } | null;
};
export type ContactViewQuery = {
  response: ContactViewQuery$data;
  variables: ContactViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "givenName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "familyName",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "teamName",
      "storageKey": null
    }
  ],
  "type": "TeamContact",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "type": "PersonContact",
            "abstractKey": null
          },
          (v6/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContactHeader_contact"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ContactDataList_contact"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ContactViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "contact",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isContact"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "emailAddress",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "phoneNumber",
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "salutation",
                "storageKey": null
              }
            ],
            "type": "PersonContact",
            "abstractKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "119210badb0271b57bd1f0036428082c",
    "id": null,
    "metadata": {},
    "name": "ContactViewQuery",
    "operationKind": "query",
    "text": "query ContactViewQuery(\n  $id: ID!\n) {\n  contact(id: $id) {\n    __typename\n    id\n    ... on PersonContact {\n      givenName\n      familyName\n    }\n    ... on TeamContact {\n      teamName\n    }\n    ...ContactHeader_contact\n    ...ContactDataList_contact\n  }\n}\n\nfragment ContactDataList_contact on Contact {\n  __isContact: __typename\n  id\n  __typename\n  ... on PersonContact {\n    salutation\n    givenName\n    familyName\n  }\n  ... on TeamContact {\n    teamName\n  }\n  emailAddress\n  phoneNumber\n  ...UpdatePersonContactDialog_contact\n  ...UpdateTeamContactDialogProps_contact\n}\n\nfragment ContactHeader_contact on Contact {\n  __isContact: __typename\n  id\n  ... on PersonContact {\n    givenName\n    familyName\n  }\n  ... on TeamContact {\n    teamName\n  }\n}\n\nfragment UpdatePersonContactDialog_contact on PersonContact {\n  id\n  salutation\n  givenName\n  familyName\n  emailAddress\n  phoneNumber\n}\n\nfragment UpdateTeamContactDialogProps_contact on TeamContact {\n  id\n  teamName\n  emailAddress\n  phoneNumber\n}\n"
  }
};
})();

(node as any).hash = "c36cf0f5596570f54906489151fae67f";

export default node;
