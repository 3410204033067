import * as React from "react";
import {useEffect, useState} from "react";
import {commitMutation, graphql} from "react-relay";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import InvoiceHeader from "./InvoiceHeader";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import {useOutletContext} from "react-router";
import Panel from "./presentational/Panel";
import {FormattedDate} from "react-intl";
import DataListItem from "./presentational/DataListItem";
import {Document, Page} from 'react-pdf'; // see https://github.com/wojtekmaj/react-pdf
import {FinalInvoiceViewDownloadPdfMutation} from "../__relay_artifacts__/FinalInvoiceViewDownloadPdfMutation.graphql";
import ActionButton from "./presentational/button/ActionButton";
import {faFilePdf} from "@fortawesome/pro-solid-svg-icons";
import {FinalInvoiceViewRefreshPdfMutation} from "../__relay_artifacts__/FinalInvoiceViewRefreshPdfMutation.graphql";
import {faBuildingColumns, faCreditCard, faEnvelope, faMoneyBillTransfer, faMoneyBillWave, faRectangleXmark, faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import SendInvoiceDialog from "./dialog/SendInvoiceDialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NoData from "./presentational/NoData";
import {isAfter, parseISO} from "date-fns";
import StatusBadge from "./presentational/StatusBadge";
import Dinero from "dinero.js";
import ActivityListItem from "./presentational/ActivityListItem";
import {faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import CreateReverseInvoiceDialog from "./dialog/CreateReverseInvoiceDialog";
import InitiateInvoicePaymentDialog from "./dialog/InitiateInvoicePaymentDialog";

type FinalInvoiceViewParams = {};

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type FinalInvoiceViewProps = FinalInvoiceViewParams & ConnectedProps<typeof connector>;

const FinalInvoiceView: React.FC<FinalInvoiceViewProps> = (props) => {
    const outletContext: any = useOutletContext();
    const {queryRef, refetch} = outletContext;
    const relayEnvironment = useRelayEnvironment();
    const [invoicePdfUri, setInvoicePdfUri] = useState();
    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

    const {dispatchShowModal, dispatchHideModal} = props

    const handleDownloadPdf = (invoiceIdentifier: string, setIsDownloadingPdf) => {
        setIsDownloadingPdf(true)
        commitMutation<FinalInvoiceViewDownloadPdfMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation FinalInvoiceViewDownloadPdfMutation(
                        $id: ID!
                    ) {
                        downloadInvoicePdf(
                            input: {
                                id: $id
                            }
                        ) {
                            uri
                            filename
                        }
                    }
                `,
                variables: {
                    id: invoiceIdentifier
                },
                onCompleted: (response, errors) => {
                    setIsDownloadingPdf(false)
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    if (response.downloadInvoicePdf?.uri) {
                        window.location.href = response.downloadInvoicePdf?.uri;
                    }
                },
                onError: (error) => {
                    setIsDownloadingPdf(false)
                    console.error(error);
                }
            }
        )
    }

    const invoice = useFragment(
        graphql`
            fragment FinalInvoiceView_invoice on Invoice {
                ...InvoiceHeader_invoice
                ...InvoiceLineItemsTable_invoice
                ...InitiateInvoicePaymentDialog_invoice
                ...SendInvoiceDialog_invoice
                ...CreateReverseInvoiceDialog_invoice
                id
                type
                version
                invoiceNumber
                invoiceDate
                state
                timeOfPerformanceStart
                timeOfPerformanceEnd
                language
                taxType
                taxTypeNote
                customerAccount {
                    customerNumber
                    vatId
                    taxStatus
                }
                address {
                    businessName
                    addressLine1
                    addressLine2
                    postalCode
                    city
                    country
                }
                paymentNote
                paymentMethod {
                    id
                    paymentMethodType
                    paymentServiceType
                    paymentTerms
                    cardBrand
                    expirationDate
                    lastFourDigits
                }
                purchaseOrderReference
                customerOrderNotes
                attachments {
                    sha1
                    filename
                    size
                }
                documentSha1
                documentRenderedAt
                documentRenderedVersion
                sentAt
                totalGrossAmount
                paymentIntents {
                    id
                    status
                    createdAt
                    succeededAt
                    canceledAt
                    charges {
                        state
                        id
                        paid
                        createdAt
                        disputed
                        paymentMethod {
                            paymentMethodType
                            paymentServiceType
                            cardBrand
                            lastFourDigits
                        }
                    }
                }
                refunds {
                    id
                    status
                    amount
                    createdAt
                    succeededAt
                }
                relatedInvoice {
                    id
                    invoiceNumber
                }
            }
        `,
        queryRef
    );

    useEffect(() => {
        const documentRenderedAt = parseISO(invoice.documentRenderedAt);
        const updatedAt = parseISO(invoice.updatedAt);
        const refreshPdfUri = (invoiceIdentifier: string, setInvoicePdfUri) => {
            commitMutation<FinalInvoiceViewRefreshPdfMutation>(
                relayEnvironment,
                {
                    mutation: graphql`
                        mutation FinalInvoiceViewRefreshPdfMutation(
                            $id: ID!
                        ) {
                            downloadInvoicePdf(
                                input: {
                                    id: $id
                                }
                            ) {
                                uri
                                filename
                            }
                        }
                    `,
                    variables: {
                        id: invoiceIdentifier
                    },
                    onCompleted: (response, errors) => {
                        if (errors && errors.length > 0) {
                            console.error(errors);
                        }
                        setInvoicePdfUri(response.downloadInvoicePdf?.uri)
                    },
                    onError: (error) => {
                        console.error(error);
                    }
                }
            )
        }

        if (!isAfter(updatedAt, documentRenderedAt) && invoicePdfUri === undefined) {
            refreshPdfUri(invoice.id, setInvoicePdfUri);
        }
    }, [invoice, invoicePdfUri, setInvoicePdfUri, relayEnvironment]);

    const documentRenderedAt = parseISO(invoice.documentRenderedAt);
    const updatedAt = parseISO(invoice.updatedAt);
    const sentAt = invoice.sentAt ? parseISO(invoice.sentAt) : undefined;
    const totalGrossAmount = Dinero({amount: invoice.totalGrossAmount});

    if (invoicePdfUri !== undefined && (invoice.documentRenderedVersion === null || isAfter(updatedAt, documentRenderedAt))) {
        setInvoicePdfUri(undefined);
    }

    const breadcrumbNavigationItems = [
        {label: 'Invoices', to: '/invoices'},
        {label: invoice.id, to: '/invoices/' + invoice.id},
    ]

    return (
        <article>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <InvoiceHeader invoice={invoice}/>
            <main className="max-w-5xl mx-auto grid grid-cols-8 gap-x-4 gap-y-8 py-8">
                <div className="col-span-5">
                    <Panel>
                        <Document
                            className="h-[890px] py-4"
                            file={invoicePdfUri}
                            loading={(
                                <div className="text-center">
                                    <FontAwesomeIcon icon={faSpinnerThird} spin={true}/>
                                </div>
                            )}
                            noData={(<NoData headline="" description={(
                                <>
                                    <ActionButton label="Refresh" onClick={refetch}/>
                                </>
                            )}/>)}
                            error={""}
                            onLoadError={(error) => {
                                console.log('onLoadError', error)
                            }}
                            onSourceError={(error) => {
                                console.log('onSourceError', error)
                            }}
                        >
                            <Page pageNumber={1} renderTextLayer={false} renderAnnotationLayer={false}></Page>
                        </Document>
                    </Panel>
                </div>
                <div className="col-span-3">
                    <Panel buttons={(
                        <>
                            {invoice.type === 'REGULAR' && (<ActionButton icon={faRectangleXmark} iconSize={"lg"} title="Reverse invoice…" onClick={() => {
                                dispatchShowModal({
                                    body: (<CreateReverseInvoiceDialog handleCloseDialog={dispatchHideModal} queryRef={invoice}/>)
                                });
                            }}/>)}
                            {invoice.type === 'REGULAR' && invoice.state === 'OPEN' && (<ActionButton icon={faMoneyBillWave} iconSize={"lg"} title="Initiate payment…" onClick={() => {
                                dispatchShowModal({
                                    body: (<InitiateInvoicePaymentDialog handleCloseDialog={dispatchHideModal} queryRef={invoice}/>)
                                });
                            }}/>)}
                            <ActionButton icon={faEnvelope} iconSize={"lg"} title="Send to customer…" onClick={() => {
                                dispatchShowModal({
                                    body: (<SendInvoiceDialog handleCloseDialog={dispatchHideModal} queryRef={invoice}/>)
                                });
                            }}/>
                            <ActionButton isSubmitting={isDownloadingPdf} icon={faFilePdf} iconSize={"lg"} title="Download PDF" onClick={() => {
                                handleDownloadPdf(invoice.id, setIsDownloadingPdf)
                            }}/>
                        </>
                    )}>
                        <div className="mt-6 space-y-4 border-t border-gray-200 py-2">
                            <dl>
                                <DataListItem label="State" condensed={true}>
                                    <StatusBadge className="-ml-2" states={{green: ["PAID", "REFUNDED"], red: ["UNCOLLECTIBLE"], yellow: ["VOID", "REVERSED"]}} value={invoice.state}/>
                                </DataListItem>
                                <DataListItem label="Invoice number" condensed={true}>{invoice.invoiceNumber ?? '-'}</DataListItem>
                                <DataListItem label="Invoice date" condensed={true}><FormattedDate value={invoice.invoiceDate}/></DataListItem>
                                {invoice.relatedInvoice !== null && (<DataListItem label="Related invoice" condensed={true}>{invoice.relatedInvoice.invoiceNumber ?? '-'}</DataListItem>)}
                                <DataListItem label="Customer number" condensed={true}>{invoice.customerAccount.customerNumber}</DataListItem>
                                <DataListItem label="VAT ID" condensed={true}>{invoice.customerAccount.vatId ?? '-'}</DataListItem>
                                <DataListItem label="Total gross amount" condensed={true}>{totalGrossAmount.toFormat()}</DataListItem>
                            </dl>
                        </div>
                        {sentAt && (
                            <div className="mt-6 space-y-4 border-t border-gray-200 py-2">
                                <ul className="-mb-8 mt-4">
                                    <ActivityListItem
                                        icon={faEnvelope}
                                        date={sentAt}
                                        states={{green: ["SENT"]}}
                                        state="SENT"
                                        isLastItem={true}
                                        datePreText="was sent on"
                                    >Invoice Email</ActivityListItem>
                                </ul>
                            </div>
                        )}
                        {invoice.paymentIntents[0] !== null && (
                            <div className="mt-6 space-y-4 border-t border-gray-200 py-2">
                                <ul className="-mb-8 mt-4">
                                    {invoice.paymentIntents.map((paymentIntent, index) => {
                                        const createdAt = paymentIntent.createdAt ? parseISO(paymentIntent.createdAt) : undefined;
                                        const succeededAt = paymentIntent.succeededAt ? parseISO(paymentIntent.succeededAt) : undefined;
                                        const canceledAt = paymentIntent.canceledAt ? parseISO(paymentIntent.canceledAt) : undefined;
                                        const date = succeededAt ?? canceledAt ?? createdAt;
                                        const datePretext = succeededAt ? 'succeeded at' : canceledAt ? 'was canceled at' : 'was created at'
                                        return (
                                            <div key={index}>
                                                <ActivityListItem
                                                    key={index}
                                                    icon={faMoneyBillWave}
                                                    date={date}
                                                    states={{green: ["SUCCEEDED"], gray: ["PROCESSING", "PENDING"], yellow: ["REQUIRES_ACTION", "REQUIRES_CONFIRMATION", "REQUIRES_PAYMENT_METHOD"], red: ["CANCELED", "UNKNOWN"]}}
                                                    state={paymentIntent.status}
                                                    datePreText={datePretext}
                                                    isLastItem={paymentIntent.charges.length === 0}>
                                                    Payment Intent <span className="text-xs font-light text-gray-500">{paymentIntent.id}</span>
                                                </ActivityListItem>
                                                {paymentIntent.charges.map((charge, chargeIndex) => {
                                                        const date = charge.createdAt ? parseISO(charge.createdAt) : undefined;

                                                        let icon = faMoneyBillTransfer;
                                                        if (charge.paymentMethod.paymentMethodType === 'Card') {
                                                            if (charge.paymentMethod.cardBrand === 'Visa') {
                                                                icon = faCcVisa;
                                                            } else if (charge.paymentMethod.cardBrand === 'Mastercard') {
                                                                icon = faCcMastercard;
                                                            } else {
                                                                icon = faCreditCard;
                                                            }
                                                        } else if (charge.paymentMethod.paymentMethodType === 'SepaDebit') {
                                                            icon = faBuildingColumns;
                                                        }

                                                    const chargeWasDisputedMessage = charge.disputed ? (<p><strong className="text-red-500">This charge was disputed.</strong></p>) : (<></>)

                                                    return (
                                                        <ActivityListItem
                                                            key={chargeIndex}
                                                            icon={icon}
                                                            states={{green: ["SUCCEEDED"], gray: ["PENDING"], red: ["FAILED", "UNKNOWN"]}}
                                                            state={charge.state}
                                                            date={date}
                                                            datePreText='created at'
                                                            isLastItem={chargeIndex === paymentIntent.charges.length - 1}
                                                                details={
                                                                    charge.paymentMethod.paymentMethodType === 'SepaDebit' ?
                                                                        (
                                                                            <>
                                                                                <p>SEPA Direct Debit drawing from bank account ending with {charge.paymentMethod.lastFourDigits}.</p>
                                                                                {chargeWasDisputedMessage}
                                                                            </>
                                                                        ) : charge.paymentMethod.paymentMethodType === 'Card' ?
                                                                            (
                                                                                <>
                                                                                    <p>Charging {charge.paymentMethod.cardBrand} card ending with {charge.paymentMethod.lastFourDigits}.</p>
                                                                                    {chargeWasDisputedMessage}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <p>{charge.paymentMethod.paymentMethodType}</p>
                                                                                    {chargeWasDisputedMessage}
                                                                                </>
                                                                            )
                                                                }>
                                                                Charge <span className="text-xs font-light text-gray-500">{charge.id}</span>
                                                            </ActivityListItem>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                        {invoice.refunds?.length > 0 && (
                            <div className="mt-6 space-y-4 border-t border-gray-200 py-2">
                                <ul className="-mb-8 mt-4">
                                    {invoice.refunds.map((refund, index) => {
                                        if (refund === null) {
                                            return (<div key={index}></div>);
                                        }
                                        const createdAt = refund.createdAt ? parseISO(refund.createdAt) : undefined;
                                        const succeededAt = refund.succeededAt ? parseISO(refund.succeededAt) : undefined;
                                        const date = succeededAt ?? createdAt;
                                        const datePretext = succeededAt ? 'succeeded at' : 'was created at'
                                        return (
                                            <div key={index}>
                                                <ActivityListItem
                                                    key={index}
                                                    icon={faMoneyBillWave}
                                                    date={date}
                                                    states={{green: ["SUCCEEDED"], gray: ["PENDING"], yellow: ["REQUIRES_ACTION"], red: ["CANCELED", "FAILED", "UNKNOWN"]}}
                                                    state={refund.status}
                                                    datePreText={datePretext}
                                                    isLastItem={true}>
                                                    Refund <span className="text-xs font-light text-gray-500">{refund.id}</span>
                                                </ActivityListItem>
                                            </div>
                                        );
                                    })}
                                </ul>
                            </div>
                        )}
                    </Panel>
                </div>
            </main>
        </article>
    );
}

export default connector(FinalInvoiceView);
