/**
 * @generated SignedSource<<e6dd3fe652374055c80a377d761527aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetailedContactListItem_customerAccount$data = {
  readonly " $fragmentSpreads": FragmentRefs<"RemoveContactDialog_customerAccount" | "UpdateContactRolesDialog_customerAccount">;
  readonly " $fragmentType": "DetailedContactListItem_customerAccount";
};
export type DetailedContactListItem_customerAccount$key = {
  readonly " $data"?: DetailedContactListItem_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailedContactListItem_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailedContactListItem_customerAccount",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveContactDialog_customerAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateContactRolesDialog_customerAccount"
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "0d1619ba46b6505099e949adfe5a6351";

export default node;
