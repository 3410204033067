import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Field from "../form/Field";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import {useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";
import type {CreatePersonContactDialogMutation} from "../../__relay_artifacts__/CreatePersonContactDialogMutation.graphql";

type CreatePersonContactDialogProps = {
    handleCloseDialog: () => {}
}

const CreatePersonContactDialog: React.FC<CreatePersonContactDialogProps> = ({handleCloseDialog}) => {
    const relayEnvironment = useRelayEnvironment();
    const navigate = useNavigate();

    const handleSubmission = (input) => {
        commitMutation<CreatePersonContactDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreatePersonContactDialogMutation(
                        $input: CreatePersonContactInput!
                    ) {
                        createPersonContact(input: $input) {
                            id
                            salutation
                            givenName
                            familyName
                            emailAddress
                            phoneNumber
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                    navigate('/contacts/' + response.createPersonContact?.id);
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        salutation: "hello",
    }

    const validationSchema = yup.object().shape({
        salutation: yup.string()
            .required('This field is required'),
        givenName: yup.string()
            .max(255, 'This name is too long')
            .matches(/^[-+&\p{L}\p{P}\d .]{2,255}$/u, 'This name contains characters which are not allowed')
            .required('This field is required'),
        familyName: yup.string()
            .max(255, 'This name is too long')
            .matches(/^[-+&\p{L}\p{P}\d .]{2,255}$/u, 'This name contains characters which are not allowed')
            .required('This field is required'),
        emailAddress: yup.string()
            .max(255, 'This email address is too long')
            .email('This is does not look like an email address')
            .required('This field is required'),
        phoneNumber: yup.string() // The ITU E.164 states that phone numbers around the globe are recommended to not be longer than 15 digits, but we allow more digits to be safe
            .max(20, 'This phone number is too long')
            .matches(/^(\+(?:[0-9] ?){6,19}[0-9])?$/, 'This is does not look like a valid phone number')
    });

    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} validationSchema={validationSchema} headline="Create Contact" submitLabel="Create">
            <Section>
                <Row>
                    <Label>Salutation</Label>
                    <Field name="salutation" as="select">
                        <option value="mr">Mr</option>
                        <option value="mrs">Mrs</option>
                        <option value="mx">Mx</option>
                        <option value="hello">Hello</option>
                    </Field>
                </Row>
                <Row>
                    <Label>Given name</Label>
                    <Field name="givenName" type="text" placeholder="John" autoFocus={true}/>
                </Row>
                <Row>
                    <Label>Family name</Label>
                    <Field name="familyName" type="text" placeholder="Doe"/>
                </Row>
                <Row>
                    <Label>Email address</Label>
                    <Field name="emailAddress" type="text" placeholder="john.doe@example.com"/>
                </Row>
                <Row>
                    <Label>Phone number</Label>
                    <Field name="phoneNumber" type="text" placeholder="+49 451 3050350"/>
                </Row>
            </Section>
        </FormDialog>
    )
}
export default CreatePersonContactDialog;
