import * as React from "react";
import {ErrorMessage, Field} from "formik";
import TableData, {TableDataProps} from "../presentational/table/TableData";
import {FieldAttributes, FieldProps} from "formik/dist/Field";
import NumberField from "./NumberField";
import CurrencyField from "./CurrencyField";
import PercentageField from "./PercentageField";

export enum FieldDataType {
    text,
    currency,
    number,
    percentage
}

type AdditionalEditableTableDataFieldProps = {
    name: string
    fieldDataType?: FieldDataType
    value?: string | React.ComponentType<FieldProps> | React.ComponentType | React.ForwardRefExoticComponent<any>;
    component?: string | React.ComponentType<FieldProps> | React.ComponentType | React.ForwardRefExoticComponent<any>;
}

type EditableTableDataFieldProps = AdditionalEditableTableDataFieldProps & TableDataProps

const EditableTableDataField: React.FC<FieldAttributes<EditableTableDataFieldProps>> = (props) => {
    const {name, value, fieldDataType, form, component, ...attributes} = props;

    const className = 'focus:ring-red-500 border-none block sm:text-sm border-gray-300 my-1 ' + (props.className !== undefined ? props.className : '')

    return (
        <TableData {...attributes}>
            {(fieldDataType === undefined || fieldDataType === FieldDataType.text) && <Field name={name} value={value} type="text" component={component} className={className}/>}
            {fieldDataType === FieldDataType.number && <NumberField name={name} value={value} className={className}/>}
            {fieldDataType === FieldDataType.currency && <CurrencyField name={name} value={value} className={className}/>}
            {fieldDataType === FieldDataType.percentage && <PercentageField name={name} value={value} className={className}/>}
            <ErrorMessage name={name} component="p" className="mt-2 text-sm text-red-600"/>
        </TableData>
    );
}

export default EditableTableDataField;
