/**
 * @generated SignedSource<<773ee05da9be67ddae88b595c079c662>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTeamContactInput = {
  emailAddress?: string | null;
  phoneNumber?: string | null;
  teamName: string;
};
export type CreateTeamContactDialogMutation$variables = {
  input: CreateTeamContactInput;
};
export type CreateTeamContactDialogMutation$data = {
  readonly createTeamContact: {
    readonly emailAddress: string | null;
    readonly id: string;
    readonly phoneNumber: string | null;
    readonly teamName: string;
  };
};
export type CreateTeamContactDialogMutation = {
  response: CreateTeamContactDialogMutation$data;
  variables: CreateTeamContactDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TeamContact",
    "kind": "LinkedField",
    "name": "createTeamContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "teamName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emailAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTeamContactDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTeamContactDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "034532b02b63ab5ec8156c2145b51470",
    "id": null,
    "metadata": {},
    "name": "CreateTeamContactDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTeamContactDialogMutation(\n  $input: CreateTeamContactInput!\n) {\n  createTeamContact(input: $input) {\n    id\n    teamName\n    emailAddress\n    phoneNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "4de1f1c4ef480ba4838a53d5b504585d";

export default node;
