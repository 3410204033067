import * as React from "react";
import Headline from "./Headline";
import Panel from "./Panel";

const Waiting = () => {
    return (
        <div className="lg:mt-6 max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <Panel>
                <div className="px-6 pt-6 pb-4">
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <Headline>Waiting...</Headline>
                        </div>
                    </div>
                </div>
            </Panel>
        </div>
    )
}

export default Waiting;
