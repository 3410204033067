/**
 * @generated SignedSource<<921de808f99e6f4176c3a8b9bf0f7bcd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveContactInput = {
  contactIdentifier: string;
  customerAccountIdentifier: string;
};
export type RemoveContactDialogMutation$variables = {
  input: RemoveContactInput;
};
export type RemoveContactDialogMutation$data = {
  readonly removeContact: {
    readonly contacts: {
      readonly edges: ReadonlyArray<{
        readonly cursor: string;
        readonly node: {
          readonly __typename: string;
          readonly emailAddress: string | null;
          readonly familyName?: string;
          readonly givenName?: string;
          readonly id: string;
          readonly phoneNumber: string | null;
          readonly teamName?: string;
        } | null;
        readonly roles: ReadonlyArray<{
          readonly label: {
            readonly en: string | null;
          };
          readonly roleIdentifier: string;
        }> | null;
      } | null> | null;
      readonly totalCount: number | null;
    } | null;
  };
};
export type RemoveContactDialogMutation = {
  response: RemoveContactDialogMutation$data;
  variables: RemoveContactDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "CustomerAccountContactConnection",
  "kind": "LinkedField",
  "name": "contacts",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccountContactEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cursor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerAccountContactRole",
          "kind": "LinkedField",
          "name": "roles",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "roleIdentifier",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Label",
              "kind": "LinkedField",
              "name": "label",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "en",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "givenName",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "familyName",
                  "storageKey": null
                }
              ],
              "type": "PersonContact",
              "abstractKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "teamName",
                  "storageKey": null
                }
              ],
              "type": "TeamContact",
              "abstractKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emailAddress",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "phoneNumber",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveContactDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerAccount",
        "kind": "LinkedField",
        "name": "removeContact",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveContactDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerAccount",
        "kind": "LinkedField",
        "name": "removeContact",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "711245fb6001de0487f940e0e7f99c9e",
    "id": null,
    "metadata": {},
    "name": "RemoveContactDialogMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveContactDialogMutation(\n  $input: RemoveContactInput!\n) {\n  removeContact(input: $input) {\n    contacts {\n      totalCount\n      edges {\n        cursor\n        roles {\n          roleIdentifier\n          label {\n            en\n          }\n        }\n        node {\n          id\n          __typename\n          ... on PersonContact {\n            givenName\n            familyName\n          }\n          ... on TeamContact {\n            teamName\n          }\n          emailAddress\n          phoneNumber\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7ec5df97160e3246efb0dd242dd5278b";

export default node;
