import * as React from "react";

const Section = (props) => {
    return (
        <div className="flex-1">
            <div className="py-6 space-y-6">
                {props.children}
            </div>
        </div>
    );
};

export default Section;
