/**
 * @generated SignedSource<<75b30db1f38fbc54388245986135de93>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TerminateSubscriptionDialog_subscription$data = {
  readonly customerAccount: {
    readonly customerName: string;
  };
  readonly id: string;
  readonly subscriptionService: {
    readonly name: string;
  };
  readonly subscriptionServiceReferenceLabel: string | null;
  readonly " $fragmentType": "TerminateSubscriptionDialog_subscription";
};
export type TerminateSubscriptionDialog_subscription$key = {
  readonly " $data"?: TerminateSubscriptionDialog_subscription$data;
  readonly " $fragmentSpreads": FragmentRefs<"TerminateSubscriptionDialog_subscription">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TerminateSubscriptionDialog_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriptionService",
      "kind": "LinkedField",
      "name": "subscriptionService",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subscriptionServiceReferenceLabel",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};

(node as any).hash = "79b2e9ed3fb65556bd1622c446a0e747";

export default node;
