import * as React from "react";

export enum TextAlignment {
    Left = 'left',
    Right = 'right',
    Center = 'center'
}

type TableDataAdditionalProps = {
    className?: string
    textAlignment?: TextAlignment
}

export type TableDataProps = TableDataAdditionalProps & React.DetailedHTMLProps<React.TdHTMLAttributes<HTMLTableDataCellElement>, HTMLTableDataCellElement>

const TableData: React.FC<React.PropsWithChildren<TableDataProps>> = (props) => {
    const {children, textAlignment, className, ...attributes} = props

    return (
        <td className={"align-top overflow-ellipsis py-2 text-sm text-gray-700 text-" + (textAlignment ?? TextAlignment.Left) + ' ' + (className !== undefined ? className : '')} {...attributes}>
            {children}
        </td>
    );
};

export default TableData;
