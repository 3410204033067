import * as React from "react";
import Dinero from "dinero.js";
import {Field, useFormikContext} from "formik";
import {FieldAttributes} from "formik/dist/Field";

type CurrencyFieldProps = {}

const CurrencyField: React.FC<FieldAttributes<CurrencyFieldProps>> = (props) => {
    const {value, ...attributes} = props
    const form = useFormikContext()
    let preparedValue = value ?? ''

    if (Number.isInteger(value)) {
        preparedValue = Dinero({amount: value}).toFormat('0.00')
    }

    const handleOnBlur = ((event) => {
        const updatedValue = parseFloat(event.currentTarget.value.replace(',', '.'))
        if (isNaN(updatedValue)) {
            form.setFieldValue(event.currentTarget.name, null)
        } else {
            form.setFieldValue(event.currentTarget.name, Math.floor(updatedValue * 100))
        }
    })

    return (
        <Field value={preparedValue} type="text" onBlur={handleOnBlur} className="text-right" {...attributes} />
    );
}

export default CurrencyField;
