import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Field from "../form/Field";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import type {CreateProjectDialogMutation} from "../../__relay_artifacts__/CreateProjectDialogMutation.graphql";
import {useNavigate} from "react-router-dom";
import {fetchQuery, useRelayEnvironment} from "react-relay/hooks";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import asFormikField from "../abstract/asFormikField";
import AbstractCombobox from "../abstract/AbstractCombobox";

type Props = {
    handleCloseDialog: () => void,
    query: any
}

const customerAccountsDataProvider = (relayEnvironment) => {
    return AwesomeDebouncePromise((searchTerm: string = '') => {
        return fetchQuery(relayEnvironment, graphql`
            query CreateProjectDialogCustomerAccountsQuery($searchTerm: String!) {
                customerAccounts(searchTerm: $searchTerm, first: 2) {
                    totalCount
                    edges {
                        node {
                            __typename
                            id
                            customerName
                            customerNumber
                        }
                    }
                }
            }
        `, {searchTerm}).toPromise().then((data) => {
            if (!data) {
                return [];
            }
            //@ts-ignore
            return data.customerAccounts?.edges?.map((edge) => edge.node) || [];
        });
    }, 500);
}

const CreateProjectDialog: React.FC<Props> = ({handleCloseDialog, query}) => {
    const relayEnvironment = useRelayEnvironment();
    const navigate = useNavigate();

    const handleSubmission = (input) => {
        commitMutation<CreateProjectDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreateProjectDialogMutation(
                        $input: CreateProjectInput!
                    ) {
                        createProject(input: $input) {
                            id
                            projectName
                            customerAccountIdentifier
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                    // navigate('/projects/' + response.createProject?.id)
                    navigate('/projects')
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        projectName: '',
        customerAccountIdentifier: ''
    }

    const validationSchema = yup.object().shape({
        projectName: yup.string()
            .min(2, 'This project name is too short')
            .max(255, 'This project name is too long')
            .required('This field is required'),
        customerAccountIdentifier: yup.string()
            .required('This field is required'),
    });

    const CustomerAccountCombobox = asFormikField(AbstractCombobox, 'customerAccountIdentifier');

    return (
        <FormDialog handleCloseDialog={handleCloseDialog}
                    handleSubmission={handleSubmission}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    headline="Create Project"
                    submitLabel="Create">
            <Section>
                <Row>
                    <Label>Project name</Label>
                    <Field name="projectName" type="text" autoFocus={true}/>
                </Row>
                <Row>
                    <Label>Customer account</Label>
                    <CustomerAccountCombobox getEntryId={({id}) => id}
                                             getEntryLabel={({customerName}) => customerName}
                                             getEntrySecondaryLabel={({customerNumber}) => customerNumber}
                                             dataProvider={customerAccountsDataProvider(relayEnvironment)}
                                             placeholder="Search for a customer"/>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default CreateProjectDialog;
