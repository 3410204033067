/**
 * @generated SignedSource<<eb0918d81b0e92a12edb857bb2746198>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionHeader_subscription$data = {
  readonly customerAccount: {
    readonly customerName: string;
  };
  readonly id: string;
  readonly subscriptionService: {
    readonly name: string;
  };
  readonly " $fragmentType": "SubscriptionHeader_subscription";
};
export type SubscriptionHeader_subscription$key = {
  readonly " $data"?: SubscriptionHeader_subscription$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionHeader_subscription">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubscriptionHeader_subscription",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SubscriptionService",
      "kind": "LinkedField",
      "name": "subscriptionService",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Subscription",
  "abstractKey": null
};

(node as any).hash = "c3a1647dd03e62fb95abca556394d8bd";

export default node;
