import * as React from "react";

type ProfilePictureProps = {
    user: {
        picture?: string
    }
}

const ProfilePicture: React.FC<ProfilePictureProps> = ({user}) => {
    return (
        <img className="w-8 rounded-full" src={user.picture} alt="profile"/>
    );
};

export default ProfilePicture;
