import * as React from "react";
import {commitMutation, fetchQuery, graphql} from "react-relay";
import {useRelayEnvironment} from "react-relay/hooks";
import Section from "../form/Section";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import type {CreateInvoiceDialogMutation} from "../../__relay_artifacts__/CreateInvoiceDialogMutation.graphql";
import {useNavigate} from "react-router-dom";
import asFormikField from "../abstract/asFormikField";
import AbstractCombobox from "../abstract/AbstractCombobox";
import AwesomeDebouncePromise from 'awesome-debounce-promise';

const customerAccountsDataProvider = (relayEnvironment) => {
    return AwesomeDebouncePromise((searchTerm: String = '') => {
        return fetchQuery(relayEnvironment, graphql`
            query CreateInvoiceDialogCustomerAccountsQuery($searchTerm: String!) {
                customerAccounts(searchTerm: $searchTerm, sort: CUSTOMER_SINCE_DESC) {
                    totalCount
                    edges {
                        node {
                            id
                            customerName
                            customerNumber
                        }
                    }
                }
            }
        `, {searchTerm}).toPromise().then((data) => {
            if (!data) {
                return [];
            }
            //@ts-ignore
            return data.customerAccounts?.edges?.map((edge) => edge.node) || [];
        });
    }, 500);
}

type Props = {
    handleCloseDialog: () => {}
}

const CreateInvoiceDialog: React.FC<Props> = ({handleCloseDialog}) => {
    const relayEnvironment = useRelayEnvironment();
    const navigate = useNavigate();

    const handleSubmission = (input) => {
        commitMutation<CreateInvoiceDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreateInvoiceDialogMutation(
                        $input: CreateInvoiceInput!
                    ) {
                        createInvoice(input: $input) {
                            id
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                    navigate('/invoices/' + response.createInvoice?.id + '/edit')
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }
    const validationSchema = yup.object().shape({
        customerAccountIdentifier: yup.string()
            .required('This field is required'),
    });

    const FormikCombobox = asFormikField(AbstractCombobox, 'customerAccountIdentifier');

    const getEntrySecondaryLabel = (customerAccount) => {
        return customerAccount?.customerNumber
    }

    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={{}} validationSchema={validationSchema} headline="Create Invoice" submitLabel="Create">
            <Section>
                <Row>
                    <Label>Customer account</Label>
                    <FormikCombobox getEntryId={(customerAccount) => customerAccount.id} getEntryLabel={(customerAccount) => customerAccount && customerAccount.customerName} getEntrySecondaryLabel={getEntrySecondaryLabel} dataProvider={customerAccountsDataProvider(relayEnvironment)} autoFocus={true}/>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default CreateInvoiceDialog;
