import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Field from "../form/Field";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import {useRelayEnvironment} from "react-relay/hooks";
import type {SetUpOnAccountPaymentMethodDialogMutation} from "../../__relay_artifacts__/SetUpOnAccountPaymentMethodDialogMutation.graphql";

type SetUpOnAccountPaymentMethodDialogProps = {
    customerAccountIdentifier: string
    handleCloseDialog: () => {}
}

const SetUpOnAccountPaymentMethodDialog: React.FC<SetUpOnAccountPaymentMethodDialogProps> = ({customerAccountIdentifier, handleCloseDialog}) => {
    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation<SetUpOnAccountPaymentMethodDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation SetUpOnAccountPaymentMethodDialogMutation(
                        $input: AttachPaymentMethodInput!
                    ) {
                        attachPaymentMethod(input: $input) {
                            paymentMethods {
                                id
                                paymentMethodType
                                paymentTerms
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        customerAccountIdentifier: customerAccountIdentifier,
        paymentMethodType: 'OnAccount',
        paymentServiceType: 'Cabana',
        paymentTerms: 'UponReceipt'
    }

    const validationSchema = yup.object().shape({
        paymentTerms: yup.string().required('This field is required'),
    });

    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} validationSchema={validationSchema} headline="Add payment on account" submitLabel="Add">
            <Section>
                <Row>
                    <Label>Payment terms</Label>
                    <Field name="paymentTerms" as="select">
                        <option value="UponReceipt">Due net, upon receipt</option>
                        <option value="Net7">Due net, 7 days</option>
                        <option value="Net15">Due net, 15 days</option>
                        <option value="Net30">Due net, 30 days</option>
                        <option value="Net60">Due net, 60 days</option>
                    </Field>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default SetUpOnAccountPaymentMethodDialog;
