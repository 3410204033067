import * as React from "react";

type TableProps = {}

const Table: React.FC<React.PropsWithChildren<TableProps>> = ({children, ...attributes}) => {
    return (
        <table className={"table-auto min-w-full divide-y divide-gray-300"} {...attributes}>
            {children}
        </table>
    );
};

export default Table;
