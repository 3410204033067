import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import Hint from "../presentational/Hint";
import FormDialog from "../dialog/FormDialog";
import Checkbox from "../form/Checkbox";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";

type Role = {
    roleIdentifier: string
}

type Props = {
    customerAccount: any,
    contact: any
    roles: Role[]
    handleCloseDialog: () => {}
}

const UpdateContactRolesDialog: React.FC<Props> = (props) => {
    const customerAccount = useFragment(
        graphql`
            fragment UpdateContactRolesDialog_customerAccount on CustomerAccount {
                id
                customerName
            }
        `,
        props.customerAccount
    );

    const contact = useFragment(
        graphql`
            fragment UpdateContactRolesDialog_contact on Contact {
                id
                ... on PersonContact {
                    givenName
                    familyName
                }
                ... on TeamContact {
                    teamName
                }
            }
        `,
        props.contact
    );

    const relayEnvironment = useRelayEnvironment();
    const handleSubmission = (input) => {
        input.contactRoles = input.contactRoles.map((roleIdentifier) => {
            return {"roleIdentifier": roleIdentifier}
        })
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation UpdateContactRolesDialogMutation($input: UpdateContactRolesInput!) {
                        updateContactRoles(input: $input) {
                            id
                            customerName
                            contacts {
                                edges {
                                    roles {
                                        roleIdentifier
                                        label {
                                            en
                                        }
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        customerAccountIdentifier: customerAccount.id,
        contactIdentifier: contact.id,
        contactRoles: props.roles.map((role) => {
            return role.roleIdentifier
        })
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Assign Contact Roles" submitLabel="Assign roles">
            <Section>
                <Row>
                    <p>Select the roles <strong>{((contact.teamName ?? "") + (contact.givenName ?? "") + " " + (contact.familyName ?? "")).trim()}</strong> has in relation to <strong>{customerAccount.customerName}</strong>.</p>
                </Row>
                <Row>
                    <fieldset className="border-t border-b border-gray-200">
                        <div className="divide-y divide-gray-200">
                            <div className="relative flex items-start py-4">
                                <div className="min-w-0 flex-1 text-sm">
                                    <label className="font-medium text-gray-700">Primary contact</label>
                                    <p className="text-gray-500">Contact for all topics not covered by other contacts.</p>
                                </div>
                                <div className="ml-3 flex items-center h-5">
                                    <Checkbox name="contactRoles" value="PrimaryContact"/>
                                </div>
                            </div>
                            <div className="relative flex items-start py-4">
                                <div className="min-w-0 flex-1 text-sm">
                                    <label className="font-medium text-gray-700">Security</label>
                                    <p className="text-gray-500">Contact for security and privacy issues and vulnerabilities.</p>
                                </div>
                                <div className="ml-3 flex items-center h-5">
                                    <Checkbox name="contactRoles" value="Security"/>
                                </div>
                            </div>
                            <div className="relative flex items-start py-4">
                                <div className="min-w-0 flex-1 text-sm">
                                    <label className="font-medium text-gray-700">Technical</label>
                                    <p className="text-gray-500">Contact for technical events and issues, such as outages or bugs.</p>
                                </div>
                                <div className="ml-3 flex items-center h-5">
                                    <Checkbox name="contactRoles" value="Technical"/>
                                </div>
                            </div>
                            <div className="relative flex items-start py-4">
                                <div className="min-w-0 flex-1 text-sm">
                                    <label className="font-medium text-gray-700">Billing</label>
                                    <p className="text-gray-500">Contact for billing and payment issues, price updates, credits.</p>
                                </div>
                                <div className="ml-3 flex items-center h-5">
                                    <Checkbox name="contactRoles" value="Billing"/>
                                </div>
                            </div>
                            <div className="relative flex items-start py-4">
                                <div className="min-w-0 flex-1 text-sm">
                                    <label className="font-medium text-gray-700">Legal</label>
                                    <p className="text-gray-500">Contact for enforcement actions, contracts and terms and service updates.</p>
                                </div>
                                <div className="ml-3 flex items-center h-5">
                                    <Checkbox name="contactRoles" value="Legal"/>
                                </div>
                            </div>
                            <div className="relative flex items-start py-4">
                                <div className="min-w-0 flex-1 text-sm">
                                    <label className="font-medium text-gray-700">Invoice recipient</label>
                                    <p className="text-gray-500">Contact who receives new invoices.</p>
                                </div>
                                <div className="ml-3 flex items-center h-5">
                                    <Checkbox name="contactRoles" value="InvoiceRecipient"/>
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </Row>
                <Row>
                    <Hint><strong>{(contact.teamName ?? "") + (contact.givenName ? contact.givenName : "") ?? (contact.familyName ?? "Unknown")}</strong> may have access to sensitive information depending on the roles selected.</Hint>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default UpdateContactRolesDialog;
