import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {faLightbulb} from "@fortawesome/pro-duotone-svg-icons";

type HintProps = {
    className?: string
}

const Hint: React.FC<React.PropsWithChildren<HintProps>> = (props) => {
    const {className, children} = props;
    return (
        <div className={"flex " + className ?? ""}>
            <div className="shrink-0">
                <FontAwesomeIcon icon={faLightbulb} className="h-5 w-5 text-yellow-400"/>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-gray-700">
                    {children}
                </p>
            </div>
        </div>
    );
}

export default Hint;
