import * as React from "react";
import {Field, useFormikContext} from "formik";
import {FieldAttributes} from "formik/dist/Field";

type PercentageFieldProps = {}

const PercentageField: React.FC<FieldAttributes<PercentageFieldProps>> = (props) => {
    const {value, ...attributes} = props
    const form = useFormikContext()
    let preparedValue = value ?? ''

    if (typeof value === 'number') {
        preparedValue = value.toFixed(2).replace('.', ',') + ' %'
    }

    const handleOnBlur = ((event) => {
        const cleanedValue = event.currentTarget.value.replace(',', '.').replace('%', '')
        const updatedValue = parseFloat(cleanedValue)
        if (isNaN(updatedValue)) {
            form.setFieldValue(event.currentTarget.name, null)
        } else {
            form.setFieldValue(event.currentTarget.name, updatedValue)
        }
    })

    return (
        <Field value={preparedValue} type="text" onBlur={handleOnBlur} className="text-right" {...attributes} />
    );
}

export default PercentageField;
