/**
 * @generated SignedSource<<d9c59e972b3cbca0414e281188d9903f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetDefaultPaymentMethodInput = {
  customerAccountIdentifier: string;
  paymentMethodIdentifier: string;
};
export type SetDefaultPaymentMethodDialogMutation$variables = {
  input: SetDefaultPaymentMethodInput;
};
export type SetDefaultPaymentMethodDialogMutation$data = {
  readonly setDefaultPaymentMethod: {
    readonly defaultPaymentMethod: {
      readonly id: string;
    } | null;
    readonly paymentMethods: ReadonlyArray<{
      readonly id: string;
    }>;
  };
};
export type SetDefaultPaymentMethodDialogMutation = {
  response: SetDefaultPaymentMethodDialogMutation$data;
  variables: SetDefaultPaymentMethodDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentMethod",
  "kind": "LinkedField",
  "name": "defaultPaymentMethod",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "PaymentMethod",
  "kind": "LinkedField",
  "name": "paymentMethods",
  "plural": true,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetDefaultPaymentMethodDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerAccount",
        "kind": "LinkedField",
        "name": "setDefaultPaymentMethod",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetDefaultPaymentMethodDialogMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CustomerAccount",
        "kind": "LinkedField",
        "name": "setDefaultPaymentMethod",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3a5e07bb4c9eff8da3d6308fd9ef78e9",
    "id": null,
    "metadata": {},
    "name": "SetDefaultPaymentMethodDialogMutation",
    "operationKind": "mutation",
    "text": "mutation SetDefaultPaymentMethodDialogMutation(\n  $input: SetDefaultPaymentMethodInput!\n) {\n  setDefaultPaymentMethod(input: $input) {\n    defaultPaymentMethod {\n      id\n    }\n    paymentMethods {\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "3580aacd74f7e916205a3b3823f11b91";

export default node;
