import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Panel from "./presentational/Panel";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import {loadQuery, usePreloadedQuery, useRelayEnvironment} from "react-relay/hooks";
import type {SubscriptionViewQuery as SubscriptionViewQueryType} from "../__relay_artifacts__/SubscriptionViewQuery.graphql";
import SubscriptionViewQuery from "../__relay_artifacts__/SubscriptionViewQuery.graphql";
import SubscriptionHeader from "./SubscriptionHeader";
import ActionButton from "./presentational/button/ActionButton";
import TerminateSubscriptionDialog from "./dialog/TerminateSubscriptionDialog";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import {faHourglassClock, faHourglassEnd} from "@fortawesome/pro-regular-svg-icons";
import CancelSubscriptionDialog from "./dialog/CancelSubscriptionDialog";
import EditableDataListItem from "./form/EditableDataListItem";
import EditableDataListField from "./form/EditableDataListField";
import * as yup from "yup";
import {Link} from "react-router-dom";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type SubscriptionViewParams = {
    prepared: any
};
type SubscriptionViewProps = SubscriptionViewParams & ConnectedProps<typeof connector>;

const handleUpdateCustomerOrderNotesSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation SubscriptionViewUpdateCustomerOrderNotesMutation($input: UpdateCustomerOrderNotesInput!) {
                    updateCustomerOrderNotes(input: $input) {
                        id
                        customerOrderNotes
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdatePurchaseOrderReferenceSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation SubscriptionViewUpdatePurchaseOrderReferenceMutation($input: UpdatePurchaseOrderReferenceInput!) {
                    updatePurchaseOrderReference(input: $input) {
                        id
                        purchaseOrderReference
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}


const SubscriptionView: React.FC<SubscriptionViewProps> = (props) => {

    const {subscription} = usePreloadedQuery<SubscriptionViewQueryType>(
        graphql`
            query SubscriptionViewQuery($id: ID!) {
                subscription(id: $id) {
                    ...SubscriptionHeader_subscription
                    ...TerminateSubscriptionDialog_subscription
                    ...CancelSubscriptionDialog_subscription
                    id
                    state
                    subscriptionService {
                        name
                        id
                    }
                    subscriptionServiceReference
                    subscriptionServiceReferenceLabel
                    subscriptionServiceReferenceUri
                    customerAccount {
                        id
                        customerName
                    }
                    orderedAt
                    activatedAt
                    expiresAt
                    customerOrderNotes
                    purchaseOrderReference
                }
            }
        `,
        props.prepared.subscriptionViewQuery
    );

    const relayEnvironment = useRelayEnvironment();

    if (subscription == null) {
        return <p>Subscription not found</p>;
    }

    const customerOrderNotesValidationSchema = yup.object().shape({
        vatId: yup.string()
            .max(255, 'The order notes are too long')
            .matches(/^[-+&\p{L}\p{P}\d .]{0,255}$/u, 'Invalid order notes')
            .nullable()
    });

    const purchaseOrderReferenceValidationSchema = yup.object().shape({
        vatId: yup.string()
            .matches(/^[-+&\p{L}\p{P}\d .]{0,20}$/u, 'Invalid purchase order reference')
            .nullable()
    });

    const breadcrumbNavigationItems = [
        {label: 'Subscriptions', to: '/subscriptions'},
        {label: subscription.id, to: '/subscriptions/' + subscription.id},
    ]

    const {dispatchShowModal, dispatchHideModal} = props

    const showTerminateSubscriptionDialog = (subscription) => {
        dispatchShowModal({
            body: (<TerminateSubscriptionDialog queryRef={subscription} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const showCancelSubscriptionDialog = (subscription) => {
        dispatchShowModal({
            body: (<CancelSubscriptionDialog queryRef={subscription} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    // FIXME: How can we handle GraphQL DateTime correctly?
    const formattedExpiresAt = subscription.expiresAt ? subscription.expiresAt.substring(0, 10) : "";
    const formattedActivatedAt = subscription.activatedAt ? subscription.activatedAt.substring(0, 10) : "";

    return (
        <article>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <SubscriptionHeader subscription={subscription}/>
            <div className="mt-8"/>
            <div className="max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                <Panel headline="Subscription" buttons={
                    <>
                        {subscription.state === 'ACTIVE' && (<ActionButton icon={faHourglassEnd} iconSize={"lg"} title="Terminate subscription …" onClick={e => showTerminateSubscriptionDialog(subscription)}/>)}
                        {subscription.state === 'ACTIVE' && (<ActionButton icon={faHourglassClock} iconSize={"lg"} title="Cancel subscription …" onClick={e => showCancelSubscriptionDialog(subscription)}/>)}
                    </>
                }>
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Status</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="grow">
                                    {subscription.state === 'EXPIRED' ? "Expired at " + formattedExpiresAt : ""}
                                    {subscription.state === 'CANCELED' ? "Canceled, will expire at " + formattedExpiresAt : ""}
                                    {subscription.state === 'ACTIVE' ? "Active since " + formattedActivatedAt : ""}
                                </span>
                            </dd>
                        </div>
                    </dl>
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Customer account</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <Link to={"/customers/" + subscription.customerAccount.id} className="focus:outline-none">
                                    <span className="grow" title={subscription.customerAccount.id}>{subscription.customerAccount.customerName}</span>
                                </Link>
                            </dd>
                        </div>
                    </dl>
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Service</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="grow" title={subscription.subscriptionService.id}>{subscription.subscriptionService.name}</span>
                            </dd>
                        </div>
                    </dl>
                    <dl className="divide-y divide-gray-200">
                        <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                            <dt className="text-sm font-medium text-gray-500">Service reference</dt>
                            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                <span className="grow" title={subscription.subscriptionServiceReference}><a href={subscription.subscriptionServiceReferenceUri ?? "#"} target="beach">{subscription.subscriptionServiceReferenceLabel}</a></span>
                            </dd>
                        </div>
                    </dl>
                    <EditableDataListItem
                        label="Customer order notes"
                        fields={(<EditableDataListField type="text" name="customerOrderNotes" placeholder="e.g. ordered by Julia Higgins"/>)}
                        initialValues={{subscriptionIdentifier: subscription.id, customerOrderNotes: subscription.customerOrderNotes ?? ""}}
                        validationSchema={customerOrderNotesValidationSchema}
                        handleSubmission={(values, setEditingActive) => handleUpdateCustomerOrderNotesSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {subscription.customerOrderNotes}
                    </EditableDataListItem>
                    <EditableDataListItem
                        label="Purchase order reference"
                        fields={(<EditableDataListField type="text" name="purchaseOrderReference" placeholder="e.g. PO-12348-887ah8"/>)}
                        initialValues={{subscriptionIdentifier: subscription.id, purchaseOrderReference: subscription.purchaseOrderReference ?? ""}}
                        validationSchema={purchaseOrderReferenceValidationSchema}
                        handleSubmission={(values, setEditingActive) => handleUpdatePurchaseOrderReferenceSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {subscription.purchaseOrderReference}
                    </EditableDataListItem>
                </Panel>
            </div>
        </article>
    );
}

export default connector(SubscriptionView);

function prepare_SubscriptionView(params, relayEnvironment) {
    return {
        subscriptionViewQuery: loadQuery(
            relayEnvironment,
            SubscriptionViewQuery,
            {
                id: params.id,
                language: params.language
            },
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_SubscriptionView};
