/**
 * @generated SignedSource<<a29419f33623959f0b312534d8f125eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettlementHeader_settlement$data = {
  readonly customerAccount: {
    readonly customerName: string;
  };
  readonly id: string;
  readonly status: string;
  readonly title: {
    readonly de: string;
    readonly en: string;
  };
  readonly " $fragmentType": "SettlementHeader_settlement";
};
export type SettlementHeader_settlement$key = {
  readonly " $data"?: SettlementHeader_settlement$data;
  readonly " $fragmentSpreads": FragmentRefs<"SettlementHeader_settlement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SettlementHeader_settlement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TranslatedString",
      "kind": "LinkedField",
      "name": "title",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "en",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "de",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "Settlement",
  "abstractKey": null
};

(node as any).hash = "fd1558b0adcaf9c3801340215f2f7569";

export default node;
