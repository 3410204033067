/**
 * @generated SignedSource<<ee849553704477f22a8d9c7aa2097116>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveContactDialog_customerAccount$data = {
  readonly customerName: string;
  readonly id: string;
  readonly " $fragmentType": "RemoveContactDialog_customerAccount";
};
export type RemoveContactDialog_customerAccount$key = {
  readonly " $data"?: RemoveContactDialog_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"RemoveContactDialog_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RemoveContactDialog_customerAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "8d9c79b43c0baec4184907e558fd11e1";

export default node;
