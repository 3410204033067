import * as React from "react";
import {ErrorMessage, Field} from "formik";
import NumberField from "./NumberField";
import CurrencyField from "./CurrencyField";
import PercentageField from "./PercentageField";
import {FieldAttributes, FieldProps} from "formik/dist/Field";
import DateField from "./DateField";

export enum FieldDataType {
    text,
    number,
    select,
    textarea,
    currency,
    percentage,
    date
}

type EditableDataListFieldProps = {
    name: string
    fieldDataType?: FieldDataType
    value?: string | React.ComponentType<FieldProps> | React.ComponentType | React.ForwardRefExoticComponent<any>;
    component?: string | React.ComponentType<FieldProps> | React.ComponentType | React.ForwardRefExoticComponent<any>;
}

const EditableDataListField: React.FC<FieldAttributes<EditableDataListFieldProps>> = (props) => {
    const {name, fieldDataType, form, component, as: is, ...attributes} = props;
    const className = '-ml-3 shadow-sm focus:ring-red-500 border-none block w-full sm:text-sm border-gray-300 my-1 ' + (props.className !== undefined ? props.className : '')
    return (
        <>
            {(fieldDataType === undefined || fieldDataType === FieldDataType.text) && <Field name={name} type="text" component={component} className={className} {...attributes} />}
            {(fieldDataType === FieldDataType.textarea) && <Field name={name} as="textarea" component={component} className={className} {...attributes}>{props.children}</Field>}
            {(fieldDataType === FieldDataType.select) && <Field name={name} as="select" component={component} className={className} {...attributes}>{props.children}</Field>}
            {fieldDataType === FieldDataType.number && <NumberField name={name} className={className} {...attributes}/>}
            {fieldDataType === FieldDataType.currency && <CurrencyField name={name} className={className} {...attributes}/>}
            {fieldDataType === FieldDataType.percentage && <PercentageField name={name} className={className} {...attributes}/>}
            {fieldDataType === FieldDataType.date && <DateField name={name} className={className} {...attributes}/>}
            <ErrorMessage name={name} component="p" className="mt-2 text-sm text-red-600"/>
        </>
    );
}

export default EditableDataListField;
