import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Field from "../form/Field";
import Row from "../form/Row";
import Label from "../form/Label";
import Hint from "../presentational/Hint";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import type {CreateCustomerDialogMutation} from "../../__relay_artifacts__/CreateCustomerDialogMutation.graphql";
import {useNavigate} from "react-router-dom";
import {useRelayEnvironment} from "react-relay/hooks";

type Props = {
    handleCloseDialog: () => {}
}

const CreateCustomerDialog: React.FC<Props> = ({handleCloseDialog}) => {
    const relayEnvironment = useRelayEnvironment();
    const navigate = useNavigate();

    const handleSubmission = (input) => {
        commitMutation<CreateCustomerDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreateCustomerDialogMutation(
                        $input: CreateCustomerAccountInput!
                    ) {
                        createCustomerAccount(input: $input) {
                            id
                            customerName
                            customerNumber
                            customerSince
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                    navigate('/customers/' + response.createCustomerAccount?.id)
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }
    const validationSchema = yup.object().shape({
        customerName: yup.string()
            .min(2, 'This customer name is too short')
            .max(255, 'This customer name is too long')
            .required('This field is required'),
    });

    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={{}} validationSchema={validationSchema} headline="Create Customer" submitLabel="Create">
            <Section>
                <Row>
                    <Label>Customer name</Label>
                    <Field name="customerName" type="text" placeholder="Customer Name Ltd." autoFocus={true}/>
                </Row>
                <Row><Hint>The customer name should be set to the official business name including its legal entity.</Hint></Row>
            </Section>
        </FormDialog>
    )
}

export default CreateCustomerDialog;
