import * as React from "react"
import {useEffect} from "react"
import {graphql} from "react-relay";
import {usePaginationFragment} from "react-relay/hooks";
import LoadMoreButton from "./presentational/LoadMoreButton";
import SubscriptionListItem from "./SubscriptionListItem";

type Props = {
    queryRef: any // FIXME: Any type we can define here? It's actually: Readonly<{ ' $data'?: unknown; ' $fragmentRefs': unknown; }> | null
    searchTerm?: string
}

type SubscriptionListProps = Props;

const SubscriptionList: React.FC<SubscriptionListProps> = (props) => {
    const {data, hasNext, loadNext, isLoadingNext, refetch} = usePaginationFragment(
        graphql`
            fragment SubscriptionList_query on Query
            @argumentDefinitions(
                first: { type: "Int", defaultValue: 100 }
                after: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "SubscriptionsPaginationQuery") {
                subscriptions(first: $first, after: $after, searchTerm: $searchTerm, sort: ORDERED_AT_DESC)
                @connection(key: "SubscriptionList_subscriptions") {
                    totalCount
                    edges {
                        node {
                            ...TerminateSubscriptionDialog_subscription
                            ...SubscriptionListItem_subscription
                            id
                            state
                            customerAccount {
                                id
                                customerName
                                customerNumber
                            }
                            subscriptionService {
                                id
                                name
                            }
                            subscriptionServiceReference
                            subscriptionServiceReferenceLabel
                            subscriptionServiceReferenceUri
                            orderedAt
                            activatedAt
                        }
                    }
                }
            }
        `,
        props.queryRef
    );

    useEffect(() => {
        refetch({searchTerm: props.searchTerm}, {fetchPolicy: 'store-and-network'});
    }, [props.searchTerm, refetch])

    if (!data.subscriptions || data.subscriptions.totalCount === 0) {
        return (<></>);
    }

    return (
        <nav className="flex-1 min-h-0 relative overflow-y-auto" aria-label="Subscriptions">
            <ul className="relative z-0 divide-y divide-gray-200">
                {data.subscriptions.edges.map(
                    (edge, index) => {
                        return <SubscriptionListItem key={index} subscription={edge.node}/>
                    }
                )}
            </ul>
            {hasNext && <LoadMoreButton onClick={() => loadNext(100)} isLoadingNext={isLoadingNext}/>}
        </nav>
    )
}

export default SubscriptionList;
