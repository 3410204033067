/**
 * @generated SignedSource<<2adad915fbcec2f15bc576412aa21bef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactDataList_contact$data = {
  readonly __typename: string;
  readonly emailAddress: string | null;
  readonly familyName?: string;
  readonly givenName?: string;
  readonly id: string;
  readonly phoneNumber: string | null;
  readonly salutation?: string;
  readonly teamName?: string;
  readonly " $fragmentSpreads": FragmentRefs<"UpdatePersonContactDialog_contact" | "UpdateTeamContactDialogProps_contact">;
  readonly " $fragmentType": "ContactDataList_contact";
};
export type ContactDataList_contact$key = {
  readonly " $data"?: ContactDataList_contact$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactDataList_contact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactDataList_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "salutation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "givenName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "familyName",
          "storageKey": null
        }
      ],
      "type": "PersonContact",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamName",
          "storageKey": null
        }
      ],
      "type": "TeamContact",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdatePersonContactDialog_contact"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UpdateTeamContactDialogProps_contact"
    }
  ],
  "type": "Contact",
  "abstractKey": "__isContact"
};

(node as any).hash = "ea99d84b0d04759e117c58a4716f35f9";

export default node;
