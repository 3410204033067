/**
 * @generated SignedSource<<678b31da6a261ea8d50ecf9f30be1f7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ChargeState = "FAILED" | "PENDING" | "SUCCEEDED" | "UNKNOWN" | "%future added value";
export type InvoiceState = "DRAFT" | "INVOICE_NUMBER_PENDING" | "OPEN" | "PAID" | "REFUNDED" | "REVERSED" | "UNCOLLECTIBLE" | "VOID" | "%future added value";
export type InvoiceType = "REGULAR" | "REVERSE" | "%future added value";
export type PaymentIntentStatus = "CANCELED" | "PENDING" | "PROCESSING" | "REQUIRES_ACTION" | "REQUIRES_CAPTURE" | "REQUIRES_CONFIRMATION" | "REQUIRES_PAYMENT_METHOD" | "SUCCEEDED" | "UNKNOWN" | "%future added value";
export type RefundStatus = "CANCELED" | "FAILED" | "PENDING" | "REQUIRES_ACTION" | "SUCCEEDED" | "UNKNOWN" | "%future added value";
export type TaxStatus = "EXEMPT" | "REGULAR" | "REVERSE_CHARGE" | "%future added value";
export type TaxType = "GROSS" | "INTRA_COMMUNITY_SERVICE" | "NET" | "THIRD_PARTY_COUNTRY_SERVICE" | "VAT_FREE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FinalInvoiceView_invoice$data = {
  readonly address: {
    readonly addressLine1: string | null;
    readonly addressLine2: string | null;
    readonly businessName: string;
    readonly city: string;
    readonly country: string;
    readonly postalCode: string;
  };
  readonly attachments: ReadonlyArray<{
    readonly filename: string;
    readonly sha1: string;
    readonly size: number;
  }>;
  readonly customerAccount: {
    readonly customerNumber: string;
    readonly taxStatus: TaxStatus | null;
    readonly vatId: string | null;
  };
  readonly customerOrderNotes: string | null;
  readonly documentRenderedAt: any | null;
  readonly documentRenderedVersion: number | null;
  readonly documentSha1: string | null;
  readonly id: string;
  readonly invoiceDate: any;
  readonly invoiceNumber: string | null;
  readonly language: string;
  readonly paymentIntents: ReadonlyArray<{
    readonly canceledAt: any | null;
    readonly charges: ReadonlyArray<{
      readonly createdAt: any;
      readonly disputed: boolean;
      readonly id: string;
      readonly paid: boolean;
      readonly paymentMethod: {
        readonly cardBrand: string | null;
        readonly lastFourDigits: string | null;
        readonly paymentMethodType: string;
        readonly paymentServiceType: string;
      };
      readonly state: ChargeState;
    } | null> | null;
    readonly createdAt: any;
    readonly id: string;
    readonly status: PaymentIntentStatus;
    readonly succeededAt: any | null;
  } | null> | null;
  readonly paymentMethod: {
    readonly cardBrand: string | null;
    readonly expirationDate: string | null;
    readonly id: string;
    readonly lastFourDigits: string | null;
    readonly paymentMethodType: string;
    readonly paymentServiceType: string;
    readonly paymentTerms: string | null;
  };
  readonly paymentNote: string;
  readonly purchaseOrderReference: string | null;
  readonly refunds: ReadonlyArray<{
    readonly amount: number;
    readonly createdAt: any;
    readonly id: string;
    readonly status: RefundStatus;
    readonly succeededAt: any | null;
  } | null> | null;
  readonly relatedInvoice: {
    readonly id: string;
    readonly invoiceNumber: string | null;
  } | null;
  readonly sentAt: any | null;
  readonly state: InvoiceState;
  readonly taxType: TaxType;
  readonly taxTypeNote: string;
  readonly timeOfPerformanceEnd: any;
  readonly timeOfPerformanceStart: any;
  readonly totalGrossAmount: number;
  readonly type: InvoiceType;
  readonly version: number;
  readonly " $fragmentSpreads": FragmentRefs<"CreateReverseInvoiceDialog_invoice" | "InitiateInvoicePaymentDialog_invoice" | "InvoiceHeader_invoice" | "InvoiceLineItemsTable_invoice" | "SendInvoiceDialog_invoice">;
  readonly " $fragmentType": "FinalInvoiceView_invoice";
};
export type FinalInvoiceView_invoice$key = {
  readonly " $data"?: FinalInvoiceView_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinalInvoiceView_invoice">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceNumber",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentMethodType",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "paymentServiceType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cardBrand",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastFourDigits",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "succeededAt",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinalInvoiceView_invoice",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceHeader_invoice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceLineItemsTable_invoice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitiateInvoicePaymentDialog_invoice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SendInvoiceDialog_invoice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateReverseInvoiceDialog_invoice"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "version",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceDate",
      "storageKey": null
    },
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOfPerformanceStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOfPerformanceEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxTypeNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessAddress",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "businessName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentTerms",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expirationDate",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchaseOrderReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerOrderNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceAttachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sha1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentSha1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentRenderedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "documentRenderedVersion",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalGrossAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentIntent",
      "kind": "LinkedField",
      "name": "paymentIntents",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        (v8/*: any*/),
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "canceledAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Charge",
          "kind": "LinkedField",
          "name": "charges",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "paid",
              "storageKey": null
            },
            (v8/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "disputed",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PaymentMethod",
              "kind": "LinkedField",
              "name": "paymentMethod",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                (v4/*: any*/),
                (v5/*: any*/),
                (v6/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Refund",
      "kind": "LinkedField",
      "name": "refunds",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        (v8/*: any*/),
        (v9/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invoice",
      "kind": "LinkedField",
      "name": "relatedInvoice",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};
})();

(node as any).hash = "3cc980dd8e6f5bc651a532d97125fb86";

export default node;
