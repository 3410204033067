import * as React from "react";
import {graphql} from "react-relay";
import {Outlet} from "react-router";
import CustomerAccountHeader from "./CustomerAccountHeader";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import CustomerViewQuery, {CustomerViewQuery as CustomerViewQueryType} from "../__relay_artifacts__/CustomerViewQuery.graphql";
import SecondaryNavigation from "./navigation/SecondaryNavigation";

type CustomerViewParams = {
    prepared: any
};

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type CustomerViewProps = CustomerViewParams & ConnectedProps<typeof connector>;

const CustomerView: React.FC<CustomerViewProps> = (props) => {

    const {customerAccount} = usePreloadedQuery<CustomerViewQueryType>(
        graphql`
            query CustomerViewQuery($id: ID!) {
                customerAccount(id: $id) {
                    id
                    customerName
                    ...CustomerAccountView_customerAccount
                    ...CustomerAccountHeader_customerAccount
                    ...CustomerContactsView_customerAccount
                    ...CustomerPaymentView_customerAccount
                }
            }
        `,
        props.prepared.customerViewQuery,
    );
    if (customerAccount == null) {
        return <p>Customer account not found</p>;
    }

    const breadcrumbNavigationItems = [
        {label: 'Customers', to: '/customers'},
        {label: customerAccount.customerName, to: '/customers/' + customerAccount.id},
    ]

    const secondaryNavigationItems = [
        {
            label: 'Customer',
            to: '/customers/' + customerAccount.id + '/account'
        },
        {
            label: 'Contacts',
            to: '/customers/' + customerAccount.id + '/contacts'
        },
        {
            label: 'Payment',
            to: '/customers/' + customerAccount.id + '/payment'
        },
    ]

    return (
        <>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <CustomerAccountHeader customerAccount={customerAccount}/>
            <SecondaryNavigation items={secondaryNavigationItems}/>
            <div className="max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                <Outlet context={customerAccount}/>
            </div>
        </>
    );
}

export default connector(CustomerView);

function prepare_CustomerView(params, relayEnvironment) {
    return {
        customerViewQuery: loadQuery(
            relayEnvironment,
            CustomerViewQuery,
            {
                id: params.id,
            },
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_CustomerView};
