/**
 * @generated SignedSource<<9e82c7b92f783a004e72db784f60573f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type TaxStatus = "EXEMPT" | "REGULAR" | "REVERSE_CHARGE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CustomerAccountView_customerAccount$data = {
  readonly businessAddress: {
    readonly addressLine1: string | null;
    readonly addressLine2: string | null;
    readonly businessName: string;
    readonly city: string;
    readonly country: string;
    readonly postalCode: string;
  } | null;
  readonly customerName: string;
  readonly customerNumber: string;
  readonly customerSince: any | null;
  readonly id: string;
  readonly preferredLanguage: string;
  readonly taxStatus: TaxStatus | null;
  readonly vatId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteCustomerDialog_customerAccount">;
  readonly " $fragmentType": "CustomerAccountView_customerAccount";
};
export type CustomerAccountView_customerAccount$key = {
  readonly " $data"?: CustomerAccountView_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerAccountView_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerAccountView_customerAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerSince",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "vatId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferredLanguage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessAddress",
      "kind": "LinkedField",
      "name": "businessAddress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "businessName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DeleteCustomerDialog_customerAccount"
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "e2606b2be2355708736ab2e20ba5f493";

export default node;
