import * as React from "react";
import {ReactElement} from "react";

type PanelProps = {
    headline?: string
    buttons?: ReactElement
    fullWidthChildren?: boolean
}

const Panel: React.FC<React.PropsWithChildren<PanelProps>> = ({headline, buttons, fullWidthChildren, children}) => {
    return (
        <div className="bg-white overflow-hidden shadow divide-y divide-gray-200">
            {(headline || buttons) && <div className="px-4 py-5 sm:px-6 mt-1 flex text-md text-gray-900 sm:mt-0">
                <div className="flex grow">
                    {headline && (
                        <h2 className="text-md font-medium text-gray-900">{headline}</h2>
                    )}
                </div>
                {buttons && <div className="flex justify-end">{buttons}</div>}
            </div>}
            <div className={fullWidthChildren !== true ? "px-4 py-5 sm:p-6" : ""}>
                {children}
            </div>
        </div>
    );
};

export default Panel;
