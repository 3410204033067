import * as React from "react";
import Link from "./navigation/Link";
import {useFragment} from "react-relay/hooks";
import {graphql} from "react-relay";

type CustomerAccountListItemProps = {
    customerAccount: any
}

const CustomerAccountListItem: React.FC<CustomerAccountListItemProps> = (props) => {
    const customerAccount = useFragment(
        graphql`
            fragment CustomerAccountListItem_customerAccount on CustomerAccount {
                id
                customerName
                customerNumber
            }
        `,
        props.customerAccount,
    );

    if (customerAccount === null) {
        return <></>;
    }

    return (
        <li>
            <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50">
                <div className="shrink-0">
                    <span className="inline-flex items-center justify-center h-6 w-6 rounded-full bg-gray-500">
                       <span className="text-xs font-medium leading-none text-white">{customerAccount.customerName.substr(0, 1)}</span>
                    </span>
                </div>

                <div className="flex-1 min-w-0">
                    <Link to={"/customers/" + customerAccount.id + "/account"} className="focus:outline-none">
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true"/>
                        <p className="text-sm font-medium text-gray-900">
                            {customerAccount.customerName}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                            {customerAccount.customerNumber}
                        </p>
                    </Link>
                </div>
            </div>
        </li>
    );
}

export default CustomerAccountListItem;
