/**
 * @generated SignedSource<<751b0f486d868c90da26559821f1ae6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettlementViewQuery$variables = {
  id: string;
};
export type SettlementViewQuery$data = {
  readonly settlement: {
    readonly cutOffAt: any;
    readonly documentsRenderedAt: any | null;
    readonly id: string;
    readonly invoiceIdentifier: string | null;
    readonly sections: ReadonlyArray<{
      readonly costCategoryTotals: ReadonlyArray<{
        readonly costCategory: {
          readonly description: {
            readonly de: string;
            readonly en: string;
          };
        };
        readonly total: number;
      }> | null;
      readonly lineItems: ReadonlyArray<{
        readonly amount: number;
        readonly annotation: {
          readonly de: string;
          readonly en: string;
        } | null;
        readonly description: {
          readonly de: string;
          readonly en: string;
        };
        readonly quantity: number | null;
        readonly timeOfPerformanceEnd: any | null;
        readonly timeOfPerformanceStart: any | null;
        readonly unitLabel: {
          readonly de: string;
          readonly en: string;
        } | null;
      }>;
      readonly name: {
        readonly de: string;
        readonly en: string;
      };
      readonly total: number;
    }>;
    readonly status: string;
    readonly title: {
      readonly de: string;
      readonly en: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"DeleteSettlementDialog_settlement" | "SettlementHeader_settlement">;
  } | null;
};
export type SettlementViewQuery = {
  response: SettlementViewQuery$data;
  variables: SettlementViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "en",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "de",
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "TranslatedString",
  "kind": "LinkedField",
  "name": "title",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cutOffAt",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "documentsRenderedAt",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceIdentifier",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "TranslatedString",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "TranslatedString",
  "kind": "LinkedField",
  "name": "description",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "SettlementLineItem",
  "kind": "LinkedField",
  "name": "lineItems",
  "plural": true,
  "selections": [
    (v10/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TranslatedString",
      "kind": "LinkedField",
      "name": "annotation",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOfPerformanceStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOfPerformanceEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "quantity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TranslatedString",
      "kind": "LinkedField",
      "name": "unitLabel",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "total",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettlementViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Settlement",
        "kind": "LinkedField",
        "name": "settlement",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SettlementHeader_settlement"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DeleteSettlementDialog_settlement"
          },
          (v2/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SettlementSection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CostCategoryTotal",
                "kind": "LinkedField",
                "name": "costCategoryTotals",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CostCategory",
                    "kind": "LinkedField",
                    "name": "costCategory",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettlementViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Settlement",
        "kind": "LinkedField",
        "name": "settlement",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccount",
            "kind": "LinkedField",
            "name": "customerAccount",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerName",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "SettlementSection",
            "kind": "LinkedField",
            "name": "sections",
            "plural": true,
            "selections": [
              (v9/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CostCategoryTotal",
                "kind": "LinkedField",
                "name": "costCategoryTotals",
                "plural": true,
                "selections": [
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CostCategory",
                    "kind": "LinkedField",
                    "name": "costCategory",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0defa322fb868c186298be446efda814",
    "id": null,
    "metadata": {},
    "name": "SettlementViewQuery",
    "operationKind": "query",
    "text": "query SettlementViewQuery(\n  $id: ID!\n) {\n  settlement(id: $id) {\n    ...SettlementHeader_settlement\n    ...DeleteSettlementDialog_settlement\n    id\n    title {\n      en\n      de\n    }\n    status\n    cutOffAt\n    documentsRenderedAt\n    invoiceIdentifier\n    sections {\n      name {\n        en\n        de\n      }\n      lineItems {\n        description {\n          en\n          de\n        }\n        annotation {\n          en\n          de\n        }\n        timeOfPerformanceStart\n        timeOfPerformanceEnd\n        quantity\n        unitLabel {\n          en\n          de\n        }\n        amount\n      }\n      costCategoryTotals {\n        total\n        costCategory {\n          description {\n            en\n            de\n          }\n          id\n        }\n      }\n      total\n    }\n  }\n}\n\nfragment DeleteSettlementDialog_settlement on Settlement {\n  id\n  title {\n    en\n  }\n  cutOffAt\n}\n\nfragment SettlementHeader_settlement on Settlement {\n  id\n  title {\n    en\n    de\n  }\n  customerAccount {\n    customerName\n    id\n  }\n  status\n}\n"
  }
};
})();

(node as any).hash = "5f3b5bdc6213afc8017e0b8996c42755";

export default node;
