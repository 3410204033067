/**
 * @generated SignedSource<<52035afdf642419f8c3e3c58b3a84c82>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetDefaultPaymentMethodDialog_customerAccount$data = {
  readonly customerName: string;
  readonly id: string;
  readonly " $fragmentType": "SetDefaultPaymentMethodDialog_customerAccount";
};
export type SetDefaultPaymentMethodDialog_customerAccount$key = {
  readonly " $data"?: SetDefaultPaymentMethodDialog_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"SetDefaultPaymentMethodDialog_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SetDefaultPaymentMethodDialog_customerAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "f81620887f4223881224612e380508bf";

export default node;
