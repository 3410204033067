/**
 * @generated SignedSource<<8245400341f7e7058367cc62ebaa8a1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionViewQuery$variables = {
  id: string;
};
export type SubscriptionViewQuery$data = {
  readonly subscription: {
    readonly activatedAt: any;
    readonly customerAccount: {
      readonly customerName: string;
      readonly id: string;
    };
    readonly customerOrderNotes: string | null;
    readonly expiresAt: any | null;
    readonly id: string;
    readonly orderedAt: any;
    readonly purchaseOrderReference: string | null;
    readonly state: string;
    readonly subscriptionService: {
      readonly id: string;
      readonly name: string;
    };
    readonly subscriptionServiceReference: string;
    readonly subscriptionServiceReferenceLabel: string | null;
    readonly subscriptionServiceReferenceUri: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"CancelSubscriptionDialog_subscription" | "SubscriptionHeader_subscription" | "TerminateSubscriptionDialog_subscription">;
  } | null;
};
export type SubscriptionViewQuery = {
  response: SubscriptionViewQuery$data;
  variables: SubscriptionViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "state",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "SubscriptionService",
  "kind": "LinkedField",
  "name": "subscriptionService",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionServiceReference",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionServiceReferenceLabel",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subscriptionServiceReferenceUri",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "orderedAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activatedAt",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "expiresAt",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "customerOrderNotes",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "purchaseOrderReference",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SubscriptionHeader_subscription"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TerminateSubscriptionDialog_subscription"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CancelSubscriptionDialog_subscription"
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccount",
            "kind": "LinkedField",
            "name": "customerAccount",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscriptionViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Subscription",
        "kind": "LinkedField",
        "name": "subscription",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccount",
            "kind": "LinkedField",
            "name": "customerAccount",
            "plural": false,
            "selections": [
              (v8/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/),
          (v3/*: any*/),
          (v5/*: any*/),
          (v7/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          (v13/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eef24417713a0db6fcd57ca9ed3d154e",
    "id": null,
    "metadata": {},
    "name": "SubscriptionViewQuery",
    "operationKind": "query",
    "text": "query SubscriptionViewQuery(\n  $id: ID!\n) {\n  subscription(id: $id) {\n    ...SubscriptionHeader_subscription\n    ...TerminateSubscriptionDialog_subscription\n    ...CancelSubscriptionDialog_subscription\n    id\n    state\n    subscriptionService {\n      name\n      id\n    }\n    subscriptionServiceReference\n    subscriptionServiceReferenceLabel\n    subscriptionServiceReferenceUri\n    customerAccount {\n      id\n      customerName\n    }\n    orderedAt\n    activatedAt\n    expiresAt\n    customerOrderNotes\n    purchaseOrderReference\n  }\n}\n\nfragment CancelSubscriptionDialog_subscription on Subscription {\n  id\n  subscriptionService {\n    name\n    id\n  }\n  subscriptionServiceReferenceLabel\n  customerAccount {\n    customerName\n    id\n  }\n}\n\nfragment SubscriptionHeader_subscription on Subscription {\n  id\n  subscriptionService {\n    name\n    id\n  }\n  customerAccount {\n    customerName\n    id\n  }\n}\n\nfragment TerminateSubscriptionDialog_subscription on Subscription {\n  id\n  subscriptionService {\n    name\n    id\n  }\n  subscriptionServiceReferenceLabel\n  customerAccount {\n    customerName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "bcf66c13262dea7943157998af100c36";

export default node;
