/**
 * @generated SignedSource<<63390c3ae35186a415c299bf2261cf12>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SettlementsViewSettlementsQuery$variables = {};
export type SettlementsViewSettlementsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SettlementList_query">;
};
export type SettlementsViewSettlementsQuery = {
  response: SettlementsViewSettlementsQuery$data;
  variables: SettlementsViewSettlementsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "searchTerm",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": "STATUS_DESC"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SettlementsViewSettlementsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "SettlementList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SettlementsViewSettlementsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "SettlementConnection",
        "kind": "LinkedField",
        "name": "settlements",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SettlementEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Settlement",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cutOffAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TranslatedString",
                    "kind": "LinkedField",
                    "name": "title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "en",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerAccount",
                    "kind": "LinkedField",
                    "name": "customerAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customerName",
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serviceIdentifier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "service",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "SubscriptionService",
                            "kind": "LinkedField",
                            "name": "subscriptionService",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v1/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "type": "Subscription",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "projectName",
                            "storageKey": null
                          }
                        ],
                        "type": "Project",
                        "abstractKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalNetAmount",
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "settlements(first:100,searchTerm:\"\",sort:\"STATUS_DESC\")"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "searchTerm",
          "sort"
        ],
        "handle": "connection",
        "key": "SettlementList_settlements",
        "kind": "LinkedHandle",
        "name": "settlements"
      }
    ]
  },
  "params": {
    "cacheID": "69f10d1fbe2ff373f426b2651ff93aa5",
    "id": null,
    "metadata": {},
    "name": "SettlementsViewSettlementsQuery",
    "operationKind": "query",
    "text": "query SettlementsViewSettlementsQuery {\n  ...SettlementList_query\n}\n\nfragment SettlementList_query on Query {\n  settlements(first: 100, searchTerm: \"\", sort: STATUS_DESC) {\n    totalCount\n    edges {\n      node {\n        id\n        status\n        createdAt\n        cutOffAt\n        title {\n          en\n        }\n        customerAccount {\n          customerName\n          id\n        }\n        serviceIdentifier\n        service {\n          __typename\n          ... on Subscription {\n            subscriptionService {\n              name\n              id\n            }\n          }\n          ... on Project {\n            projectName\n          }\n          id\n        }\n        totalNetAmount\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "030aa4f1b5e8c42fd6aedde73aabf51a";

export default node;
