import React, {Dispatch, Fragment, SetStateAction} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import Link from "./navigation/Link";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faXmark} from "@fortawesome/pro-solid-svg-icons";
import CabanaLogo from "./presentational/CabanaLogo";

type NavigationItem = {
    label: string
    path: string
}

type NavigationProps = {
    sidebarOpen: boolean
    setSidebarOpen: Dispatch<SetStateAction<boolean>>
    navigationItems: Array<NavigationItem>
    secondaryNavigationItems?: Array<NavigationItem>
}

const Navigation: React.FC<NavigationProps> = ({sidebarOpen, setSidebarOpen, navigationItems, secondaryNavigationItems}) => {
    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="fixed inset-0 flex z-40 lg:hidden" onClose={setSidebarOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75"/>
                    </Transition.Child>
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-700">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-in-out duration-300"
                                enterFrom="opacity-0"
                                enterTo="opacity-100"
                                leave="ease-in-out duration-300"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <div className="absolute top-0 right-0 -mr-12 pt-2">
                                    <button
                                        type="button"
                                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none"
                                        onClick={() => setSidebarOpen(false)}
                                    >
                                        <span className="sr-only">Close sidebar</span>
                                        <FontAwesomeIcon icon={faXmark} className="h-6 w-6 text-white" aria-hidden="true"/>
                                    </button>
                                </div>
                            </Transition.Child>
                            <div className="flex items-center shrink-0 px-6">
                                <CabanaLogo className="h-10 px-4"/>
                            </div>
                            <nav className="mt-5 shrink-0 h-full divide-y divide-gray-600 overflow-y-auto" aria-label="Sidebar">
                                <div className="space-y-1">
                                    {navigationItems.map(item => (
                                        <div key={item.path} className="px-4 hover:bg-gray-600 text-gray-400">
                                            <Link
                                                to={item.path}
                                                className={'group flex px-4 py-2 text-base leading-6 font-medium hover:text-white border-l-4 border-transparent'}
                                                activeClassName={'text-white border-l-4 border-red-500'}
                                                onClick={() => setSidebarOpen(false)}
                                            >{item.label}</Link>
                                        </div>
                                    ))}
                                </div>
                            </nav>
                        </div>
                    </Transition.Child>
                    <div className="shrink-0 w-14" aria-hidden="true">
                        {/* Dummy element to force sidebar to shrink to fit close icon */}
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:flex lg:shrink-0">
                <div className="flex flex-col w-64">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex flex-col grow bg-gray-700 pt-8 pb-4 overflow-y-auto">
                        <div className="flex items-center shrink-0 px-6">
                            <CabanaLogo className="h-10 px-4"/>
                        </div>
                        <nav className="mt-6 flex-1 flex flex-col divide-y divide-gray-600 overflow-y-auto" aria-label="Sidebar">
                            <div className="space-y-1">
                                {navigationItems.map(item => (
                                    <div key={item.path} className="px-4 hover:bg-gray-600 text-gray-400">
                                        <Link
                                            to={item.path}
                                            className={'group flex px-4 py-2 text-md leading-6 font-medium hover:text-white border-l-4 border-transparent'}
                                            activeClassName={'text-white border-l-4 border-red-500'}
                                        >{item.label}</Link>
                                    </div>
                                ))}
                            </div>
                            {secondaryNavigationItems && (
                                <div className="mt-6 pt-6 space-y-1">
                                    {secondaryNavigationItems.map((item) => (
                                        <div key={item.path} className="px-4 hover:bg-gray-600 text-gray-400">
                                            <Link
                                                to={item.path}
                                                className={'group flex px-4 py-2 text-md leading-6 font-medium hover:text-white border-l-4 border-transparent'}
                                                activeClassName={'text-white border-l-4 border-red-500'}
                                            >{item.label}</Link>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navigation;
