import * as React from "react";
import Panel from "./presentational/Panel";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import {usePreloadedQuery} from "react-relay/hooks";
import {graphql} from "react-relay";
import {useOutletContext} from "react-router";
import {ConfigurationContractsViewQuery} from "../__relay_artifacts__/ConfigurationContractsViewQuery.graphql";
import CreateTermsAndConditionsDocumentDialog from "./dialog/CreateTermsAndConditionsDocumentDialog";
import ActionButton from "./presentational/button/ActionButton";
import {FormattedDate} from "react-intl";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ConfigurationContractsViewProps = Props & ConnectedProps<typeof connector>;

const ConfigurationContractsView: React.FC<ConfigurationContractsViewProps> = (props) => {
    const queryRef: any = useOutletContext();
    const {termsAndConditionsDocuments} = usePreloadedQuery<ConfigurationContractsViewQuery>(
        graphql`
            query ConfigurationContractsViewQuery {
                termsAndConditionsDocuments {
                    totalCount
                    edges {
                        node {
                            id
                            code
                            title
                            version
                            uri
                            startDate
                            published
                            publishDate
                        }
                    }
                }
            }
        `,
        queryRef,
    );

    const {dispatchShowModal, dispatchHideModal} = props
    const showCreateTermsAndConditionsDocumentDialog = () => {
        dispatchShowModal({
            body: (<CreateTermsAndConditionsDocumentDialog handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <Panel headline="Terms and Conditions">
            <table className="min-w-full divide-y divide-gray-200 border border-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider hidden sm:visible"
                        >
                            ID
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Code
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Title
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Version
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            URI
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Start Date
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {termsAndConditionsDocuments && termsAndConditionsDocuments.edges.map((edge, index) => {
                        if (!edge.node) {
                            return (<></>);
                        }
                        const {code, id, title, uri, version} = edge.node;

                        return (
                            <tr key={index} className={index % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800 hidden sm:visible">{id}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{code}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{title}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{version}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800">{uri}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-800"><FormattedDate value={edge.node.startDate} day="2-digit" month="2-digit" year="numeric"/></td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="text-center py-10">
                <ActionButton onClick={e => showCreateTermsAndConditionsDocumentDialog()} label="Create terms and conditions"/>
            </div>
        </Panel>
    )
}

export default connector(ConfigurationContractsView);
