import * as React from "react"
import {commitMutation, graphql} from "react-relay";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import ActionButton from "./presentational/button/ActionButton";
import {FieldArray, Form, Formik} from "formik";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {InvoiceLineItemsTableUpdateInvoiceLineItemsMutation} from "../__relay_artifacts__/InvoiceLineItemsTableUpdateInvoiceLineItemsMutation.graphql";
import Dinero from "dinero.js";
import TableHeader from "./presentational/table/TableHeader";
import TableData, {TextAlignment} from "./presentational/table/TableData";
import EditableTableDataField, {FieldDataType} from "./form/EditableTableDataField";
import {faTrashCan} from "@fortawesome/pro-regular-svg-icons";
import {faCaretDown, faCaretUp} from "@fortawesome/pro-solid-svg-icons";
import Warning from "./presentational/Warning";

type InvoiceLineItemsTableProps = {
    queryRef: any // FIXME: Any type we can define here? It's actually: Readonly<{ ' $data'?: unknown; ' $fragmentRefs': unknown; }> | null
}

const InvoiceLineItemsTable: React.FC<InvoiceLineItemsTableProps> = (props) => {
    const relayEnvironment = useRelayEnvironment()
    const invoice = useFragment(
        graphql`
            fragment InvoiceLineItemsTable_invoice on Invoice {
                id
                type
                language
                taxType
                lineItems {
                    quantity
                    unitLabel
                    description
                    price
                    taxRatePercentage
                }
            }
        `
        , props.queryRef
    )

    const handleSubmission = (input, actions) => {
        commitMutation<InvoiceLineItemsTableUpdateInvoiceLineItemsMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation InvoiceLineItemsTableUpdateInvoiceLineItemsMutation(
                        $input: UpdateInvoiceLineItemsInput!
                    ) {
                        updateInvoiceLineItems(input: $input) {
                            id
                            lineItems {
                                quantity
                                unitLabel
                                description
                                price
                                taxRatePercentage
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    actions.setSubmitting(false)
                    actions.resetForm({values: response.updateInvoiceLineItems})
                },
                onError: (error) => {
                    console.error(error);
                    actions.setSubmitting(false)
                    actions.resetForm()
                }
            }
        )
    }

    const initialValues = {
        id: invoice.id,
        lineItems: invoice.lineItems
    };

    const lineItemsValidationSchema = yup.object().shape({
        lineItems: yup.array()
            .of(
                yup.object().shape({
                    description: yup.string()
                        .max(255, 'This description is too long')
                        .matches(/^[-+&%!/#"*,.€ \p{L}\p{P}\d]{1,255}$/u, 'Invalid description')
                        .required('The description cannot be empty'),
                    unitLabel: yup.string()
                        .max(20, 'Too long')
                        .matches(/^[-+&%!/#"*,.€ \p{L}\p{P}\d]{1,255}$/u, 'Invalid unit label')
                        .nullable()
                })
            )
    });

    let totalNetAmount = Dinero({amount: 0})
    let totalTaxAmount = Dinero({amount: 0})
    let totalGrossAmount = Dinero({amount: 0})

    // This function must calculate the same way PHP does, see Flownative\Cabana\Domain\Projection\Invoice::__construct()
    const calculateAmounts = (lineItems: any[]) => {
        totalNetAmount = Dinero({amount: 0})
        totalTaxAmount = Dinero({amount: 0})
        totalGrossAmount = Dinero({amount: 0})
        let netAmountsByTaxRatePercentage = {}

        if (lineItems.length > 0) {
            lineItems.forEach((lineItem) => {
                if (lineItem.price !== null) {
                    let quantity = parseFloat(lineItem.quantity)
                    if (isNaN(quantity)) {
                        quantity = 1;
                    }
                    let priceInt = parseInt(lineItem.price) ?? 0;
                    if (isNaN(priceInt)) {
                        priceInt = 0;
                    }
                    const price = Dinero({amount: priceInt})
                    const netAmount = price.multiply(quantity)

                    totalNetAmount = totalNetAmount.add(netAmount)
                    if (lineItem.taxRatePercentage !== null && invoice.taxType === "GROSS") {
                        if (netAmountsByTaxRatePercentage[String(lineItem.taxRatePercentage)]) {
                            netAmountsByTaxRatePercentage[String(lineItem.taxRatePercentage)] = netAmountsByTaxRatePercentage[String(lineItem.taxRatePercentage)].add(netAmount)
                        } else {
                            netAmountsByTaxRatePercentage[String(lineItem.taxRatePercentage)] = netAmount
                        }
                    }
                }
            })
            Object.keys(netAmountsByTaxRatePercentage).forEach((taxRatePercentageString) => {
                const taxRatePercentage = parseFloat(taxRatePercentageString)
                const netAmount = netAmountsByTaxRatePercentage[taxRatePercentage]
                if (netAmount) {
                    totalTaxAmount = totalTaxAmount.add(netAmount.multiply(taxRatePercentage / 100))
                }
            })
            totalGrossAmount = totalNetAmount.add(totalTaxAmount)
        }
    }

    calculateAmounts(invoice.lineItems)

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={lineItemsValidationSchema}
            onSubmit={(values, actions) => {
                handleSubmission(values, actions)
            }}
        >
            {({values, isSubmitting, dirty, submitForm}) => (
                <Form>
                    {invoice.type === 'REVERSE' && (
                        <div className="min-w-full bg-yellow-50 py-2 px-4 mb-4 text-center border-yellow-200 border-l-4">
                            <Warning>Be careful: According to bookkeeping standards only few modifications, if any, are allowed for reverse invoices.</Warning>
                        </div>
                    )}
                    <table className="min-w-full divide-y divide-gray-300 table-auto">
                        <thead>
                            <tr>
                                <TableHeader/>
                                <TableHeader>Description</TableHeader>
                                <TableHeader>Quantity</TableHeader>
                                <TableHeader>Unit</TableHeader>
                                <TableHeader>Tax Rate</TableHeader>
                                <TableHeader textAlignment={TextAlignment.Right}>Unit Price</TableHeader>
                                <TableHeader/>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            <FieldArray name="lineItems">
                                {({remove, push, move}) => {
                                    calculateAmounts(values.lineItems)
                                    return (
                                        <>
                                            {values.lineItems.length > 0 &&
                                                values.lineItems.map((lineItem, index) => {
                                                    return (
                                                        <tr key={index} className="hover:bg-gray-100 focus-within:bg-gray-100 border-b group">
                                                            <TableData>
                                                                <button type="button" disabled={isSubmitting} tabIndex={-1} onClick={(event) => {
                                                                    move(index, index - 1);
                                                                    event.currentTarget.blur()
                                                                }} className={"px-4 text-sm font-medium text-gray-700 invisible" + (index > 0 ? " group-hover:visible" : "")}>
                                                                    <FontAwesomeIcon icon={faCaretUp}/>
                                                                </button>

                                                                <button type="button" disabled={isSubmitting} tabIndex={-1} onClick={(event) => {
                                                                    move(index, index + 1);
                                                                    event.currentTarget.blur()
                                                                }} className={"px-4 text-sm font-medium text-gray-700 invisible" + (index < (values.lineItems.length - 1) ? " group-hover:visible" : "")}>
                                                                    <FontAwesomeIcon icon={faCaretDown}/>
                                                                </button>
                                                            </TableData>
                                                            <EditableTableDataField name={`lineItems.${index}.description`} value={lineItem.description ?? ''} className="w-full shadow-none"/>
                                                            <EditableTableDataField name={`lineItems.${index}.quantity`} value={lineItem.quantity} fieldDataType={FieldDataType.number} className="w-20"/>
                                                            <EditableTableDataField name={`lineItems.${index}.unitLabel`} value={lineItem.unitLabel ?? ''} className="w-20"/>
                                                            {invoice.taxType === "GROSS" && <EditableTableDataField name={`lineItems.${index}.taxRatePercentage`} value={lineItem.taxRatePercentage} fieldDataType={FieldDataType.percentage} textAlignment={TextAlignment.Right} className="w-20"/>}
                                                            {invoice.taxType !== "GROSS" && <TableData textAlignment={TextAlignment.Right} className="w-20"/>}
                                                            <EditableTableDataField name={`lineItems.${index}.price`} value={lineItem.price} fieldDataType={FieldDataType.currency} textAlignment={TextAlignment.Right} className="w-20"/>
                                                            <TableData>
                                                                <button type="button" disabled={isSubmitting} tabIndex={-1} onClick={() => remove(index)} className="py-3 px-4 text-sm font-medium text-gray-700 invisible group-hover:visible">
                                                                    <FontAwesomeIcon icon={faTrashCan}/>
                                                                </button>
                                                            </TableData>
                                                        </tr>
                                                    )
                                                })}
                                            <tr>
                                                <td/>
                                                <TableData colSpan={6}>
                                                    <ActionButton label="Add item" tabIndex={-1} additionalClassname="my-4" onClick={(event) => {
                                                        push({
                                                            quantity: null,
                                                            unitLabel: '',
                                                            description: '',
                                                            taxRatePercentage: invoice.taxType === "GROSS" ? 19.0 : 0.0,
                                                            price: null
                                                        })
                                                    }}/>

                                                    <ActionButton isSubmitting={isSubmitting} disabled={!dirty} visible={dirty} label="Save"/>
                                                </TableData>
                                            </tr>
                                        </>
                                    )
                                }}
                            </FieldArray>
                        </tbody>
                        <tfoot>
                            <tr>
                                <TableData colSpan={5} textAlignment={TextAlignment.Right} className="">Subtotal</TableData>
                                <TableData textAlignment={TextAlignment.Right} className="pl-8">{totalNetAmount.toFormat()}</TableData>
                            </tr>
                            <tr>
                                <TableData colSpan={5} textAlignment={TextAlignment.Right} className="">Tax</TableData>
                                <TableData textAlignment={TextAlignment.Right} className="pl-8">{totalTaxAmount.toFormat()}</TableData>
                            </tr>
                            <tr>
                                <TableData colSpan={5} textAlignment={TextAlignment.Right} className="font-semibold">Total</TableData>
                                <TableData textAlignment={TextAlignment.Right} className="pl-8 font-semibold">{totalGrossAmount.toFormat()}</TableData>
                            </tr>
                        </tfoot>
                    </table>
                </Form>
            )}
        </Formik>
    )
}

export default InvoiceLineItemsTable;
