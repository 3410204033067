import * as React from "react";
import {useState} from "react";
import {commitMutation, graphql} from "react-relay";
import Panel from "./presentational/Panel";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import SecondaryNavigation from "./navigation/SecondaryNavigation";
import {loadQuery, usePreloadedQuery, useRelayEnvironment} from "react-relay/hooks";
import type {SettlementViewQuery as SettlementViewQueryType} from "../__relay_artifacts__/SettlementViewQuery.graphql";
import SettlementViewQuery from "../__relay_artifacts__/SettlementViewQuery.graphql";
import SettlementHeader from "./SettlementHeader";
import {useNavigate, useParams} from "react-router";
import ActionButton from "./presentational/button/ActionButton";
import {faFilePdf} from "@fortawesome/pro-solid-svg-icons";
import {SettlementViewDownloadPdfMutation} from "../__relay_artifacts__/SettlementViewDownloadPdfMutation.graphql";
import Dinero from "dinero.js";
import {useIntl} from "react-intl";
import {SettlementViewCreateInvoiceMutation} from "../__relay_artifacts__/SettlementViewCreateInvoiceMutation.graphql";
import {SettlementViewFinalizeSettlementMutation} from "../__relay_artifacts__/SettlementViewFinalizeSettlementMutation.graphql";
import DeleteSettlementDialog from "./dialog/DeleteSettlementDialog";
import {hideModal, showModal} from "../store/actions";
import { connect, ConnectedProps } from "react-redux";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type SettlementViewParams = {
    prepared: any
};
type SettlementViewProps = SettlementViewParams & ConnectedProps<typeof connector>;
enum Language {
    en = "en",
    de = "de",
}

const SettlementView: React.FC<SettlementViewProps> = (props) => {

    const {dispatchShowModal, dispatchHideModal} = props;
    const {language: languageValue} = useParams<"language">();
    const [isCreatingInvoice, setIsCreatingInvoice] = useState(false);
    const [isFinalizingSettlement, setIsFinalizingSettlement] = useState(false);
    const navigate = useNavigate();
    let language = (languageValue === 'de' ? Language.de : Language.en);
    const intl = useIntl();

    const translate = (translatedString: { en: string, de: string } | null) => {
        if (!translatedString) {
            return null;
        }
        return language === Language.de ? translatedString.de : translatedString.en;
    }

    const {settlement} = usePreloadedQuery<SettlementViewQueryType>(
        graphql`
            query SettlementViewQuery($id: ID!) {
                settlement(id: $id) {
                    ...SettlementHeader_settlement
                    ...DeleteSettlementDialog_settlement
                    id
                    title {
                        en
                        de
                    }
                    status
                    cutOffAt
                    documentsRenderedAt
                    invoiceIdentifier
                    sections {
                        name {
                            en
                            de
                        }
                        lineItems {
                            description {
                                en
                                de
                            }
                            annotation {
                                en
                                de
                            }
                            timeOfPerformanceStart
                            timeOfPerformanceEnd
                            quantity
                            unitLabel {
                                en
                                de
                            }
                            amount
                        }
                        costCategoryTotals {
                            total
                            costCategory {
                                description {
                                    en
                                    de
                                }
                            }
                        }
                        total
                    }
                }
            }
        `,
        props.prepared.settlementViewQuery
    );

    const relayEnvironment = useRelayEnvironment();

    const handleCreateInvoice = (settlementIdentifier: string, setIsCreatingInvoice) => {
        setIsCreatingInvoice(true)
        commitMutation<SettlementViewCreateInvoiceMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation SettlementViewCreateInvoiceMutation(
                        $settlementIdentifier: String!
                    ) {
                        createInvoiceFromSettlement(
                            input: {
                                settlementIdentifier: $settlementIdentifier
                            }
                        ) {
                            id
                            invoiceNumber
                        }
                    }
                `,
                variables: {
                    settlementIdentifier: settlementIdentifier
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    setIsCreatingInvoice(false)
                    navigate('/invoices/' + response.createInvoiceFromSettlement?.id + '/edit')
                },
                onError: (error) => {
                    console.error(error);
                    setIsCreatingInvoice(false)
                }
            }
        )
    }
    const handleFinalizeSettlement = (settlementIdentifier: string, setIsFinalizingSettlement) => {
        setIsFinalizingSettlement(true)
        commitMutation<SettlementViewFinalizeSettlementMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation SettlementViewFinalizeSettlementMutation(
                        $settlementIdentifier: String!
                    ) {
                        finalizeSettlement(
                            input: {
                                settlementIdentifier: $settlementIdentifier
                            }
                        ) {
                            id
                            status
                            updatedAt
                        }
                    }
                `,
                variables: {
                    settlementIdentifier: settlementIdentifier
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    setIsCreatingInvoice(false)
                },
                onError: (error) => {
                    console.error(error);
                    setIsCreatingInvoice(false)
                }
            }
        )
    }
    const handleDownloadPdf = (settlementIdentifier: string, language: Language) => {
        commitMutation<SettlementViewDownloadPdfMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation SettlementViewDownloadPdfMutation(
                        $settlementIdentifier: String!
                        $language: String!
                    ) {
                        downloadSettlementPdf(
                            input: {
                                settlementIdentifier: $settlementIdentifier
                                language: $language
                            }
                        ) {
                            uri
                            filename
                        }
                    }
                `,
                variables: {
                    settlementIdentifier: settlementIdentifier,
                    language: language
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    if (response.downloadSettlementPdf?.uri) {
                        window.location.href = response.downloadSettlementPdf?.uri;
                    }
                },
                onError: (error) => {
                    console.error(error);
                }
            }
        )
    }

    const showDeleteSettlementDialog = () => {
        dispatchShowModal({
            body: (<DeleteSettlementDialog queryRef={settlement} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    if (settlement == null) {
        return <p>Settlement not found</p>;
    }

    const breadcrumbNavigationItems = [
        {label: 'Settlements', to: '/settlements'},
        {label: translate(settlement.title) ?? '', to: '/settlements/' + settlement.id},
    ]
    const secondaryNavigationItems = [
        {
            label: 'English',
            path: '/settlements/:id/:language',
            to: '/settlements/' + settlement.id + '/en',
            exact: true
        },
        {
            label: 'German',
            path: '/settlements/:id/:language',
            to: '/settlements/' + settlement.id + '/de',
            exact: true
        },
    ]

    return (
        <article>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <SettlementHeader settlement={settlement} language={language}/>
            <SecondaryNavigation items={secondaryNavigationItems}/>
            <div className="max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                <Panel headline="Settlement" buttons={
                    <>
                        {settlement.invoiceIdentifier && (<ActionButton label="View Invoice" onClick={() => {
                            navigate("/invoices/" + settlement.invoiceIdentifier)
                        }}/>)}
                        <ActionButton icon={faFilePdf} iconSize={"lg"} disabled={!settlement.documentsRenderedAt} onClick={() => {
                            handleDownloadPdf(settlement.id, language)
                        }}/>
                        {settlement.status === 'OPEN' && (<ActionButton label="Finalize" disabled={isFinalizingSettlement} isSubmitting={isFinalizingSettlement} onClick={() => {
                            handleFinalizeSettlement(settlement.id, setIsFinalizingSettlement)
                        }}/>)}
                        {settlement.status === 'FINALIZED' && (<ActionButton label="Create invoice" disabled={isCreatingInvoice} isSubmitting={isCreatingInvoice} onClick={() => {
                            handleCreateInvoice(settlement.id, setIsCreatingInvoice)
                        }}/>)}
                    </>
                }>
                    <ul>
                        {settlement.sections.map(
                            (section, index) => {
                                const sectionTotal = Dinero({amount: section.total})
                                return (
                                    <li key={index}>
                                        <table className="min-w-full divide-y divide-gray-300">
                                            <thead>
                                                <tr>
                                                    <td colSpan={6} className="sm:whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900sm:pl-6">
                                                        <h3 className="font-bold">{translate(section.name)}</h3>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="sm:whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-700 sm:pl-6"
                                                    >
                                                        {language === 'de' ? 'Beschreibung' : 'Description'}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700 hidden sm:table-cell"
                                                    >
                                                        {language === 'de' ? 'Zeitraum' : 'Period'}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700 text-right hidden sm:table-cell"
                                                    >
                                                        {language === 'de' ? 'Menge' : 'Quantity'}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700 hidden sm:table-cell"
                                                    >
                                                        {language === 'de' ? 'Einheit' : 'Unit'}
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-700 text-right"
                                                    >
                                                        {language === 'de' ? 'Betrag' : 'Amount'}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody className="bg-white">
                                                {section.lineItems.map((lineItem, index2) => {
                                                    const amount = Dinero({amount: lineItem.amount})
                                                    const formattedTimePerformanceStart = lineItem.timeOfPerformanceStart && intl.formatDate(lineItem.timeOfPerformanceStart, {day: '2-digit', month: '2-digit'});
                                                    const formattedTimePerformanceEnd = lineItem.timeOfPerformanceEnd && intl.formatDate(lineItem.timeOfPerformanceEnd, {day: '2-digit', month: '2-digit'});

                                                    return (
                                                        <tr key={index2}>
                                                            <td className="align-top py-2 pl-4 pr-3 text-sm text-gray-700 sm:pl-6">
                                                                {translate(lineItem.description)}
                                                                {lineItem.annotation && (
                                                                    <span className="text-gray-500 italic"><br/>{translate(lineItem.annotation)}</span>
                                                                )}
                                                            </td>
                                                            <td className="align-top whitespace-nowrap px-2 py-2 text-sm text-gray-700 hidden sm:table-cell">
                                                                {formattedTimePerformanceStart}
                                                                {formattedTimePerformanceStart !== formattedTimePerformanceEnd ? '- ' + formattedTimePerformanceEnd : ''}
                                                            </td>
                                                            <td className="align-top whitespace-nowrap px-2 py-2 text-sm text-gray-700 text-right hidden sm:table-cell">{lineItem.quantity}</td>
                                                            <td className="align-top px-2 py-2 text-sm text-gray-700 hidden sm:table-cell">{translate(lineItem.unitLabel)}</td>
                                                            <td className="align-top whitespace-nowrap px-2 py-2 text-sm text-gray-700 text-right">{amount.toFormat()}</td>
                                                        </tr>
                                                    )
                                                })}
                                                {section.costCategoryTotals && section.costCategoryTotals.map(
                                                    (costCategoryTotal, costCategoryTotalsIndex) => {
                                                        const total = Dinero({amount: costCategoryTotal.total})
                                                        return (
                                                            <tr key={costCategoryTotalsIndex}>
                                                                <td colSpan={4} className={"py-2 pl-4 pr-3 text-sm text-gray-700 sm:pl-6" + (costCategoryTotalsIndex === 0 ? " border-t-2" : "")}>
                                                                    {language === 'de' ? 'Summe' : 'Subtotal'} {translate(costCategoryTotal.costCategory.description)}
                                                                </td>
                                                                <td className={"whitespace-nowrap px-2 py-2 text-sm text-gray-700 text-right" + (costCategoryTotalsIndex === 0 ? " border-t-2" : "")}>
                                                                    {total.toFormat()}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                )}
                                            </tbody>
                                            <tfoot>
                                                <tr>
                                                    <td colSpan={4} className="sm:whitespace-nowrap py-2 pl-4 pr-3 text-sm text-gray-900 font-semibold sm:pl-6 border-b-4">
                                                        {language === 'de' ? 'Gesamtsumme' : 'Total'} {translate(section.name)}
                                                    </td>
                                                    <td className="whitespace-nowrap px-2 py-2 text-sm text-gray-900 font-semibold text-right border-b-4">{sectionTotal.toFormat()}</td>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    </li>
                                )
                            }
                        )}
                    </ul>
                </Panel>
                <div className="grid grid-cols-1 gap-4 place-items-end">
                    {settlement.status !== 'INVOICED' && (<ActionButton onClick={showDeleteSettlementDialog} label="Delete Settlement"/>)}
                </div>
            </div>
        </article>
    );
}

export default connector(SettlementView);

function prepare_SettlementView(params, relayEnvironment) {
    return {
        settlementViewQuery: loadQuery(
            relayEnvironment,
            SettlementViewQuery,
            {
                id: params.id,
                language: params.language
            },
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_SettlementView};
