import * as React from "react";

type EditablePropertiesProps = {
    label: string
    condensed?: boolean
}

const DataListItem: React.FC<React.PropsWithChildren<EditablePropertiesProps>> = ({label, condensed, children}) => {
    return (
        <div className={"group sm:grid sm:grid-cols-3 sm:gap-4 " + (condensed ? "py-1" : "py-4 sm:py-5")}>
            <dt className={"font-medium text-gray-500 text-nowrap " + (condensed ? "text-xs" : "text-sm")}>{label}</dt>
            <dd className={"mt-1 flex text-gray-900 sm:col-span-2 sm:mt-0 " + (condensed ? "text-xs" : "text-sm")}>
                <span className="grow">{children}</span>
            </dd>
        </div>
    )
}

export default DataListItem;
