/**
 * @generated SignedSource<<1d83528a90203a444a05cf70506034b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerAccountsPaginationQuery$variables = {
  after?: string | null;
  first?: number | null;
  searchTerm?: string | null;
};
export type CustomerAccountsPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CustomerAccountList_query">;
};
export type CustomerAccountsPaginationQuery = {
  response: CustomerAccountsPaginationQuery$data;
  variables: CustomerAccountsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "sort",
    "value": "CUSTOMER_SINCE_DESC"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerAccountsPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "CustomerAccountList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerAccountsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "CustomerAccountConnection",
        "kind": "LinkedField",
        "name": "customerAccounts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccountEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerAccount",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "customerNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "searchTerm",
          "sort"
        ],
        "handle": "connection",
        "key": "CustomerAccountList_customerAccounts",
        "kind": "LinkedHandle",
        "name": "customerAccounts"
      }
    ]
  },
  "params": {
    "cacheID": "7ec5733eafa3b8cbe76accaba5e1733f",
    "id": null,
    "metadata": {},
    "name": "CustomerAccountsPaginationQuery",
    "operationKind": "query",
    "text": "query CustomerAccountsPaginationQuery(\n  $after: String\n  $first: Int = 100\n  $searchTerm: String = \"\"\n) {\n  ...CustomerAccountList_query_2s884C\n}\n\nfragment CustomerAccountListItem_customerAccount on CustomerAccount {\n  id\n  customerName\n  customerNumber\n}\n\nfragment CustomerAccountList_query_2s884C on Query {\n  customerAccounts(first: $first, after: $after, searchTerm: $searchTerm, sort: CUSTOMER_SINCE_DESC) {\n    totalCount\n    edges {\n      node {\n        ...CustomerAccountListItem_customerAccount\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc6f9ea248bc514424cb4a508e28463d";

export default node;
