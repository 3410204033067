/**
 * @generated SignedSource<<26ca89027f504a6b2c80a33d7b741c38>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateCustomerAccountInput = {
  customerName: string;
};
export type CreateCustomerDialogMutation$variables = {
  input: CreateCustomerAccountInput;
};
export type CreateCustomerDialogMutation$data = {
  readonly createCustomerAccount: {
    readonly customerName: string;
    readonly customerNumber: string;
    readonly customerSince: any | null;
    readonly id: string;
  };
};
export type CreateCustomerDialogMutation = {
  response: CreateCustomerDialogMutation$data;
  variables: CreateCustomerDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CustomerAccount",
    "kind": "LinkedField",
    "name": "createCustomerAccount",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerNumber",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerSince",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateCustomerDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateCustomerDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8743ea0396e996600cb8c112a7ef2e26",
    "id": null,
    "metadata": {},
    "name": "CreateCustomerDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CreateCustomerDialogMutation(\n  $input: CreateCustomerAccountInput!\n) {\n  createCustomerAccount(input: $input) {\n    id\n    customerName\n    customerNumber\n    customerSince\n  }\n}\n"
  }
};
})();

(node as any).hash = "99aaf81151c20edaaec28f76dbd48076";

export default node;
