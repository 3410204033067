/**
 * @generated SignedSource<<fe7fd8bcce36de32bcd5869919e8435c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCustomerOrderNotesInput = {
  customerOrderNotes: string;
  subscriptionIdentifier: string;
};
export type SubscriptionViewUpdateCustomerOrderNotesMutation$variables = {
  input: UpdateCustomerOrderNotesInput;
};
export type SubscriptionViewUpdateCustomerOrderNotesMutation$data = {
  readonly updateCustomerOrderNotes: {
    readonly customerOrderNotes: string | null;
    readonly id: string;
  };
};
export type SubscriptionViewUpdateCustomerOrderNotesMutation = {
  response: SubscriptionViewUpdateCustomerOrderNotesMutation$data;
  variables: SubscriptionViewUpdateCustomerOrderNotesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "updateCustomerOrderNotes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerOrderNotes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionViewUpdateCustomerOrderNotesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscriptionViewUpdateCustomerOrderNotesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "335191cb4360afe26b61acf988e5e31b",
    "id": null,
    "metadata": {},
    "name": "SubscriptionViewUpdateCustomerOrderNotesMutation",
    "operationKind": "mutation",
    "text": "mutation SubscriptionViewUpdateCustomerOrderNotesMutation(\n  $input: UpdateCustomerOrderNotesInput!\n) {\n  updateCustomerOrderNotes(input: $input) {\n    id\n    customerOrderNotes\n  }\n}\n"
  }
};
})();

(node as any).hash = "384cbb233692aa0d50e3f619798c9523";

export default node;
