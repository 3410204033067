import * as React from "react";
import Link from "./Link";

type NavigationItem = {
    label: string
    to: string
}

type SecondaryNavigationProps = {
    items: Array<NavigationItem>
}

const SecondaryNavigation: React.FC<SecondaryNavigationProps> = ({items}) => {
    const regularClassName = "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm uppercase"
    const activeClassName = "border-red-500 hover:border-red-400 text-red-600 hover:text-red-500 whitespace-nowrap py-4 px-1 border-b-4 font-medium text-sm"

    return (
        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {items.map((item, index) => (
                    <Link key={index} to={item.to} activeClassName={activeClassName} className={regularClassName}>
                        {item.label}
                    </Link>
                ))}
            </nav>
        </div>
    )
}

export default SecondaryNavigation;
