/**
 * @generated SignedSource<<ae819049fc48e029aba668b45b5d9bdc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ProjectBillingMode = "FIXED_FEE" | "NON_BILLABLE" | "TIME_AND_MATERIALS" | "%future added value";
export type UpdateProjectBillingInput = {
  billingMode: ProjectBillingMode;
  fixedFee?: number | null;
  hourlyRate?: number | null;
  id: string;
};
export type ProjectDetailsViewUpdateProjectBillingMutation$variables = {
  input: UpdateProjectBillingInput;
};
export type ProjectDetailsViewUpdateProjectBillingMutation$data = {
  readonly updateProjectBilling: {
    readonly billingMode: ProjectBillingMode;
    readonly fixedFee: number | null;
    readonly hourlyRate: number;
    readonly id: string;
  };
};
export type ProjectDetailsViewUpdateProjectBillingMutation = {
  response: ProjectDetailsViewUpdateProjectBillingMutation$data;
  variables: ProjectDetailsViewUpdateProjectBillingMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "updateProjectBilling",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "billingMode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "hourlyRate",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "fixedFee",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectDetailsViewUpdateProjectBillingMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectDetailsViewUpdateProjectBillingMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "010ebf04e3647fc0d7d834ffb01e5c6b",
    "id": null,
    "metadata": {},
    "name": "ProjectDetailsViewUpdateProjectBillingMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectDetailsViewUpdateProjectBillingMutation(\n  $input: UpdateProjectBillingInput!\n) {\n  updateProjectBilling(input: $input) {\n    id\n    billingMode\n    hourlyRate\n    fixedFee\n  }\n}\n"
  }
};
})();

(node as any).hash = "7175887e9a49428312dbf762d741c5b0";

export default node;
