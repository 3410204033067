import React, {Suspense, useEffect, useState, useTransition} from "react";

import {useParams} from "react-router";

import {useRelayEnvironment} from "react-relay";
import {Environment} from "relay-runtime";
import Waiting from "./presentational/Waiting";

const SUSPENSE_CONFIG = {timeoutMs: 5000};

type PrepareFunction = (params: object, relayEnvironment: Environment) => any;

export declare function prepare(params: object, relayEnvironment: Environment);

type TransitioningContainerProps = {
    component: any,
    prepare: PrepareFunction,
    innerProps?: object
};

const TransitioningContainer: React.FC<TransitioningContainerProps> = (props) => {
    // Improve the route transition UX by delaying transitions: show the previous route entry
    // for a brief period while the next route is being prepared. See
    // <https://reactjs.org/docs/concurrent-mode-patterns.html#transitions
    //
    // Ignore TS warning about useTransition() argument, because the TS definition seems to be
    // wrong in the current React 18 alpha
    // @ts-ignore
    const [, startTransition] = useTransition(SUSPENSE_CONFIG);
    const [visibleComponent, setVisibleComponent] = useState(<Waiting/>);
    const params = useParams();
    const environment = useRelayEnvironment();
    const {prepare, innerProps} = props;
    const Component = props.component;

    // On mount subscribe for route changes
    useEffect(() => {
        startTransition(() => {
            const prepared = prepare(params, environment);
            setVisibleComponent(<Component prepared={prepared} params={params} {...innerProps}  />)
        });
    }, [params, Component, innerProps, environment, prepare]);

    return (
        <Suspense fallback={<Waiting/>}>
            {visibleComponent}
        </Suspense>
    )
}

export default TransitioningContainer;
