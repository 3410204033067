/**
 * @generated SignedSource<<c3a4fa2225122aded687c73517a01885>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactHeader_contact$data = {
  readonly familyName?: string;
  readonly givenName?: string;
  readonly id: string;
  readonly teamName?: string;
  readonly " $fragmentType": "ContactHeader_contact";
};
export type ContactHeader_contact$key = {
  readonly " $data"?: ContactHeader_contact$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactHeader_contact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactHeader_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "givenName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "familyName",
          "storageKey": null
        }
      ],
      "type": "PersonContact",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamName",
          "storageKey": null
        }
      ],
      "type": "TeamContact",
      "abstractKey": null
    }
  ],
  "type": "Contact",
  "abstractKey": "__isContact"
};

(node as any).hash = "fe7989d2b5d6b96611d6e620bb66e3f5";

export default node;
