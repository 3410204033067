import * as React from "react";
import Link from "./navigation/Link";
import {useFragment} from "react-relay/hooks";
import {graphql} from "react-relay";

type SubscriptionListItemProps = {
    subscription: any
}

const SubscriptionListItem: React.FC<SubscriptionListItemProps> = (props) => {
    const subscription = useFragment(
        graphql`
            fragment SubscriptionListItem_subscription on Subscription {
                id
                state
                customerAccount {
                    customerName
                    customerNumber
                }
                subscriptionService {
                    name
                }
                subscriptionServiceReference
                subscriptionServiceReferenceLabel
            }
        `,
        props.subscription,
    );

    if (subscription === null) {
        return <></>;
    }

    return (
        <li>
            <div className="relative px-6 py-5 flex items-center space-x-3 hover:bg-gray-50">
                <div className="flex-shrink-0">
                    <span className={"px-2 inline-flex text-xs leading-5 font-semibold rounded-full " + (subscription.state === 'ACTIVE' ? "bg-green-100 text-green-800" : "bg-gray-100 text-gray-800")}>{subscription.state}</span>
                </div>

                <div className="flex-1 min-w-0">
                    <Link to={"/subscriptions/" + subscription.id} className="focus:outline-none">
                        {/* Extend touch target to entire panel */}
                        <span className="absolute inset-0" aria-hidden="true"/>
                        <p className="text-sm font-medium text-gray-900">
                            {subscription.subscriptionService.name}: {subscription.subscriptionServiceReferenceLabel}
                        </p>
                        <p className="text-sm text-gray-500 truncate">
                            {subscription.id}
                        </p>
                    </Link>
                </div>
            </div>
        </li>
    );
}

export default SubscriptionListItem;
