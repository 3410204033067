import * as React from "react";
import {graphql} from "react-relay";
import {useFragment} from "react-relay/hooks";

type SubscriptionHeaderProps = {
    subscription: any
}

const SubscriptionHeader: React.FC<SubscriptionHeaderProps> = (props) => {
    const subscription = useFragment(
        graphql`
            fragment SubscriptionHeader_subscription on Subscription {
                id
                subscriptionService {
                    name
                }
                customerAccount {
                    customerName
                }
            }
        `,
        props.subscription
    )

    return (
        <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <div className="flex items-center space-x-5">
                <div>
                    <h2 className="text-xl font-medium text-gray-700">{subscription.subscriptionService.name}</h2>
                    <h1 className="text-2xl font-bold text-gray-900">{subscription.customerAccount.customerName}</h1>
                    <p className="text-sm font-medium text-gray-500">{subscription.id}</p>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionHeader;
