import * as React from "react";
import {MouseEventHandler} from "react";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type ButtonProps = {
    label?: string
    onClick: MouseEventHandler
    icon?: IconDefinition
}

const Button: React.FC<ButtonProps> = ({label, onClick, icon}) => {
    return (
        <span className="ml-3 shadow-sm rounded-md">
          <button type="button" onClick={onClick} className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
              {icon && <FontAwesomeIcon icon={icon} className="mr-2"/>}{label}
          </button>
        </span>
    )
}

export default Button;
