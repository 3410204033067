import {faCircleCheck, faCircleExclamation, faCircleQuestion, faCircleXmark, faClock, IconDefinition} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {FormattedDate} from "react-intl";

type ActivityListItemProps = {
    icon: IconDefinition
    date?: Date
    isLastItem?: boolean
    details?: any
    datePreText?: string
    state?: string
    states?: {
        gray?: string[],
        green?: string[],
        yellow?: string[],
        red?: string[]
    }
}

const ActivityListItem: React.FC<React.PropsWithChildren<ActivityListItemProps>> = (props) => {
    const {icon, date, children, isLastItem, datePreText, details, states, state} = props;

    let statusClassName = "text-gray-500";
    let statusIcon = faCircleQuestion
    if (states?.gray !== undefined && state !== undefined && states.gray.indexOf(state) !== -1) {
        statusClassName = "text-gray-500";
        statusIcon = faClock
    }
    if (states?.green !== undefined && state !== undefined && states.green.indexOf(state) !== -1) {
        statusClassName = "text-green-500";
        statusIcon = faCircleCheck
    }
    if (states?.yellow !== undefined && state !== undefined && states.yellow.indexOf(state) !== -1) {
        statusClassName = "text-yellow-500";
        statusIcon = faCircleExclamation
    }
    if (states?.red !== undefined && state !== undefined && states.red.indexOf(state) !== -1) {
        statusClassName = "text-red-500"
        statusIcon = faCircleXmark;
    }

    return (
        <li>
            <div className="relative pb-8">
                {isLastItem === undefined || isLastItem === false ? (
                    <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"/>
                ) : null}
                <div className="relative flex items-start space-x-3">
                    {state ? (
                        <>
                            <div className="relative">
                                    <span className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white">
                                        <FontAwesomeIcon className="h-6 w-6 text-white" icon={icon}/>

                                    </span>
                                <span className="absolute -bottom-0.5 -right-1.5 rounded-full bg-white px-0.5 py-px">
                                        <FontAwesomeIcon icon={statusIcon} className={"h-4 w-4 " + statusClassName}/>
                                    </span>
                            </div>
                            <div className="min-w-0 flex-1">
                                <div>
                                    <div className="text-sm">
                                            <span className="font-medium text-gray-900">
                                                {children}
                                            </span>
                                    </div>
                                    {date && (<p className="mt-0.5 text-sm text-gray-500">{datePreText} <FormattedDate value={date}/></p>)}
                                </div>
                                {details && (
                                    <div className="mt-2 text-sm text-gray-700">
                                        {details}
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            <div>
                                <div className="relative px-1">
                                    <div className="flex h-8 w-8 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white">
                                        <FontAwesomeIcon icon={icon} className="h-5 w-5 text-white"/>
                                    </div>
                                </div>
                            </div>
                            <div className="min-w-0 flex-1 py-1.5">
                                <div className="text-sm text-gray-500">
                                    <span className="text-gray-900">{children + ' '}</span>
                                    {date && (<span className="whitespace-nowrap">{datePreText} <FormattedDate value={date}/></span>)}
                                </div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </li>
    )
}

export default ActivityListItem;
