import React from 'react';
import {NavLink} from "react-router-dom";

type LinkProps = {
    to: string
    className?: string
    activeClassName?: string
    onClick?: () => void
}

const Link: React.FC<React.PropsWithChildren<LinkProps>> = (props) => {
    const {onClick, to, children, className, activeClassName} = props;


    const classNames = ({isActive}) => className + (isActive ? ' ' + activeClassName : '');

    return (
        <NavLink onClick={onClick} to={to} className={classNames}>
            {children}
        </NavLink>
    );
}

export default Link;
