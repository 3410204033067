import * as React from "react";
import {graphql} from "react-relay";
import {useFragment} from "react-relay/hooks";
import StatusBadge from "./presentational/StatusBadge";

type SettlementHeaderProps = {
    settlement: any
    language: string | undefined
}

const getStatusLabel = (status: string): string => {
    switch (status) {
        case 'OPEN':
            return 'Open';
        case 'INVOICED':
            return 'Invoiced';
        case 'FINALIZED':
            return 'Finalized';
        case 'CLOSED':
            return 'Closed';
    }
    return 'Unknown'
}

const SettlementHeader: React.FC<SettlementHeaderProps> = (props) => {
    const settlement = useFragment(
        graphql`
            fragment SettlementHeader_settlement on Settlement {
                id
                title {
                    en
                    de
                }
                customerAccount {
                    customerName
                }
                status
            }
        `,
        props.settlement
    )

    const translate = (translatedString: { en: string, de: string } | null) => {
        if (!translatedString) {
            return null
        }
        return props.language === 'de' ? translatedString.de : translatedString.en;
    }

    return (
        <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <div className="flex items-center space-x-5">
                <div>
                    <h2 className="text-xl font-medium text-gray-700">{settlement.customerAccount.customerName}</h2>
                    <h1 className="text-2xl font-bold text-gray-900">
                        {translate(settlement.title)}
                        <StatusBadge
                            value={settlement.status}
                            states={{gray: ['OPEN'], green: ['INVOICED', 'FIXED_FEE']}}
                            label={getStatusLabel(settlement.status)}
                            size="base"
                            className="mx-2"
                        />
                    </h1>
                    <p className="text-sm font-medium text-gray-500">{settlement.id}</p>
                </div>
            </div>
        </div>
    )
}

export default SettlementHeader;
