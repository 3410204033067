import * as React from "react";
import {useState} from "react";
import Panel from "./presentational/Panel";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import {graphql} from "react-relay";
import Headline from "./presentational/Headline";
import {faPlusCircle} from "@fortawesome/pro-regular-svg-icons";
import SubscriptionList from "./SubscriptionList";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import type {SubscriptionsViewSubscriptionsQuery as SubscriptionsViewSubscriptionsQueryType} from "../__relay_artifacts__/SubscriptionsViewSubscriptionsQuery.graphql";
import SubscriptionsViewSubscriptionsQuery from "../__relay_artifacts__/SubscriptionsViewSubscriptionsQuery.graphql";
import CreateSubscriptionDialog from "./dialog/CreateSubscriptionDialog";
import ActionButton from "./presentational/button/ActionButton";
import ListSearch from "./presentational/ListSearch";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type Props = {
    prepared: any
};

type SubscriptionsViewProps = ConnectedProps<typeof connector> & Props;

const SubscriptionsView: React.FC<SubscriptionsViewProps> = (props) => {
    const {dispatchShowModal, dispatchHideModal} = props
    const [searchTerm, setSearchTerm] = useState('');

    const subscriptions = usePreloadedQuery<SubscriptionsViewSubscriptionsQueryType>(
        graphql`
            query SubscriptionsViewSubscriptionsQuery {
                ...SubscriptionList_query
            }
        `,
        props.prepared.subscriptionsQuery,
    );

    const searchAPIDebounced = AwesomeDebouncePromise(
        searchTerm => {
            setSearchTerm(searchTerm)
        },
        500
    );

    const handleSearchTermChange = async (searchTerm) => {
        await searchAPIDebounced(searchTerm);
    };

    const showCreateSubscriptionDialog = () => {
        dispatchShowModal({
            body: (<CreateSubscriptionDialog handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <div className="lg:mt-6 mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <Panel>
                <div className="px-6 pt-6 pb-4">
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <Headline>Subscriptions</Headline>
                        </div>
                        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                            <ActionButton icon={faPlusCircle} title="Create subscription" onClick={() => {
                                showCreateSubscriptionDialog()
                            }}/>
                        </div>
                    </div>
                    <ListSearch handleSearchTermChange={handleSearchTermChange} placeholder="Search Subscriptions"/>
                </div>
                <SubscriptionList queryRef={subscriptions} searchTerm={searchTerm}/>
            </Panel>
        </div>
    )
}

export default connector(SubscriptionsView);

function prepare_SubscriptionsView(params, relayEnvironment) {
    return {
        subscriptionsQuery: loadQuery(
            relayEnvironment,
            SubscriptionsViewSubscriptionsQuery,
            {},
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_SubscriptionsView};
