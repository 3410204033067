/**
 * @generated SignedSource<<37764095c564a6960ea2f6ca2363e7f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdatePersonContactDialog_contact$data = {
  readonly emailAddress: string | null;
  readonly familyName: string;
  readonly givenName: string;
  readonly id: string;
  readonly phoneNumber: string | null;
  readonly salutation: string;
  readonly " $fragmentType": "UpdatePersonContactDialog_contact";
};
export type UpdatePersonContactDialog_contact$key = {
  readonly " $data"?: UpdatePersonContactDialog_contact$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpdatePersonContactDialog_contact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpdatePersonContactDialog_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "givenName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "familyName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    }
  ],
  "type": "PersonContact",
  "abstractKey": null
};

(node as any).hash = "96740013ea2b52628e6a7669f20339aa";

export default node;
