import * as React from "react";
import {ErrorMessage, Field as FormikField} from "formik";

const Field = (props) => {
    const {name, type, ...attributes} = props;
    return (
        <>
            <FormikField type={type} name={name} {...attributes} className="block w-full shadow-sm sm:text-sm focus:ring-red-500 focus:border-red-500 border-gray-300 rounded-md">
                {props.children}
            </FormikField>
            <ErrorMessage name={name} component="p" className="mt-2 text-sm text-red-600"/>
        </>
    );
}

export default Field;
