import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";
import Hint from "../presentational/Hint";

type FinalizeInvoiceDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const FinalizeInvoiceDialog: React.FC<FinalizeInvoiceDialogProps> = (props) => {

    const navigate = useNavigate();
    const invoice = useFragment(
        graphql`
            fragment FinalizeInvoiceDialog_invoice on Invoice {
                id
                customerAccount {
                    customerName
                }
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation FinalizeInvoiceDialogFinalizeInvoiceMutation($input: FinalizeInvoiceInput!) {
                        finalizeInvoice(input: $input) {
                            ...FinalInvoiceView_invoice
                        }
                    }
                `,
                variables: {
                    input: {
                        id: input.id
                    }
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                    navigate('/invoices/' + invoice.id)
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        id: invoice.id
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Finalize Invoice" submitLabel="Finalize">
            <Section>
                <Row>
                    <div className="text-gray-900 font-medium">
                        Proceed with finalizing this invoice for {invoice.customerAccount.customerName}?
                    </div>
                    <Hint className="pt-4">An invoice number will be assigned and the invoice cannot be deleted anymore. Once finalized, the invoice is automatically sent to the configured contacts.</Hint>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default FinalizeInvoiceDialog;
