import React from "react";
import {Auth0Provider} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";
import {CabanaConfiguration} from "./Root";

type Auth0ProviderWithNavigationProps = {
    configuration: CabanaConfiguration
}

const Auth0ProviderWithNavigation: React.FC<React.PropsWithChildren<Auth0ProviderWithNavigationProps>> = ({configuration, children}) => {
    const domain = configuration.auth0.domain;
    const clientId = configuration.auth0.clientId;
    const audience = configuration.auth0.audience;

    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: audience
            }}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithNavigation;
