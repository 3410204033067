import * as React from "react";


export enum TextAlignment {
    Left = 'left',
    Right = 'right',
    Center = 'center'
}

type TableHeaderAdditionalProps = {
    className?: string
    textAlignment?: TextAlignment
}

type TableHeaderProps = TableHeaderAdditionalProps & React.DetailedHTMLProps<React.ThHTMLAttributes<HTMLTableHeaderCellElement>, HTMLTableHeaderCellElement>

const TableHeader: React.FC<React.PropsWithChildren<TableHeaderProps>> = (props) => {
    const {children, textAlignment, className, ...attributes} = props

    return (
        <th scope="col" className={"whitespace-nowrap py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-" + (textAlignment ?? TextAlignment.Left) + ' ' + (className !== undefined ? className : '')} {...attributes}>
            {children}
        </th>
    );
};

export default TableHeader;
