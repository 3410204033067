import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";

type TerminateSubscriptionDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const TerminateSubscriptionDialog: React.FC<TerminateSubscriptionDialogProps> = (props) => {

    const subscription = useFragment(
        graphql`
            fragment TerminateSubscriptionDialog_subscription on Subscription {
                id
                subscriptionService {
                    name
                }
                subscriptionServiceReferenceLabel
                customerAccount {
                    customerName
                }
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation TerminateSubscriptionDialogMutation(
                        $input: TerminateSubscriptionInput!
                    ) {
                        terminateSubscription(input: $input) {
                            id
                            state
                            expiresAt
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={{id: subscription.id}} headline="Terminate Subscription" submitLabel="Terminate immediately" dangerous={true}>
            <Section>
                <Row>
                    <div className="text-gray-900 font-medium">
                        Really terminate {subscription.subscriptionService.name} subscription for {subscription.subscriptionServiceReferenceLabel}?
                    </div>
                </Row>
                <Row>{subscription.customerAccount.customerName}'s subscription will expire immediately.</Row>
            </Section>
        </FormDialog>
    )
}

export default TerminateSubscriptionDialog;
