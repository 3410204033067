/**
 * @generated SignedSource<<478bd554e89e522564ad812a267563e2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvoiceState = "DRAFT" | "INVOICE_NUMBER_PENDING" | "OPEN" | "PAID" | "REFUNDED" | "REVERSED" | "UNCOLLECTIBLE" | "VOID" | "%future added value";
export type InvoiceType = "REGULAR" | "REVERSE" | "%future added value";
export type TaxStatus = "EXEMPT" | "REGULAR" | "REVERSE_CHARGE" | "%future added value";
export type TaxType = "GROSS" | "INTRA_COMMUNITY_SERVICE" | "NET" | "THIRD_PARTY_COUNTRY_SERVICE" | "VAT_FREE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DraftInvoiceView_invoice$data = {
  readonly address: {
    readonly addressLine1: string | null;
    readonly addressLine2: string | null;
    readonly businessName: string;
    readonly city: string;
    readonly country: string;
    readonly postalCode: string;
  };
  readonly attachments: ReadonlyArray<{
    readonly filename: string;
    readonly sha1: string;
    readonly size: number;
  }>;
  readonly customerAccount: {
    readonly customerNumber: string;
    readonly taxStatus: TaxStatus | null;
    readonly vatId: string | null;
  };
  readonly customerOrderNotes: string | null;
  readonly id: string;
  readonly invoiceDate: any;
  readonly invoiceNumber: string | null;
  readonly language: string;
  readonly paymentMethod: {
    readonly cardBrand: string | null;
    readonly expirationDate: string | null;
    readonly id: string;
    readonly lastFourDigits: string | null;
    readonly paymentMethodType: string;
    readonly paymentServiceType: string;
    readonly paymentTerms: string | null;
  };
  readonly paymentNote: string;
  readonly purchaseOrderReference: string | null;
  readonly relatedInvoice: {
    readonly id: string;
    readonly invoiceNumber: string | null;
  } | null;
  readonly state: InvoiceState;
  readonly taxType: TaxType;
  readonly taxTypeNote: string;
  readonly timeOfPerformanceEnd: any;
  readonly timeOfPerformanceStart: any;
  readonly type: InvoiceType;
  readonly " $fragmentSpreads": FragmentRefs<"FinalizeInvoiceDialog_invoice" | "InvoiceHeader_invoice" | "InvoiceLineItemsTable_invoice">;
  readonly " $fragmentType": "DraftInvoiceView_invoice";
};
export type DraftInvoiceView_invoice$key = {
  readonly " $data"?: DraftInvoiceView_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"DraftInvoiceView_invoice">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "invoiceNumber",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DraftInvoiceView_invoice",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceHeader_invoice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InvoiceLineItemsTable_invoice"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FinalizeInvoiceDialog_invoice"
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "state",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOfPerformanceStart",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "timeOfPerformanceEnd",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxTypeNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerNumber",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "vatId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BusinessAddress",
      "kind": "LinkedField",
      "name": "address",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "businessName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "addressLine2",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "postalCode",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "city",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "country",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentNote",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentServiceType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentTerms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardBrand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expirationDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastFourDigits",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "purchaseOrderReference",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerOrderNotes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceAttachment",
      "kind": "LinkedField",
      "name": "attachments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sha1",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "filename",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "size",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Invoice",
      "kind": "LinkedField",
      "name": "relatedInvoice",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};
})();

(node as any).hash = "e43f9942e2f2cc6e0866f523e16db790";

export default node;
