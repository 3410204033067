/**
 * @generated SignedSource<<608b400d68424875de587c337e0dd01b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaxStatus = "EXEMPT" | "REGULAR" | "REVERSE_CHARGE" | "%future added value";
export type UpdateCustomerTaxStatusInput = {
  id: string;
  taxStatus: TaxStatus;
};
export type CustomerAccountViewUpdateCustomerTaxStatusMutation$variables = {
  input: UpdateCustomerTaxStatusInput;
};
export type CustomerAccountViewUpdateCustomerTaxStatusMutation$data = {
  readonly updateCustomerTaxStatus: {
    readonly id: string;
    readonly taxStatus: TaxStatus | null;
  };
};
export type CustomerAccountViewUpdateCustomerTaxStatusMutation = {
  response: CustomerAccountViewUpdateCustomerTaxStatusMutation$data;
  variables: CustomerAccountViewUpdateCustomerTaxStatusMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CustomerAccount",
    "kind": "LinkedField",
    "name": "updateCustomerTaxStatus",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "taxStatus",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerAccountViewUpdateCustomerTaxStatusMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerAccountViewUpdateCustomerTaxStatusMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1d2ecdf6f4dc2582a07810dbf1434b35",
    "id": null,
    "metadata": {},
    "name": "CustomerAccountViewUpdateCustomerTaxStatusMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerAccountViewUpdateCustomerTaxStatusMutation(\n  $input: UpdateCustomerTaxStatusInput!\n) {\n  updateCustomerTaxStatus(input: $input) {\n    id\n    taxStatus\n  }\n}\n"
  }
};
})();

(node as any).hash = "1ba821e9fb66bff3424264158c2fe014";

export default node;
