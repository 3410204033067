import React from "react";
import {Environment, Network, RecordSource, Store} from "relay-runtime";
import {useAuth0} from "@auth0/auth0-react";
import {RelayEnvironmentProvider} from "react-relay/hooks";
import {CabanaConfiguration} from "./Root";

type RelayEnvironmentProviderWithAuth0Props = {
    configuration: CabanaConfiguration
}

let relayEnvironment;

function createRelayEnvironment(getAccessTokenSilently, configuration) {
    if (relayEnvironment) {
        return relayEnvironment;
    }

    const fetchRelay = async (params, variables) => {
        const token = await getAccessTokenSilently();
        const response = await fetch(configuration.graphql.uri, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                query: params.text,
                variables,
            }),
        });
        return response.json();
    };

    const source = new RecordSource();
    const store = new Store(source);
    const network = Network.create(fetchRelay);

    relayEnvironment = new Environment({
        network,
        store,
    });

    return relayEnvironment;
}

const RelayEnvironmentProviderWithAuth0: React.FC<React.PropsWithChildren<RelayEnvironmentProviderWithAuth0Props>> = ({configuration, children}) => {
    const {getAccessTokenSilently} = useAuth0();
    const relayEnvironment = createRelayEnvironment(getAccessTokenSilently, configuration);

    return (
        <RelayEnvironmentProvider environment={relayEnvironment}>
            {children}
        </RelayEnvironmentProvider>
    );
};

export default RelayEnvironmentProviderWithAuth0;
