import * as React from "react"
import {useEffect} from "react"
import {graphql} from "react-relay";
import {usePaginationFragment} from "react-relay/hooks";
import LoadMoreButton from "./presentational/LoadMoreButton";
import {FormattedDate} from "react-intl";
import {useNavigate} from "react-router";
import StatusBadge from "./presentational/StatusBadge";
import TableHeader from "./presentational/table/TableHeader";
import TableData, {TextAlignment} from "./presentational/table/TableData";
import Dinero from "dinero.js";
import Table from "./presentational/table/Table";

type Props = {
    queryRef: any
    searchTerm?: string
}

type SettlementListProps = Props;

const SettlementList: React.FC<SettlementListProps> = (props) => {
    const navigate = useNavigate()
    const {data, hasNext, loadNext, isLoadingNext, refetch} = usePaginationFragment(
        graphql`
            fragment SettlementList_query on Query
            @argumentDefinitions(
                first: { type: "Int", defaultValue: 100 }
                after: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "SettlementsPaginationQuery") {
                settlements(first: $first, after: $after, searchTerm: $searchTerm, sort: STATUS_DESC)
                @connection(key: "SettlementList_settlements") {
                    totalCount
                    edges {
                        node {
                            id
                            status
                            createdAt
                            cutOffAt
                            title {
                                en
                            }
                            customerAccount {
                                customerName
                            }
                            serviceIdentifier
                            service {
                                __typename
                                ...on Subscription {
                                    subscriptionService {
                                        name
                                    }
                                }
                                ...on Project {
                                    projectName
                                }
                            }
                            totalNetAmount
                        }
                    }
                }
            }
        `,
        props.queryRef
    );

    useEffect(() => {
        refetch({searchTerm: props.searchTerm}, {fetchPolicy: 'store-and-network'});
    }, [props.searchTerm, refetch])

    if (!data.settlements || data.settlements.totalCount === 0) {
        return (<></>);
    }

    return (
        <nav className="flex-1 min-h-0 relative overflow-y-auto" aria-label="Settlements">
            <Table>
                <thead>
                    <tr>
                        <TableHeader className="hidden sm:table-cell">State</TableHeader>
                        <TableHeader className="hidden sm:table-cell">Cut Off Date</TableHeader>
                        <TableHeader>Customer</TableHeader>
                        <TableHeader>Service</TableHeader>
                        <TableHeader>Description</TableHeader>
                        <TableHeader textAlignment={TextAlignment.Right}>Net Total</TableHeader>
                    </tr>
                </thead>
                <tbody>
                    {data.settlements.edges.map(
                        (edge, index) => {
                            const settlement = edge.node;
                            const projectOrSubscriptionName = settlement.service.__typename === 'Subscription' ? settlement.service.subscriptionService.name : settlement.service.projectName
                            const totalNetAmount = Dinero({amount: settlement.totalNetAmount})

                            return (
                                <tr key={index} className={(index % 2 === 0 ? 'bg-white' : 'bg-gray-50') + ' hover:bg-gray-50 cursor-pointer group'} onClick={() => {
                                    navigate("/settlements/" + settlement.id + "/en")
                                }}>
                                    <TableData className="hidden sm:table-cell">
                                        <StatusBadge value={settlement.status} states={{green: ['INVOICED'], yellow: ['FINALIZED', 'OPEN']}}/>
                                    </TableData>
                                    <TableData className="hidden sm:table-cell"><FormattedDate value={edge.node.cutOffAt} day="2-digit" month="2-digit" year="numeric"/></TableData>
                                    <TableData>{settlement.customerAccount.customerName}</TableData>
                                    <TableData>{projectOrSubscriptionName}</TableData>
                                    <TableData>{settlement.title.en}</TableData>
                                    <TableData textAlignment={TextAlignment.Right}>
                                        {totalNetAmount.toFormat()}
                                    </TableData>
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </Table>
            {hasNext && <LoadMoreButton onClick={() => loadNext(100)} isLoadingNext={isLoadingNext}/>}
        </nav>
    )
}

export default SettlementList;
