import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";

type ContactHeaderProps = {
    contact: {
        id: string
        givenName?: string
        familyName?: string
        teamName?: string
    }
}

const ContactHeader: React.FC<ContactHeaderProps> = ({contact}) => {
    const name = contact.teamName ? contact.teamName : contact.givenName + " " + contact.familyName;

    return (
        <div className="mt-6 max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <div className="flex items-center space-x-5">
                <div className="shrink-0">
                        <span className="inline-flex items-center justify-center h-16 w-16 sm:h-24 sm:w-24 rounded-full bg-red-600">
                           <span className="text-4xl font-bold leading-none text-white">{name.substr(0, 1)}</span>
                        </span>
                </div>
                <div>
                    <h1 className="text-2xl font-bold text-gray-900">{name}</h1>
                    <p className="text-sm font-medium text-gray-500">{contact.id}</p>
                </div>
            </div>
        </div>
    )
}

export default createFragmentContainer(ContactHeader, {
        contact: graphql`
            fragment ContactHeader_contact on Contact {
                id
                ... on PersonContact {
                    givenName
                    familyName
                }
                ... on TeamContact {
                    teamName
                }
            }
        `
    }
);
