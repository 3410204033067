import * as React from "react";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type DataTypeIconProps = {
    icon: IconDefinition
    title?: string
}

const DataTypeIcon: React.FC<DataTypeIconProps> = ({title, icon}) => {
    return (
        <FontAwesomeIcon icon={icon} className="mr-1.5 h-5 w-5 text-gray-400" title={title}/>
    )
}

export default DataTypeIcon;
