import * as React from "react";
import {commitMutation, fetchQuery, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import asFormikField from "../abstract/asFormikField";
import AbstractCombobox from "../abstract/AbstractCombobox";

type AddContactDialogProps = {
    customerAccount: any,
    handleCloseDialog: () => {}
}

const contactsDataProvider = (relayEnvironment) => {
    return AwesomeDebouncePromise((searchTerm: String = '') => {
        return fetchQuery(relayEnvironment, graphql`
            query AddContactDialogContactsQuery($searchTerm: String!) {
                contacts(searchTerm: $searchTerm, first: 2) {
                    totalCount
                    edges {
                        node {
                            __typename
                            id
                            ... on TeamContact {
                                teamName
                            }
                            ... on PersonContact {
                                givenName
                                familyName
                            }
                            emailAddress
                        }
                    }
                }
            }
        `, {searchTerm}).toPromise().then((data) => {
            if (!data) {
                return [];
            }
            //@ts-ignore
            return data.contacts?.edges?.map((edge) => edge.node) || [];
        });
    }, 500);
}

const AddContactDialog: React.FC<AddContactDialogProps> = (props) => {
    const customerAccount = useFragment(
        graphql`
            fragment AddContactDialog_customerAccount on CustomerAccount {
                id
            }
        `,
        props.customerAccount
    );

    const relayEnvironment = useRelayEnvironment();
    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation AddContactDialogMutation($input: AddContactInput!) {
                        addContact(input: $input) {
                            contacts {
                                totalCount
                                edges {
                                    cursor
                                    roles {
                                        roleIdentifier
                                        label {
                                            en
                                        }
                                    }
                                    node {
                                        id
                                        __typename
                                        ... on PersonContact {
                                            givenName
                                            familyName
                                        }
                                        ... on TeamContact {
                                            teamName
                                        }
                                        emailAddress
                                        phoneNumber
                                    }
                                }
                            }
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        customerAccountIdentifier: customerAccount.id
    }

    const validationSchema = yup.object().shape({
        contactIdentifier: yup.string()
            .required('This field is required'),
    });

    const FormikCombobox = asFormikField(AbstractCombobox, 'contactIdentifier');
    const getEntryLabel = (contact) => {
        switch (contact.__typename) {
            case 'PersonContact':
                return contact.givenName + ' ' + contact.familyName
            case 'TeamContact':
                return contact.teamName
        }
        return contact?.id
    }
    const getEntrySecondaryLabel = (contact) => {
        return contact?.emailAddress
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} validationSchema={validationSchema} headline="Add Contact" submitLabel="Add">
            <Section>
                <Row>
                    <Label>Team or person contact</Label>
                    <FormikCombobox getEntryId={(contact) => contact.id} getEntryLabel={getEntryLabel} getEntrySecondaryLabel={getEntrySecondaryLabel} dataProvider={contactsDataProvider(relayEnvironment)} autoFocus={true} placeholder="Search for a contact"/>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default AddContactDialog;
