import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Field from "../form/Field";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import {useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";
import {CreateTeamContactDialogMutation} from "../../__relay_artifacts__/CreateTeamContactDialogMutation.graphql";

type CreateTeamContactDialogProps = {
    handleCloseDialog: () => {}
}

const CreateTeamContactDialog: React.FC<CreateTeamContactDialogProps> = ({handleCloseDialog}) => {
    const relayEnvironment = useRelayEnvironment();
    const navigate = useNavigate();

    const handleSubmission = (input) => {
        commitMutation<CreateTeamContactDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreateTeamContactDialogMutation(
                        $input: CreateTeamContactInput!
                    ) {
                        createTeamContact(input: $input) {
                            id
                            teamName
                            emailAddress
                            phoneNumber
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                    navigate('/contacts/' + response.createTeamContact?.id);
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {}
    const validationSchema = yup.object().shape({
        teamName: yup.string()
            .max(255, 'This name is too long')
            .matches(/^[\w,\-+&!?/ ()*.\\'äàáâæãåāéèêëėîïíīìöôòóõœøōüûùúūßśšñńçćčÿÄÀÁÂÆÃÅĀÉÈÊËĖÎÏÍĪÌÖÔÒÓÕŒØŌÜÛÙÚŪSSŚŠÑŃÇĆČŸ]{2,255}$/u, 'This team name contains characters which are not allowed')
            .required('The team name is required'),
        emailAddress: yup.string()
            .max(255, 'This email address is too long')
            .email('This is does not look like an email address')
            .required('This field is required'),
        phoneNumber: yup.string() // The ITU E.164 states that phone numbers around the globe are recommended to not be longer than 15 digits, but we allow more digits to be safe
            .max(20, 'This phone number is too long')
            .matches(/^(\+(?:[0-9] ?){6,19}[0-9])?$/, 'This is does not look like a valid phone number')
    });

    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} validationSchema={validationSchema} headline="Create Contact" submitLabel="Create">
            <Section>
                <Row>
                    <Label>Team name</Label>
                    <Field name="teamName" type="text" placeholder="Backoffice Team" autoFocus={true}/>
                </Row>
                <Row>
                    <Label>Email address</Label>
                    <Field name="emailAddress" type="text" placeholder="backoffice@example.com"/>
                </Row>
                <Row>
                    <Label>Phone number</Label>
                    <Field name="phoneNumber" type="text" placeholder="+49 451 3050350"/>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default CreateTeamContactDialog;
