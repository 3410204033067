/**
 * @generated SignedSource<<7e602131216b6f2333120aa0e0d91f4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RemoveInvoiceAttachmentInput = {
  id: string;
  sha1: string;
};
export type DraftInvoiceViewRemoveInvoiceAttachmentMutation$variables = {
  input: RemoveInvoiceAttachmentInput;
};
export type DraftInvoiceViewRemoveInvoiceAttachmentMutation$data = {
  readonly removeInvoiceAttachment: {
    readonly attachments: ReadonlyArray<{
      readonly filename: string;
      readonly lastModifiedAt: any;
      readonly sha1: string;
      readonly size: number;
    }>;
    readonly id: string;
  };
};
export type DraftInvoiceViewRemoveInvoiceAttachmentMutation = {
  response: DraftInvoiceViewRemoveInvoiceAttachmentMutation$data;
  variables: DraftInvoiceViewRemoveInvoiceAttachmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "removeInvoiceAttachment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceAttachment",
        "kind": "LinkedField",
        "name": "attachments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sha1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftInvoiceViewRemoveInvoiceAttachmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftInvoiceViewRemoveInvoiceAttachmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "097093a1a8cad04d5dcae3bcefd3a932",
    "id": null,
    "metadata": {},
    "name": "DraftInvoiceViewRemoveInvoiceAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation DraftInvoiceViewRemoveInvoiceAttachmentMutation(\n  $input: RemoveInvoiceAttachmentInput!\n) {\n  removeInvoiceAttachment(input: $input) {\n    id\n    attachments {\n      sha1\n      filename\n      lastModifiedAt\n      size\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e96b3ca13ef553afe4c2bf3153dcde42";

export default node;
