/**
 * @generated SignedSource<<dd5a86bcdd40cdb81cedcd893033ed57>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateInvoiceDialogCustomerAccountsQuery$variables = {
  searchTerm: string;
};
export type CreateInvoiceDialogCustomerAccountsQuery$data = {
  readonly customerAccounts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly customerName: string;
        readonly customerNumber: string;
        readonly id: string;
      } | null;
    }>;
    readonly totalCount: number;
  } | null;
};
export type CreateInvoiceDialogCustomerAccountsQuery = {
  response: CreateInvoiceDialogCustomerAccountsQuery$data;
  variables: CreateInvoiceDialogCustomerAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      },
      {
        "kind": "Literal",
        "name": "sort",
        "value": "CUSTOMER_SINCE_DESC"
      }
    ],
    "concreteType": "CustomerAccountConnection",
    "kind": "LinkedField",
    "name": "customerAccounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerAccountEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccount",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateInvoiceDialogCustomerAccountsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateInvoiceDialogCustomerAccountsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "778ad537bb8a3b17ab62f689f53d9a48",
    "id": null,
    "metadata": {},
    "name": "CreateInvoiceDialogCustomerAccountsQuery",
    "operationKind": "query",
    "text": "query CreateInvoiceDialogCustomerAccountsQuery(\n  $searchTerm: String!\n) {\n  customerAccounts(searchTerm: $searchTerm, sort: CUSTOMER_SINCE_DESC) {\n    totalCount\n    edges {\n      node {\n        id\n        customerName\n        customerNumber\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5f7dd3140b17c40fa7de4b94fd0501bc";

export default node;
