/**
 * @generated SignedSource<<61be0442f794ff5ea170be971350dfee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FinalizeInvoiceDialog_invoice$data = {
  readonly customerAccount: {
    readonly customerName: string;
  };
  readonly id: string;
  readonly " $fragmentType": "FinalizeInvoiceDialog_invoice";
};
export type FinalizeInvoiceDialog_invoice$key = {
  readonly " $data"?: FinalizeInvoiceDialog_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"FinalizeInvoiceDialog_invoice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FinalizeInvoiceDialog_invoice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};

(node as any).hash = "0a384a9171117fc1a603b44c442617ac";

export default node;
