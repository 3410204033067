/**
 * @generated SignedSource<<f18ef878d72b396b4532bd66a1a44a5e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConfigurationContractsViewQuery$variables = {};
export type ConfigurationContractsViewQuery$data = {
  readonly termsAndConditionsDocuments: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly code: string;
        readonly id: string;
        readonly publishDate: any | null;
        readonly published: boolean;
        readonly startDate: any;
        readonly title: string;
        readonly uri: string;
        readonly version: string;
      } | null;
    }>;
    readonly totalCount: number;
  } | null;
};
export type ConfigurationContractsViewQuery = {
  response: ConfigurationContractsViewQuery$data;
  variables: ConfigurationContractsViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "TermsAndConditionsDocumentConnection",
    "kind": "LinkedField",
    "name": "termsAndConditionsDocuments",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "TermsAndConditionsDocumentEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TermsAndConditionsDocument",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "version",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uri",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "startDate",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "published",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "publishDate",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigurationContractsViewQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConfigurationContractsViewQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "12410b648c460d04e6e75f68b2296e9d",
    "id": null,
    "metadata": {},
    "name": "ConfigurationContractsViewQuery",
    "operationKind": "query",
    "text": "query ConfigurationContractsViewQuery {\n  termsAndConditionsDocuments {\n    totalCount\n    edges {\n      node {\n        id\n        code\n        title\n        version\n        uri\n        startDate\n        published\n        publishDate\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5ef171d75e8f0ce17605a61f8f15bb03";

export default node;
