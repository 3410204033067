import * as React from "react";

type StatusBadgeProps = {
    value: string
    label?: string
    className?: string
    size?: 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl'
    states: {
        gray?: string[],
        green?: string[],
        yellow?: string[],
        red?: string[]
    }
}

const StatusBadge: React.FC<StatusBadgeProps> = (props) => {
    const {value, label, states, className, size} = props;
    const styles = {
        'xs': 'text-xs px-1 font-bold',
        'sm px-1': 'text-sm px-2 py-1 font-bold',
        'base': 'text-base px-2 py-1 border-2 font-medium ',
        'lg': 'text-lg px-2 py-1 border-2 font-medium ',
        'xl': 'text-xl px-2 py-1 border-2 font-medium ',
        '2xl': 'text-2xl px-2 py-1 border-2 font-medium '
    }

    let additionalClassNames = "bg-gray-100 text-gray-800";
    if (states.green !== undefined && states.green.indexOf(value) !== -1) {
        additionalClassNames = "bg-green-100 text-green-800";
    }
    if (states.yellow !== undefined && states.yellow.indexOf(value) !== -1) {
        additionalClassNames = "bg-yellow-100 text-yellow-800";
    }
    if (states.red !== undefined && states.red.indexOf(value) !== -1) {
        additionalClassNames = "bg-red-100 text-red-800";
    }

    return (
        <span className={`inline-flex leading-5 rounded-full ${styles[size ?? 'xs']} ${className ?? ''} ${additionalClassNames}`}>{label ?? value}</span>
    );
};

export default StatusBadge;
