import * as React from "react";
import {graphql} from "react-relay";
import {useFragment} from "react-relay/hooks";

type InvoiceHeaderProps = {
    invoice: any
}

const InvoiceHeader: React.FC<InvoiceHeaderProps> = (props) => {
    const invoice = useFragment(
        graphql`
            fragment InvoiceHeader_invoice on Invoice {
                id
                type
                invoiceNumber
                customerAccount {
                    customerName
                }
                relatedInvoiceIdentifier
            }
        `,
        props.invoice
    )

    return (
        <div className="mt-6 max-w-5xl mx-auto">
            <div className="ml-4 mt-4 flex flex-wrap items-center justify-between">
                <div className="ml-4 flex items-center">
                    <div>
                        <h2 className="text-xl font-medium text-gray-700">{invoice.customerAccount.customerName}</h2>
                        <h1 className="text-2xl font-bold text-gray-900">
                            {invoice.type === 'REVERSE' ? 'Reverse Invoice' : 'Invoice'} {invoice.invoiceNumber}
                        </h1>
                        <p className="text-sm font-medium text-gray-500">{invoice.id}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InvoiceHeader;
