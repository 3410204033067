import * as React from "react";
import {connect, ConnectedProps} from "react-redux";
import {Outlet} from "react-router";
import {hideModal, showModal} from "../store/actions";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import SecondaryNavigation from "./navigation/SecondaryNavigation";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import type {ConfigurationViewQuery as ConfigurationViewQueryType} from "../__relay_artifacts__/ConfigurationViewQuery.graphql";
import ConfigurationViewQuery from "../__relay_artifacts__/ConfigurationViewQuery.graphql";
import ConfigurationContractsViewQuery from "../__relay_artifacts__/ConfigurationContractsViewQuery.graphql";
import {graphql} from "react-relay";

type ConfigurationViewParams = {
    prepared: any
};

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ConfigurationViewProps = ConfigurationViewParams & ConnectedProps<typeof connector>;

const ConfigurationView: React.FC<ConfigurationViewProps> = (props) => {

    usePreloadedQuery<ConfigurationViewQueryType>(
        graphql`
            query ConfigurationViewQuery {
                customerAccounts {
                    totalCount
                }
                termsAndConditionsDocuments {
                    totalCount
                }
            }
        `,
        props.prepared.configurationViewQuery,
    );

    const breadcrumbNavigationItems = [
        {label: 'Configuration', to: '/configuration/contracts'},
        {label: 'Contracts', to: '/configuration/contracts'},
    ]

    const secondaryNavigationItems = [
        {
            label: 'Contracts',
            to: '/configuration/contracts'
        },
    ]

    return (
        <>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <div className="lg:max-w-6xl lg:mx-auto">
                <div className="py-4 md:flex md:items-center md:justify-between">
                    <div className="flex-1 min-w-0">
                        <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                            <div className="flex items-center space-x-5">
                                <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Configuration</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <SecondaryNavigation items={secondaryNavigationItems}/>
            <div className="max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
                <Outlet context={props.prepared}/>
            </div>
        </>
    );
}

export default connector(ConfigurationView);

function prepare_ConfigurationView(params, relayEnvironment) {
    return {
        configurationViewQuery: loadQuery(
            relayEnvironment,
            ConfigurationViewQuery,
            {},
            {fetchPolicy: 'store-and-network'},
        ),
        configurationContractsViewQuery: loadQuery(
            relayEnvironment,
            ConfigurationContractsViewQuery,
            {},
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_ConfigurationView};
