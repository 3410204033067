/**
 * @generated SignedSource<<f302a2d81997f4039b9eeea818d8f08e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerContactsView_customerAccount$data = {
  readonly contacts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly id: string;
      } | null;
      readonly roles: ReadonlyArray<{
        readonly roleIdentifier: string;
      }> | null;
    } | null> | null;
    readonly totalCount: number | null;
  } | null;
  readonly customerName: string;
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"AddContactDialog_customerAccount" | "DetailedContactList_customerAccount">;
  readonly " $fragmentType": "CustomerContactsView_customerAccount";
};
export type CustomerContactsView_customerAccount$key = {
  readonly " $data"?: CustomerContactsView_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerContactsView_customerAccount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerContactsView_customerAccount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccountContactConnection",
      "kind": "LinkedField",
      "name": "contacts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CustomerAccountContactEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "CustomerAccountContactRole",
              "kind": "LinkedField",
              "name": "roles",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "roleIdentifier",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetailedContactList_customerAccount"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AddContactDialog_customerAccount"
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};
})();

(node as any).hash = "c4e664531699674cc09d3d1fb3d3d7f7";

export default node;
