import * as React from "react";
import {Form, Formik} from 'formik';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";

type FormDialogProps = {
    /**
     * A callback handling the closing of the dialog
     */
    handleCloseDialog: () => void,
    /**
     * A function handling submission of the form
     */
    handleSubmission: (input: {}) => void,
    /**
     * The initial values for the form fields
     */
    initialValues: {},
    /**
     * A Yup Schema or a function that returns a Yup schema
     */
    validationSchema?: any,
    /**
     * A headline for the dialog
     */
    headline: string,
    /**
     * A label for the submit button
     */
    submitLabel: string,
    /**
     * If the submission of the form is potentially dangerous
     */
    dangerous?: boolean
}

const FormDialog: React.FC<React.PropsWithChildren<FormDialogProps>> = (props) => {
    const {handleCloseDialog, handleSubmission, initialValues, validationSchema, headline, submitLabel, dangerous} = props;

    const submitButtonAdditionalClassNames = dangerous ? "bg-red-600 hover:bg-red-700 focus:ring-red-500" : "bg-gray-600 hover:bg-gray-700 focus:ring-gray-500";

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                {/* Overlay which darkens the background below the modal */}
                <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div className="absolute inset-0 bg-gray-500 opacity-75"/>
                </div>
                {/* The following span is a trick to center the modal dialog vertically */}
                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div
                    className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="dialog-headline"
                >
                    <div className="bg-gray-100 px-10 py-8 -m-6">
                        <h2 className="text-xl font-medium text-gray-900">
                            {headline}
                        </h2>
                    </div>
                    <Formik initialValues={initialValues} onSubmit={values => {
                        handleSubmission(values);
                    }} validationSchema={validationSchema}>
                        {({isSubmitting}) => (
                            <Form className="h-full flex flex-col overflow-y-scroll pt-8">
                                {props.children}
                                <div className="shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                                    <div className="space-x-3 flex justify-end">
                                        <button type="button" onClick={e => handleCloseDialog()} className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
                                            Cancel
                                        </button>
                                        <button type="submit" disabled={isSubmitting} className={"inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 " + submitButtonAdditionalClassNames}>
                                            {isSubmitting && <FontAwesomeIcon icon={faSpinnerThird} spin className="h-5 w-5 mr-3"/>}
                                            {submitLabel}
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default FormDialog;
