/**
 * @generated SignedSource<<b0403d3ac059120a7cfc15583d3ee2d8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactsViewContactsQuery$variables = {};
export type ContactsViewContactsQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ContactList_query">;
};
export type ContactsViewContactsQuery = {
  response: ContactsViewContactsQuery$data;
  variables: ContactsViewContactsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "searchTerm",
    "value": ""
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ContactsViewContactsQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ContactList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ContactsViewContactsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "CustomerAccountContactConnection",
        "kind": "LinkedField",
        "name": "contacts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccountContactEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "TypeDiscriminator",
                    "abstractKey": "__isContact"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "givenName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "familyName",
                        "storageKey": null
                      }
                    ],
                    "type": "PersonContact",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "teamName",
                        "storageKey": null
                      }
                    ],
                    "type": "TeamContact",
                    "abstractKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "emailAddress",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "contacts(first:100,searchTerm:\"\")"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "searchTerm"
        ],
        "handle": "connection",
        "key": "ContactList_contacts",
        "kind": "LinkedHandle",
        "name": "contacts"
      }
    ]
  },
  "params": {
    "cacheID": "60d486d9e2306f9f57c0156ddb94bc39",
    "id": null,
    "metadata": {},
    "name": "ContactsViewContactsQuery",
    "operationKind": "query",
    "text": "query ContactsViewContactsQuery {\n  ...ContactList_query\n}\n\nfragment ContactListItem_contact on Contact {\n  __isContact: __typename\n  id\n  ... on PersonContact {\n    givenName\n    familyName\n  }\n  ... on TeamContact {\n    teamName\n  }\n  emailAddress\n}\n\nfragment ContactList_query on Query {\n  contacts(first: 100, searchTerm: \"\") {\n    totalCount\n    edges {\n      node {\n        __typename\n        ...ContactListItem_contact\n        id\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7d3b0147a285d83b96ae4cc146578e94";

export default node;
