import * as React from "react"
import {useEffect} from "react"
import {graphql} from "react-relay";
import {usePaginationFragment} from "react-relay/hooks";
import CustomerAccountListItem from "./CustomerAccountListItem";
import LoadMoreButton from "./presentational/LoadMoreButton";

type CustomerAccountListProps = {
    queryRef: any // FIXME: Any type we can define here? It's actually: Readonly<{ ' $data'?: unknown; ' $fragmentRefs': unknown; }> | null
    searchTerm?: string
}

const CustomerAccountList: React.FC<CustomerAccountListProps> = (props) => {
    const {data, hasNext, loadNext, isLoadingNext, refetch} = usePaginationFragment(
        graphql`
            fragment CustomerAccountList_query on Query
            @argumentDefinitions(
                #                cursor: { type: "String" }
                first: { type: "Int", defaultValue: 100 }
                after: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "CustomerAccountsPaginationQuery") {
                customerAccounts(first: $first, after: $after, searchTerm: $searchTerm, sort: CUSTOMER_SINCE_DESC)
                @connection(key: "CustomerAccountList_customerAccounts") {
                    totalCount
                    edges {
                        node {
                            ...CustomerAccountListItem_customerAccount
                        }
                    }
                }
            }
        `,
        props.queryRef
    );

    useEffect(() => {
        refetch({searchTerm: props.searchTerm}, {fetchPolicy: 'store-and-network'});
    }, [props.searchTerm, refetch])

    if (!data.customerAccounts || data.customerAccounts.totalCount === 0) {
        return (<></>);
    }

    return (
        <nav className="flex-1 min-h-0 relative overflow-y-auto" aria-label="Customers">
            <ul className="relative z-0 divide-y divide-gray-200">
                {data.customerAccounts.edges.map(
                    (edge, index) => {
                        return <CustomerAccountListItem key={index} customerAccount={edge.node}/>
                    }
                )}
            </ul>
            {hasNext && <LoadMoreButton onClick={() => loadNext(100)} isLoadingNext={isLoadingNext}/>}
        </nav>
    )
}

export default CustomerAccountList;
