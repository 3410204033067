import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronDown} from "@fortawesome/pro-solid-svg-icons";
import {Combobox} from '@headlessui/react'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

type comboboxData = Array<object>

type AbstractComboboxProps = {
    dataProvider: (query: string) => Promise<comboboxData>
    getEntryId: (entry: object) => string
    getEntryLabel: (entry: object | null) => string
    getEntrySecondaryLabel: (entry: object | null) => string | undefined
    initialValue: any
    onValueChange: (value: any) => void
    onSearch: (searchTerm: string) => void
    autoFocus: boolean | undefined
}

const AbstractCombobox: React.FC<AbstractComboboxProps> = ({dataProvider, getEntryId, getEntryLabel, getEntrySecondaryLabel, initialValue, onValueChange, autoFocus}) => {
    const [data, setData] = React.useState<comboboxData>([]);
    const [currentValue, setCurrentValue] = React.useState(initialValue);
    const [searchTerm, setSearchTerm] = React.useState(currentValue ? getEntryLabel(currentValue) : '');

    const onSearchChange = (event) => {
        const query = event.target.value;
        dataProvider(query).then(setData);
        setSearchTerm(query);
    }

    const onSelectValue = (entry) => {
        setCurrentValue(entry);
        onValueChange(getEntryId(entry));
    }

    return (
        <Combobox as="div" value={currentValue} onChange={onSelectValue}>
            {({open}) => (
                <div className="relative mt-1">
                    <Combobox.Input
                        className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm"
                        onChange={onSearchChange}
                        displayValue={
                            (entry: object | null) => open ? (searchTerm) : (
                                entry ? (getEntryLabel(entry) + (getEntrySecondaryLabel && ' (' + getEntrySecondaryLabel(entry) + ')')) : ''
                            )
                        }
                        autoFocus={autoFocus}
                    />
                    <Combobox.Button
                        className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                        <FontAwesomeIcon icon={faChevronDown} aria-hidden="true"/>
                    </Combobox.Button>

                    {data.length > 0 && (
                        <Combobox.Options
                            className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                            unmount={false}>
                            {data.map((entry) => (
                                <Combobox.Option
                                    key={getEntryId(entry)}
                                    value={entry}
                                    className={({active}) =>
                                        classNames('relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-red-600 text-white' : 'text-gray-900')
                                    }
                                >
                                    {({active, selected}) => (
                                        <div className="flex">
                                            <span className={classNames('block truncate', selected && 'font-semibold')}>
                                                {getEntryLabel(entry)}
                                            </span>
                                            {getEntrySecondaryLabel && (
                                                <span className={classNames('ml-4 truncate', active ? 'text-red-200' : 'text-gray-500')}>{getEntrySecondaryLabel(entry)}</span>
                                            )}
                                            {selected && (
                                                <span className={classNames('absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-red-600')}>
                                                    <FontAwesomeIcon icon={faCheck} aria-hidden="true"/>
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </Combobox.Option>
                            ))}
                        </Combobox.Options>
                    )}
                </div>
            )}

        </Combobox>
    )
}

export default AbstractCombobox;
