import * as React from "react";
import {MouseEventHandler, ReactNode} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/pro-duotone-svg-icons";
import Button from "./button/Button";
import {faPlus} from "@fortawesome/free-solid-svg-icons";

type NoDataProps = {
    icon?: IconDefinition
    headline: string
    description: string | ReactNode
    newButtonLabel?: string
    onNewButton?: MouseEventHandler
}

const NoData: React.FC<NoDataProps> = ({icon, headline, description, newButtonLabel, onNewButton}) => {
    return (
        <div className="text-center my-8">
            {icon && (<FontAwesomeIcon icon={icon} size="2x" className="text-gray-400"/>)}
            <h3 className="mt-2 text-sm font-medium text-gray-900">{headline}</h3>
            <p className="mt-1 text-sm text-gray-500">
                {description}
            </p>
            {onNewButton && (
                <div className="mt-6">
                    <Button label={newButtonLabel} onClick={onNewButton} icon={faPlus}/>
                </div>
            )}
        </div>
    )
}

export default NoData;
