/**
 * @generated SignedSource<<c337e31b753cb7791527ed86886835d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadInvoiceAttachmentInput = {
  id: string;
  upload: UploadInput;
};
export type UploadInput = {
  data: string;
  filename: string;
  lastModifiedAt: any;
};
export type DraftInvoiceViewAddInvoiceAttachmentMutation$variables = {
  input: UploadInvoiceAttachmentInput;
};
export type DraftInvoiceViewAddInvoiceAttachmentMutation$data = {
  readonly uploadInvoiceAttachment: {
    readonly attachments: ReadonlyArray<{
      readonly filename: string;
      readonly lastModifiedAt: any;
      readonly sha1: string;
      readonly size: number;
    }>;
    readonly id: string;
  };
};
export type DraftInvoiceViewAddInvoiceAttachmentMutation = {
  response: DraftInvoiceViewAddInvoiceAttachmentMutation$data;
  variables: DraftInvoiceViewAddInvoiceAttachmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "uploadInvoiceAttachment",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "InvoiceAttachment",
        "kind": "LinkedField",
        "name": "attachments",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sha1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "filename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastModifiedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "size",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftInvoiceViewAddInvoiceAttachmentMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftInvoiceViewAddInvoiceAttachmentMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ecf2445dc42f95a810fc0584cb4d65b6",
    "id": null,
    "metadata": {},
    "name": "DraftInvoiceViewAddInvoiceAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation DraftInvoiceViewAddInvoiceAttachmentMutation(\n  $input: UploadInvoiceAttachmentInput!\n) {\n  uploadInvoiceAttachment(input: $input) {\n    id\n    attachments {\n      sha1\n      filename\n      lastModifiedAt\n      size\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6ec95a1dc470cf3a52d99fb78238b8dd";

export default node;
