import * as React from "react";
import {MouseEventHandler} from "react";
import {IconDefinition} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Link from "../../navigation/Link";
import {SizeProp} from "@fortawesome/fontawesome-svg-core";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";

type AdditionalSecondaryButtonProps = {
    label?: string
    title?: string
    isSubmitting?: boolean
    onClick?: MouseEventHandler
    icon?: IconDefinition
    linkTo?: string
    disabled?: boolean
    visible?: boolean
    spinIcon?: boolean
    iconSize?: SizeProp
    type?: "button" | "submit" | "reset" | undefined
    additionalClassname?: string
}

type SecondaryButtonProps = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & AdditionalSecondaryButtonProps

const ActionButton: React.FC<SecondaryButtonProps> = (props) => {
    const {label, title, isSubmitting, onClick, icon, linkTo, disabled = false, visible, spinIcon, iconSize = "1x", type, additionalClassname, ...attributes} = props
    const actualIcon = isSubmitting ? faSpinnerThird : icon

    let className;

    if (disabled || isSubmitting) {
        className = "inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 ml-2 text-xs font-medium text-gray-300 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 cursor-not-allowed " + additionalClassname;
    } else {
        className = "inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 ml-2 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 " + additionalClassname;
    }
    if (visible === false) {
        className = className + ' invisible';
    }

    const button = (
        <button type={type !== undefined ? type : (onClick !== undefined ? 'button' : 'submit')}
                onClick={(event) => {
                    if (onClick) {
                        onClick(event)
                    }
                    event.currentTarget.blur()
                }}
                disabled={disabled || isSubmitting}
                aria-disabled={disabled || isSubmitting}
                className={className}
                {...attributes}
        >
            {actualIcon &&
                <span className={label ? 'pr-2' : ''}>
                    <FontAwesomeIcon icon={actualIcon} title={title} size={iconSize} spin={spinIcon || isSubmitting}/>
                </span>
            }
            {label}
        </button>
    );
    if (linkTo) {
        return (
            <Link to={linkTo}>
                {button}
            </Link>
        )
    } else {
        return button;
    }
}

export default ActionButton;
