/**
 * @generated SignedSource<<af079aa16368766d5730f3c518bf38c5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdatePersonContactInput = {
  emailAddress?: string | null;
  familyName: string;
  givenName: string;
  id: string;
  phoneNumber?: string | null;
  salutation: string;
};
export type UpdatePersonContactDialogMutation$variables = {
  input: UpdatePersonContactInput;
};
export type UpdatePersonContactDialogMutation$data = {
  readonly updatePersonContact: {
    readonly emailAddress: string | null;
    readonly familyName: string;
    readonly givenName: string;
    readonly id: string;
    readonly phoneNumber: string | null;
    readonly salutation: string;
  };
};
export type UpdatePersonContactDialogMutation = {
  response: UpdatePersonContactDialogMutation$data;
  variables: UpdatePersonContactDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PersonContact",
    "kind": "LinkedField",
    "name": "updatePersonContact",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "salutation",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "givenName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "familyName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "emailAddress",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "phoneNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdatePersonContactDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdatePersonContactDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "5d87daa525cf7cda23bb4c790219795d",
    "id": null,
    "metadata": {},
    "name": "UpdatePersonContactDialogMutation",
    "operationKind": "mutation",
    "text": "mutation UpdatePersonContactDialogMutation(\n  $input: UpdatePersonContactInput!\n) {\n  updatePersonContact(input: $input) {\n    id\n    salutation\n    givenName\n    familyName\n    emailAddress\n    phoneNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "d953a69fc6909f41d458588c97a923fc";

export default node;
