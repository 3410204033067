/**
 * @generated SignedSource<<38d548e0d0cc062be199e1265dd8d688>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerAccountListItem_customerAccount$data = {
  readonly customerName: string;
  readonly customerNumber: string;
  readonly id: string;
  readonly " $fragmentType": "CustomerAccountListItem_customerAccount";
};
export type CustomerAccountListItem_customerAccount$key = {
  readonly " $data"?: CustomerAccountListItem_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerAccountListItem_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerAccountListItem_customerAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerNumber",
      "storageKey": null
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "8fc5a3ddcfebaa23def1cf0e4b697fa3";

export default node;
