/**
 * @generated SignedSource<<019efdaa711467d34cf51ce392c801d7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddContactDialog_customerAccount$data = {
  readonly id: string;
  readonly " $fragmentType": "AddContactDialog_customerAccount";
};
export type AddContactDialog_customerAccount$key = {
  readonly " $data"?: AddContactDialog_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"AddContactDialog_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AddContactDialog_customerAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "97c4ce18bdc6c580904e907de32b5a44";

export default node;
