/**
 * @generated SignedSource<<7040038d2409f6994ed6547e66af5ad7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SettlementViewCreateInvoiceMutation$variables = {
  settlementIdentifier: string;
};
export type SettlementViewCreateInvoiceMutation$data = {
  readonly createInvoiceFromSettlement: {
    readonly id: string;
    readonly invoiceNumber: string | null;
  };
};
export type SettlementViewCreateInvoiceMutation = {
  response: SettlementViewCreateInvoiceMutation$data;
  variables: SettlementViewCreateInvoiceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "settlementIdentifier"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "settlementIdentifier",
            "variableName": "settlementIdentifier"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "createInvoiceFromSettlement",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "invoiceNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SettlementViewCreateInvoiceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SettlementViewCreateInvoiceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a8cd96d5fc4faee27e3ba8d2e0733420",
    "id": null,
    "metadata": {},
    "name": "SettlementViewCreateInvoiceMutation",
    "operationKind": "mutation",
    "text": "mutation SettlementViewCreateInvoiceMutation(\n  $settlementIdentifier: String!\n) {\n  createInvoiceFromSettlement(input: {settlementIdentifier: $settlementIdentifier}) {\n    id\n    invoiceNumber\n  }\n}\n"
  }
};
})();

(node as any).hash = "4910fa918490d62c1628ed0666cb1f7e";

export default node;
