/**
 * @generated SignedSource<<f24998c19f66a4dcd243889644214253>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ContactListItem_contact$data = {
  readonly emailAddress: string | null;
  readonly familyName?: string;
  readonly givenName?: string;
  readonly id: string;
  readonly teamName?: string;
  readonly " $fragmentType": "ContactListItem_contact";
};
export type ContactListItem_contact$key = {
  readonly " $data"?: ContactListItem_contact$data;
  readonly " $fragmentSpreads": FragmentRefs<"ContactListItem_contact">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ContactListItem_contact",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "givenName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "familyName",
          "storageKey": null
        }
      ],
      "type": "PersonContact",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "teamName",
          "storageKey": null
        }
      ],
      "type": "TeamContact",
      "abstractKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emailAddress",
      "storageKey": null
    }
  ],
  "type": "Contact",
  "abstractKey": "__isContact"
};

(node as any).hash = "8147dfe24f97fd3de9a65d350dd99e61";

export default node;
