import * as React from "react";
import {MouseEventHandler} from "react";

type LoadMoreButtonProps = {
    onClick: MouseEventHandler
    isLoadingNext: boolean
}

const LoadMoreButton: React.FC<LoadMoreButtonProps> = ({onClick, isLoadingNext}) => {
    return (
        <div className="flex-1 flex justify-center my-10">
            <button onClick={onClick} disabled={isLoadingNext} className={'ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md bg-white hover:bg-gray-50 ' + (isLoadingNext ? 'text-gray-300' : 'text-gray-700')}>
                Load more
            </button>
        </div>
    );
}

export default LoadMoreButton;
