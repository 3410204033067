import * as React from "react";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import Panel from "./presentational/Panel";
import Hint from "./presentational/Hint";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type ProjectPaymentViewProps = Props & ConnectedProps<typeof connector>;

const ProjectPaymentView: React.FC<ProjectPaymentViewProps> = (props) => {
    return (
        <>
            <Panel headline="Payment Method">
                <Hint>Custom payment methods for projects are not implemented yet.</Hint>
            </Panel>
        </>
    )
}

export default connector(ProjectPaymentView);
