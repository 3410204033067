import * as React from "react";
import {graphql} from "react-relay";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import UpdatePersonContactDialog from "./dialog/UpdatePersonContactDialog";
import ActionButton from "./presentational/button/ActionButton";
import UpdateTeamContactDialog from "./dialog/UpdateTeamContactDialog";
import {useFragment} from "react-relay/hooks";

type Props = {
    queryRef: any
}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});


type ContactDataListProps = Props & ConnectedProps<typeof connector>;

const ContactDataList: React.FC<ContactDataListProps> = (props) => {
    const contact = useFragment(
        graphql`
            fragment ContactDataList_contact on Contact {
                id
                __typename
                ... on PersonContact {
                    salutation
                    givenName
                    familyName
                }
                ... on TeamContact {
                    teamName
                }
                emailAddress
                phoneNumber
                ...UpdatePersonContactDialog_contact
                ...UpdateTeamContactDialogProps_contact
            }
        `
        , props.queryRef
    )

    const name = contact.teamName ? contact.teamName : contact.givenName + " " + contact.familyName;

    const {dispatchShowModal, dispatchHideModal} = props;
    const showUpdatePersonContactDialog = () => {
        dispatchShowModal({
            body: (<UpdatePersonContactDialog queryRef={contact} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const showUpdateTeamContactDialog = () => {
        dispatchShowModal({
            body: (<UpdateTeamContactDialog queryRef={contact} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <dl>
            {contact.__typename === 'PersonContact' && (
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                    <dt className="text-sm font-medium text-gray-500">
                        Salutation
                    </dt>
                    <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="grow">
                        {contact.salutation}
                    </span>
                        <span className="ml-4 shrink-0">
                        <ActionButton onClick={showUpdatePersonContactDialog} label="Edit"/>
                    </span>
                    </dd>
                </div>
            )}
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                    Name
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="grow">{name}</span>
                    <span className="ml-4 shrink-0">
                        {contact.__typename === 'TeamContact' && <ActionButton onClick={showUpdateTeamContactDialog} label="Edit"/>}
                    </span>
                </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                    Email address
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="grow">
                        {contact.emailAddress}
                    </span>
                    <span className="ml-4 shrink-0"/>
                </dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                <dt className="text-sm font-medium text-gray-500">
                    Phone number
                </dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    <span className="grow">
                        {contact.phoneNumber}
                    </span>
                </dd>
            </div>
        </dl>
    )
}

export default connector(ContactDataList);
