import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import {useOutletContext} from "react-router";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import DeleteCustomerDialog from "./dialog/DeleteCustomerDialog";
import {countries, CountryOptions} from "../Countries";
import Panel from "./presentational/Panel";
import EditableDataListItem from "./form/EditableDataListItem";
import * as yup from "yup";
import EditableDataListField, {FieldDataType} from "./form/EditableDataListField";
import ActionButton from "./presentational/button/ActionButton";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type CustomerAccountViewProps = Props & ConnectedProps<typeof connector>;

const taxStatuses = {
    null: "Unknown",
    REGULAR: "Regular",
    EXEMPT: "Exempt",
    REVERSE_CHARGE: "Reverse Charge"
}

const languages = {
    de: "German",
    en: "English",
}

const handleRenameCustomerSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation CustomerAccountViewRenameCustomerMutation($input: RenameCustomerAccountInput!) {
                    renameCustomerAccount(input: $input) {
                        id
                        customerName
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdateCustomerBusinessAddress = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation CustomerAccountViewUpdateCustomerBusinessAddressMutation($input: UpdateCustomerBusinessAddressInput!) {
                    updateCustomerBusinessAddress(input: $input) {
                        id
                        businessAddress {
                            businessName
                            addressLine1
                            addressLine2
                            postalCode
                            city
                            country
                        }
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdateCustomerTaxInformationSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation CustomerAccountViewUpdateCustomerTaxInformationMutation($input: UpdateCustomerTaxInformationInput!) {
                    updateCustomerTaxInformation(input: $input) {
                        id
                        vatId
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdateCustomerTaxStatusSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation CustomerAccountViewUpdateCustomerTaxStatusMutation($input: UpdateCustomerTaxStatusInput!) {
                    updateCustomerTaxStatus(input: $input) {
                        id
                        taxStatus
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdatePreferredLanguageSubmission = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation CustomerAccountViewUpdatePreferredLanguageMutation($input: UpdatePreferredLanguageInput!) {
                    updatePreferredLanguage(input: $input) {
                        id
                        preferredLanguage
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const CustomerAccountView: React.FC<CustomerAccountViewProps> = (props) => {
    const queryRef: any = useOutletContext();
    const customerAccount = useFragment(
        graphql`
            fragment CustomerAccountView_customerAccount on CustomerAccount {
                id
                customerName
                customerNumber
                customerSince
                vatId
                taxStatus
                preferredLanguage
                businessAddress {
                    businessName
                    addressLine1
                    addressLine2
                    postalCode
                    city
                    country
                }
                ...DeleteCustomerDialog_customerAccount
            }
        `,
        queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const {dispatchShowModal, dispatchHideModal} = props;

    const showDeleteCustomerAccountDialog = () => {
        dispatchShowModal({
            body: (<DeleteCustomerDialog queryRef={customerAccount} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const customerNameValidationSchema = yup.object().shape({
        customerName: yup.string()
            .min(2, 'This customer name is too short')
            .max(255, 'This customer name is too long')
            .required('This field is required')
    });

    const customerTaxInformationValidationSchema = yup.object().shape({
        vatId: yup.string()
            .min(2, 'This vat ID is too short')
            .max(23, 'This vat ID is too long')
            .matches(/^[A-Z0-9,.\- ]{2,23}$/, 'This does not look like a valid VAT ID')
            .nullable()
    });

    const businessAddressValidationSchema = yup.object().shape({
        businessName: yup.string()
            .min(2, 'This customer name is too short')
            .max(255, 'This customer name is too long')
            .nullable()
            .required('This field is required'),
        postalCode: yup.string()
            .matches(/^[a-zA-Z0-9 .-]{2,12}$/, 'Invalid postal code')
            .nullable()
            .required('This field is required'),
        city: yup.string()
            .matches(/^[-+&\p{L}\p{P}\d .]{2,255}$/u, 'Invalid city name')
            .nullable()
            .required('This field is required'),
    });

    return (
        <>
            <Panel headline="Account Information">
                <dl className="divide-y divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                        <dt className="text-sm font-medium text-gray-500">Customer Number</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <span className="grow">{customerAccount.customerNumber}</span>
                        </dd>
                    </div>

                    <EditableDataListItem
                        label="Customer Name"
                        fields={(<EditableDataListField type="text" name="customerName" placeholder="Customer Name Ltd." autoFocus={true}/>)}
                        initialValues={{id: customerAccount.id, customerName: customerAccount.customerName}}
                        validationSchema={customerNameValidationSchema}
                        handleSubmission={(values, setEditingActive) => handleRenameCustomerSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {customerAccount.customerName}
                    </EditableDataListItem>

                    <EditableDataListItem
                        label="Business Address"
                        fields={(
                            <>
                                <EditableDataListField type="text" name="businessName" autoFocus={true}/>
                                <EditableDataListField type="text" name="addressLine1" placeholder="Street address"/>
                                <EditableDataListField type="text" name="addressLine2" placeholder="Additional address information"/>
                                <EditableDataListField type="text" name="postalCode" placeholder="Postal code"/>
                                <EditableDataListField type="text" name="city" placeholder="City"/>
                                <EditableDataListField name="country" fieldDataType={FieldDataType.select}>
                                    <CountryOptions/>
                                </EditableDataListField>
                            </>
                        )}
                        initialValues={{
                            id: customerAccount.id,
                            businessName: customerAccount.businessAddress ? customerAccount.businessAddress.businessName : null,
                            addressLine1: customerAccount.businessAddress ? customerAccount.businessAddress.addressLine1 : null,
                            addressLine2: customerAccount.businessAddress ? customerAccount.businessAddress.addressLine2 : null,
                            postalCode: customerAccount.businessAddress ? customerAccount.businessAddress.postalCode : null,
                            city: customerAccount.businessAddress ? customerAccount.businessAddress.city : null,
                            country: customerAccount.businessAddress ? customerAccount.businessAddress.country : 'DEU'
                        }}
                        validationSchema={businessAddressValidationSchema}
                        handleSubmission={(values, setEditingActive) => handleUpdateCustomerBusinessAddress(values, setEditingActive, relayEnvironment)}
                    >
                        {customerAccount.businessAddress && <>
                            {customerAccount.businessAddress.businessName}<br/>
                            {customerAccount.businessAddress.addressLine1}<br/>
                            {customerAccount.businessAddress.addressLine2}{customerAccount.businessAddress.addressLine2 && <br/>}
                            {customerAccount.businessAddress.postalCode} {customerAccount.businessAddress.city}<br/>
                            {countries[customerAccount.businessAddress.country]}
                        </>}
                    </EditableDataListItem>

                    <EditableDataListItem
                        label="VAT ID"
                        fields={(<EditableDataListField type="text" name="vatId" placeholder="DE999999999" autoFocus={true}/>)}
                        initialValues={{id: customerAccount.id, vatId: customerAccount.vatId}}
                        validationSchema={customerTaxInformationValidationSchema}
                        handleSubmission={(values, setEditingActive) => handleUpdateCustomerTaxInformationSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {customerAccount.vatId}
                    </EditableDataListItem>

                    <EditableDataListItem
                        label="Tax status"
                        fields={(
                            <>
                                <EditableDataListField name="taxStatus" fieldDataType={FieldDataType.select}>
                                    {Object.keys(taxStatuses).map((key) => {
                                        return (
                                            <option key={key} value={key}>{taxStatuses[key]}</option>
                                        )
                                    })}
                                </EditableDataListField>
                            </>
                        )}
                        initialValues={{
                            id: customerAccount.id,
                            taxStatus: customerAccount.taxStatus ?? null
                        }}
                        handleSubmission={(values, setEditingActive) => handleUpdateCustomerTaxStatusSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {taxStatuses[customerAccount.taxStatus]}
                    </EditableDataListItem>

                    <EditableDataListItem
                        label="Preferred language"
                        fields={(
                            <>
                                <EditableDataListField name="preferredLanguage" fieldDataType={FieldDataType.select}>
                                    {Object.keys(languages).map((key) => {
                                        return (
                                            <option key={key} value={key}>{languages[key]}</option>
                                        )
                                    })}
                                </EditableDataListField>
                            </>
                        )}
                        initialValues={{
                            id: customerAccount.id,
                            preferredLanguage: customerAccount.preferredLanguage
                        }}
                        handleSubmission={(values, setEditingActive) => handleUpdatePreferredLanguageSubmission(values, setEditingActive, relayEnvironment)}
                    >
                        {languages[customerAccount.preferredLanguage]}
                    </EditableDataListItem>
                </dl>
            </Panel>
            <div className="grid grid-cols-1 gap-4 place-items-end">
                <ActionButton onClick={showDeleteCustomerAccountDialog} label="Delete Account"/>
            </div>

        </>
    )
}

export default connector(CustomerAccountView);
