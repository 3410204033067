/**
 * @generated SignedSource<<3d32e035b8409e2f72ddf24ed29b97c2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateCustomerBusinessAddressInput = {
  addressLine1?: string | null;
  addressLine2?: string | null;
  businessName?: string | null;
  city?: string | null;
  country?: string | null;
  id: string;
  postalCode?: string | null;
};
export type CustomerAccountViewUpdateCustomerBusinessAddressMutation$variables = {
  input: UpdateCustomerBusinessAddressInput;
};
export type CustomerAccountViewUpdateCustomerBusinessAddressMutation$data = {
  readonly updateCustomerBusinessAddress: {
    readonly businessAddress: {
      readonly addressLine1: string | null;
      readonly addressLine2: string | null;
      readonly businessName: string;
      readonly city: string;
      readonly country: string;
      readonly postalCode: string;
    } | null;
    readonly id: string;
  };
};
export type CustomerAccountViewUpdateCustomerBusinessAddressMutation = {
  response: CustomerAccountViewUpdateCustomerBusinessAddressMutation$data;
  variables: CustomerAccountViewUpdateCustomerBusinessAddressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CustomerAccount",
    "kind": "LinkedField",
    "name": "updateCustomerBusinessAddress",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessAddress",
        "kind": "LinkedField",
        "name": "businessAddress",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "businessName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressLine1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "addressLine2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postalCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "city",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "country",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CustomerAccountViewUpdateCustomerBusinessAddressMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CustomerAccountViewUpdateCustomerBusinessAddressMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "586f6015cfcb9b7e8f837f6f59b5a411",
    "id": null,
    "metadata": {},
    "name": "CustomerAccountViewUpdateCustomerBusinessAddressMutation",
    "operationKind": "mutation",
    "text": "mutation CustomerAccountViewUpdateCustomerBusinessAddressMutation(\n  $input: UpdateCustomerBusinessAddressInput!\n) {\n  updateCustomerBusinessAddress(input: $input) {\n    id\n    businessAddress {\n      businessName\n      addressLine1\n      addressLine2\n      postalCode\n      city\n      country\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "31a2efb009ff995534196a2f62d2227a";

export default node;
