import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";
import Warning from "../presentational/Warning";

type DeleteProjectDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const DeleteProjectDialog: React.FC<DeleteProjectDialogProps> = (props) => {

    const navigate = useNavigate();
    const project = useFragment(
        graphql`
            fragment DeleteProjectDialog_project on Project {
                id
                projectName
                projectNumber
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation DeleteProjectDialogMutation(
                        $input: DeleteProjectInput!
                    ) {
                        deleteProject(input: $input) {
                            id @deleteRecord
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                    navigate('/projects')
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        id: project.id
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Delete Project" submitLabel="Delete" dangerous={true}>
            <Section>
                <Row>
                    <p className="text-gray-900 font-medium">
                        Really delete project "{project.projectName}" {project.projectNumber ? '(' + project.projectNumber + ')' : ''}?
                    </p>
                </Row>
                <Row>
                    <Warning>
                        This will also delete the corresponding project in Harvest, including all potential time logs.
                    </Warning>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default DeleteProjectDialog;
