export type CabanaConfiguration = {
    auth0: {
        domain: string
        clientId: string
        redirectUri: string
        audience: string
    },
    graphql: {
        uri: string
    }
    sentry?: {
        dsn: string
        environment: string
    }
    stripe: {
        publishableKey: string
    }
}

async function loadCabanaConfiguration(appRoot: HTMLElement): Promise<CabanaConfiguration> {
    if (!appRoot) {
        throw Error('Element "app-root" was not found, but is expected to provide the configuration URI');
    }

    const configurationUri = appRoot.dataset.configurationUri;
    if (!configurationUri) {
        throw Error('No configuration URI found in "root" element');
    }

    try {
        const response = await fetch(configurationUri);
        return response.json();
    } catch (err) {
        throw Error('Failed retrieving Cabana configuration from ' + configurationUri + ': ' + err);
    }
}

export default loadCabanaConfiguration;
