/**
 * @generated SignedSource<<daebeb577aae53460ffdc1ac9c50da7c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InvoicesViewInvoicesQuery$variables = {};
export type InvoicesViewInvoicesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceList_query">;
};
export type InvoicesViewInvoicesQuery = {
  response: InvoicesViewInvoicesQuery$data;
  variables: InvoicesViewInvoicesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  },
  {
    "kind": "Literal",
    "name": "searchTerm",
    "value": ""
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": "CREATED_AT_DESC"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InvoicesViewInvoicesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "InvoiceList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InvoicesViewInvoicesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v0/*: any*/),
        "concreteType": "InvoiceConnection",
        "kind": "LinkedField",
        "name": "invoices",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "InvoiceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Invoice",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "invoiceNumber",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "invoiceDate",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "createdAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalGrossAmount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerAccount",
                    "kind": "LinkedField",
                    "name": "customerAccount",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customerName",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "invoices(first:100,searchTerm:\"\",sort:\"CREATED_AT_DESC\")"
      },
      {
        "alias": null,
        "args": (v0/*: any*/),
        "filters": [
          "searchTerm",
          "sort"
        ],
        "handle": "connection",
        "key": "InvoiceList_invoices",
        "kind": "LinkedHandle",
        "name": "invoices"
      }
    ]
  },
  "params": {
    "cacheID": "5d2d67c4db65156135b71d5e4ae0bd68",
    "id": null,
    "metadata": {},
    "name": "InvoicesViewInvoicesQuery",
    "operationKind": "query",
    "text": "query InvoicesViewInvoicesQuery {\n  ...InvoiceList_query\n}\n\nfragment DeleteInvoiceDialog_invoice on Invoice {\n  id\n}\n\nfragment InvoiceList_query on Query {\n  invoices(first: 100, searchTerm: \"\", sort: CREATED_AT_DESC) {\n    totalCount\n    edges {\n      node {\n        id\n        invoiceNumber\n        state\n        invoiceDate\n        createdAt\n        totalGrossAmount\n        customerAccount {\n          id\n          customerName\n        }\n        ...DeleteInvoiceDialog_invoice\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c6d5cb1e8d64b32249f3a766dfe4ae42";

export default node;
