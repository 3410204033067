/**
 * @generated SignedSource<<3aa2864cc82fc81d5d8abfd5d5efcb2e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateInvoiceCustomerOrderNotesInput = {
  customerOrderNotes: string;
  id: string;
};
export type DraftInvoiceViewUpdateCustomerOrderNotesMutation$variables = {
  input: UpdateInvoiceCustomerOrderNotesInput;
};
export type DraftInvoiceViewUpdateCustomerOrderNotesMutation$data = {
  readonly updateInvoiceCustomerOrderNotes: {
    readonly customerOrderNotes: string | null;
    readonly id: string;
  };
};
export type DraftInvoiceViewUpdateCustomerOrderNotesMutation = {
  response: DraftInvoiceViewUpdateCustomerOrderNotesMutation$data;
  variables: DraftInvoiceViewUpdateCustomerOrderNotesMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "updateInvoiceCustomerOrderNotes",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerOrderNotes",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftInvoiceViewUpdateCustomerOrderNotesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftInvoiceViewUpdateCustomerOrderNotesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "841dfe72653b78c9ca70471065de683e",
    "id": null,
    "metadata": {},
    "name": "DraftInvoiceViewUpdateCustomerOrderNotesMutation",
    "operationKind": "mutation",
    "text": "mutation DraftInvoiceViewUpdateCustomerOrderNotesMutation(\n  $input: UpdateInvoiceCustomerOrderNotesInput!\n) {\n  updateInvoiceCustomerOrderNotes(input: $input) {\n    id\n    customerOrderNotes\n  }\n}\n"
  }
};
})();

(node as any).hash = "b6b9da6fc3f946a65589663a07616520";

export default node;
