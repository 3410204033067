import * as React from "react";
import {useState} from "react";
import Panel from "./presentational/Panel";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import {graphql} from "react-relay";
import Headline from "./presentational/Headline";
import InvoiceList from "./InvoiceList";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import type {InvoicesViewInvoicesQuery as InvoicesViewInvoicesQueryType} from "../__relay_artifacts__/InvoicesViewInvoicesQuery.graphql";
import InvoicesViewInvoicesQuery from "../__relay_artifacts__/InvoicesViewInvoicesQuery.graphql";
import ListSearch from "./presentational/ListSearch";
import CreateInvoiceDialog from "./dialog/CreateInvoiceDialog";
import ActionButton from "./presentational/button/ActionButton";
import {faPlusCircle} from "@fortawesome/pro-regular-svg-icons";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type Props = {
    prepared: any
};

type InvoicesViewProps = ConnectedProps<typeof connector> & Props;

const InvoicesView: React.FC<InvoicesViewProps> = (props) => {
    const {dispatchShowModal, dispatchHideModal} = props
    const [searchTerm, setSearchTerm] = useState('');

    const invoices = usePreloadedQuery<InvoicesViewInvoicesQueryType>(
        graphql`
            query InvoicesViewInvoicesQuery {
                ...InvoiceList_query
            }
        `,
        props.prepared.invoicesQuery,
    );

    const searchAPIDebounced = AwesomeDebouncePromise(
        searchTerm => {
            setSearchTerm(searchTerm)
        },
        500
    );

    const handleSearchTermChange = async (searchTerm) => {
        await searchAPIDebounced(searchTerm);
    };

    const showCreateInvoiceDialog = () => {
        dispatchShowModal({
            body: (<CreateInvoiceDialog handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <div className="lg:mt-6 mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <Panel>
                <div className="px-6 pt-6 pb-4">
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <Headline>Invoices</Headline>
                        </div>
                        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                            <ActionButton icon={faPlusCircle} title="Create invoice" onClick={() => {
                                showCreateInvoiceDialog()
                            }}/>
                        </div>
                    </div>
                    <ListSearch handleSearchTermChange={handleSearchTermChange} placeholder="Search Invoices"/>
                </div>
                <InvoiceList queryRef={invoices} searchTerm={searchTerm}/>
            </Panel>
        </div>
    )
}

export default connector(InvoicesView);

function prepare_InvoicesView(params, relayEnvironment) {
    return {
        invoicesQuery: loadQuery(
            relayEnvironment,
            InvoicesViewInvoicesQuery,
            {},
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_InvoicesView};
