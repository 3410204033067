import * as React from "react"
import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";
import {BrowserRouter} from "react-router-dom";
import {Provider as ReduxProvider} from "react-redux";
import Auth0ProviderWithNavigation from "./Auth0ProviderWithNavigation";
import CabanaConfigurationContext from "./configuration/CabanaConfigurationContext";
import loadCabanaConfiguration from "./configuration/CabanaConfiguration";
import RelayEnvironmentProviderWithAuth0 from "./RelayEnvironmentProviderWithAuth0";
import store from "./store/store";
import './assets/main.css';
import Root from "./Root";
import {createRoot} from "react-dom/client";

const rootElement = document.getElementById('app-root');
if (!rootElement) throw new Error('Failed to find the app root element');

loadCabanaConfiguration(rootElement).then(configuration => {
    if (configuration.sentry) {
        Sentry.init({
            dsn: configuration.sentry.dsn,
            environment: configuration.sentry.environment,
            integrations: [new Integrations.BrowserTracing()],
            tracesSampleRate: 1.0,
        });

    }
    const root = createRoot(rootElement);

    const render = Component => {
        return root.render(
            <CabanaConfigurationContext.Provider value={configuration}>
                <ReduxProvider store={store}>
                    <BrowserRouter>
                        <Auth0ProviderWithNavigation configuration={configuration}>
                            <RelayEnvironmentProviderWithAuth0 configuration={configuration}>
                                <Component configuration={configuration}/>
                            </RelayEnvironmentProviderWithAuth0>
                        </Auth0ProviderWithNavigation>
                    </BrowserRouter>
                </ReduxProvider>
            </CabanaConfigurationContext.Provider>
        );
    };

    render(Root);

    // This is the hot reloading magic needed.
    // see https://medium.com/@brianhan/hot-reloading-cra-without-eject-b54af352c642
    //@ts-ignore
    if (module.hot) {
        //@ts-ignore
        module.hot.accept('./Root', () => {
            //@ts-ignore
            const NextApp = require('./Root').default;
            render(NextApp);
        });
    }
});
