/**
 * @generated SignedSource<<408d4159ab8918fade57d8e63d1512b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerAccountHeader_customerAccount$data = {
  readonly contacts: {
    readonly totalCount: number | null;
  } | null;
  readonly customerName: string;
  readonly id: string;
  readonly " $fragmentType": "CustomerAccountHeader_customerAccount";
};
export type CustomerAccountHeader_customerAccount$key = {
  readonly " $data"?: CustomerAccountHeader_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerAccountHeader_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CustomerAccountHeader_customerAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccountContactConnection",
      "kind": "LinkedField",
      "name": "contacts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "2aacefaa5945ed46683f5c7e306c8717";

export default node;
