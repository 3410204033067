import * as React from "react";
import {graphql} from "react-relay";
import DetailedContactListItem from "./DetailedContactListItem";
import {useFragment} from "react-relay/hooks";

type Props = {
    queryRef: any
    typeNameFilter?: string
}

const DetailedContactList: React.FC<Props> = (props) => {

    const customerAccount = useFragment(
        graphql`
            fragment DetailedContactList_customerAccount on CustomerAccount {
                contacts {
                    edges {
                        node {
                            ...DetailedContactListItem_contact
                            id
                            __typename
                        }
                        roles {
                            roleIdentifier
                            label {
                                en
                            }
                        }
                    }
                }
                ...DetailedContactListItem_customerAccount
            }
        `,
        props.queryRef
    );


    let contactEdges = customerAccount.contacts.edges;
    if (props.typeNameFilter) {
        contactEdges = contactEdges.filter((edge) => {
            return (edge && edge.node && edge.node.__typename === props.typeNameFilter)
        })
    }
    return (
        <ul className="relative z-0 divide-y divide-gray-200">
            {contactEdges.map((edge, index) => {
                return <DetailedContactListItem key={index} customerAccount={customerAccount} contact={edge.node} roles={edge.roles} index={index}/>
            })}
        </ul>
    )
}

export default DetailedContactList
