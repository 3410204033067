import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import Hint from "../presentational/Hint";
import {useNavigate} from "react-router-dom";
import {CreateReverseInvoiceDialogMutation} from "../../__relay_artifacts__/CreateReverseInvoiceDialogMutation.graphql";

type CreateReverseInvoiceDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const CreateReverseInvoiceDialog: React.FC<CreateReverseInvoiceDialogProps> = (props) => {

    const invoice = useFragment(
        graphql`
            fragment CreateReverseInvoiceDialog_invoice on Invoice {
                id
                invoiceNumber
                customerAccount {
                    customerName
                }
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();
    const navigate = useNavigate();

    const handleSubmission = (input) => {
        commitMutation<CreateReverseInvoiceDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreateReverseInvoiceDialogMutation(
                        $input: CreateReverseInvoiceInput!
                    ) {
                        createReverseInvoice(input: $input) {
                            id
                            updatedAt
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                    navigate('/invoices');
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        relatedInvoiceIdentifier: invoice.id
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Reverse Invoice" submitLabel="Create">
            <Section>
                <Row>
                    <div className="text-gray-900">
                        <p>Create a reverse invoice for {invoice.invoiceNumber}?</p>
                    </div>
                    <Hint className="py-4">
                        A new reverse invoice will be created as a draft invoice. Nothing is is sent to {invoice.customerAccount.customerName} and no payments are canceled until the reverse invoice was finalized.
                    </Hint>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default CreateReverseInvoiceDialog;
