/**
 * @generated SignedSource<<d573a6e02785e0cd3b8f1a7aebf1fb7b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DetailedPaymentMethodList_customerAccount$data = {
  readonly defaultPaymentMethod: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly paymentMethods: ReadonlyArray<{
    readonly cardBrand: string | null;
    readonly expirationDate: string | null;
    readonly id: string;
    readonly lastFourDigits: string | null;
    readonly paymentMethodType: string;
    readonly paymentTerms: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"DeletePaymentMethodDialog_paymentMethod" | "SetDefaultPaymentMethodDialog_paymentMethod">;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"SetDefaultPaymentMethodDialog_customerAccount">;
  readonly " $fragmentType": "DetailedPaymentMethodList_customerAccount";
};
export type DetailedPaymentMethodList_customerAccount$key = {
  readonly " $data"?: DetailedPaymentMethodList_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"DetailedPaymentMethodList_customerAccount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DetailedPaymentMethodList_customerAccount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "defaultPaymentMethod",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "paymentMethods",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardBrand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastFourDigits",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "expirationDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentTerms",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SetDefaultPaymentMethodDialog_paymentMethod"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DeletePaymentMethodDialog_paymentMethod"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SetDefaultPaymentMethodDialog_customerAccount"
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};
})();

(node as any).hash = "fa7892c883ecff4ab32694cc71dd9e7f";

export default node;
