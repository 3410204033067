/**
 * @generated SignedSource<<5b8364df14b66989dd58a6e2be9fd99a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateTermsAndConditionsDocumentInput = {
  code: string;
  publishDate?: string | null;
  published: boolean;
  startDate: string;
  title: string;
  uri: string;
  version: string;
};
export type CreateTermsAndConditionsDocumentDialogMutation$variables = {
  input: CreateTermsAndConditionsDocumentInput;
};
export type CreateTermsAndConditionsDocumentDialogMutation$data = {
  readonly createTermsAndConditionsDocument: {
    readonly code: string;
    readonly id: string;
    readonly startDate: any;
    readonly title: string;
    readonly version: string;
  };
};
export type CreateTermsAndConditionsDocumentDialogMutation = {
  response: CreateTermsAndConditionsDocumentDialogMutation$data;
  variables: CreateTermsAndConditionsDocumentDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "TermsAndConditionsDocument",
    "kind": "LinkedField",
    "name": "createTermsAndConditionsDocument",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "code",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "startDate",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateTermsAndConditionsDocumentDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateTermsAndConditionsDocumentDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b069d4822bd5728fd07075c2c942a6e7",
    "id": null,
    "metadata": {},
    "name": "CreateTermsAndConditionsDocumentDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CreateTermsAndConditionsDocumentDialogMutation(\n  $input: CreateTermsAndConditionsDocumentInput!\n) {\n  createTermsAndConditionsDocument(input: $input) {\n    id\n    code\n    title\n    version\n    startDate\n  }\n}\n"
  }
};
})();

(node as any).hash = "7307fd2dfc8ff8bf4842771eea514210";

export default node;
