/**
 * @generated SignedSource<<2a0a841b7e4ba7056ebf0c090fff82b7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateInvoicePurchaseOrderReferenceInput = {
  id: string;
  purchaseOrderReference: string;
};
export type DraftInvoiceViewUpdatePurchaseOrderReferenceMutation$variables = {
  input: UpdateInvoicePurchaseOrderReferenceInput;
};
export type DraftInvoiceViewUpdatePurchaseOrderReferenceMutation$data = {
  readonly updateInvoicePurchaseOrderReference: {
    readonly id: string;
    readonly purchaseOrderReference: string | null;
  };
};
export type DraftInvoiceViewUpdatePurchaseOrderReferenceMutation = {
  response: DraftInvoiceViewUpdatePurchaseOrderReferenceMutation$data;
  variables: DraftInvoiceViewUpdatePurchaseOrderReferenceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "updateInvoicePurchaseOrderReference",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "purchaseOrderReference",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftInvoiceViewUpdatePurchaseOrderReferenceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftInvoiceViewUpdatePurchaseOrderReferenceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1016f8f2e34177c7307fee5763c34d4a",
    "id": null,
    "metadata": {},
    "name": "DraftInvoiceViewUpdatePurchaseOrderReferenceMutation",
    "operationKind": "mutation",
    "text": "mutation DraftInvoiceViewUpdatePurchaseOrderReferenceMutation(\n  $input: UpdateInvoicePurchaseOrderReferenceInput!\n) {\n  updateInvoicePurchaseOrderReference(input: $input) {\n    id\n    purchaseOrderReference\n  }\n}\n"
  }
};
})();

(node as any).hash = "f76a3de79fe5461c193c2b680c743846";

export default node;
