/**
 * @generated SignedSource<<d7db6e3952878a651c20843887a73511>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type RenameProjectInput = {
  id: string;
  projectName: string;
};
export type ProjectDetailsViewRenameProjectMutation$variables = {
  input: RenameProjectInput;
};
export type ProjectDetailsViewRenameProjectMutation$data = {
  readonly renameProject: {
    readonly id: string;
    readonly projectName: string;
  };
};
export type ProjectDetailsViewRenameProjectMutation = {
  response: ProjectDetailsViewRenameProjectMutation$data;
  variables: ProjectDetailsViewRenameProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "renameProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "projectName",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectDetailsViewRenameProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectDetailsViewRenameProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f368e154eb08ec135d6aff07fce2d1d4",
    "id": null,
    "metadata": {},
    "name": "ProjectDetailsViewRenameProjectMutation",
    "operationKind": "mutation",
    "text": "mutation ProjectDetailsViewRenameProjectMutation(\n  $input: RenameProjectInput!\n) {\n  renameProject(input: $input) {\n    id\n    projectName\n  }\n}\n"
  }
};
})();

(node as any).hash = "493c9281cad73d7308ff4576d5499d07";

export default node;
