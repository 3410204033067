/**
 * @generated SignedSource<<d56f251c0b02486bb3460482baa506b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateInvoiceTimeOfPerformanceInput = {
  id: string;
  timeOfPerformanceEnd: any;
  timeOfPerformanceStart: any;
};
export type DraftInvoiceViewUpdateTimeOfPerformanceMutation$variables = {
  input: UpdateInvoiceTimeOfPerformanceInput;
};
export type DraftInvoiceViewUpdateTimeOfPerformanceMutation$data = {
  readonly updateInvoiceTimeOfPerformance: {
    readonly id: string;
    readonly timeOfPerformanceEnd: any;
    readonly timeOfPerformanceStart: any;
  };
};
export type DraftInvoiceViewUpdateTimeOfPerformanceMutation = {
  response: DraftInvoiceViewUpdateTimeOfPerformanceMutation$data;
  variables: DraftInvoiceViewUpdateTimeOfPerformanceMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Invoice",
    "kind": "LinkedField",
    "name": "updateInvoiceTimeOfPerformance",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeOfPerformanceStart",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "timeOfPerformanceEnd",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DraftInvoiceViewUpdateTimeOfPerformanceMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DraftInvoiceViewUpdateTimeOfPerformanceMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ac929e654274bbc8d341e79d4ac2ecab",
    "id": null,
    "metadata": {},
    "name": "DraftInvoiceViewUpdateTimeOfPerformanceMutation",
    "operationKind": "mutation",
    "text": "mutation DraftInvoiceViewUpdateTimeOfPerformanceMutation(\n  $input: UpdateInvoiceTimeOfPerformanceInput!\n) {\n  updateInvoiceTimeOfPerformance(input: $input) {\n    id\n    timeOfPerformanceStart\n    timeOfPerformanceEnd\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8245ded8b5ff41b289e9119e7b4b4d5";

export default node;
