import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {graphql} from "react-relay";
import {useFragment} from "react-relay/hooks";
import {faCcMastercard, faCcVisa} from "@fortawesome/free-brands-svg-icons";
import {faBank, faCreditCard, faFileInvoice, faSquareQuestion, faStar, faTrashCan} from "@fortawesome/pro-regular-svg-icons";
import ActionButton from "./presentational/button/ActionButton";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import DeletePaymentMethodDialog from "./dialog/DeletePaymentMethodDialog";
import SetDefaultPaymentMethodDialog from "./dialog/SetDefaultPaymentMethodDialog";

type Props = {
    queryRef: any
    typeNameFilter?: string
}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type DetailedPaymentMethodListProps = Props & ConnectedProps<typeof connector>;

const DetailedPaymentMethodList: React.FC<DetailedPaymentMethodListProps> = (props) => {

    const {dispatchShowModal, dispatchHideModal} = props;
    const customerAccount = useFragment(
        graphql`
            fragment DetailedPaymentMethodList_customerAccount on CustomerAccount {
                id
                defaultPaymentMethod {
                    id
                }
                paymentMethods {
                    id
                    paymentMethodType
                    cardBrand
                    lastFourDigits
                    expirationDate
                    paymentTerms
                    ...SetDefaultPaymentMethodDialog_paymentMethod
                    ...DeletePaymentMethodDialog_paymentMethod
                }
                ...SetDefaultPaymentMethodDialog_customerAccount
            }
        `,
        props.queryRef
    );

    const showSetDefaultPaymentMethodDialog = (paymentMethod) => {
        dispatchShowModal({
            body: (<SetDefaultPaymentMethodDialog customerAccount={customerAccount} paymentMethod={paymentMethod} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const showDeletePaymentMethodDialog = (paymentMethod) => {
        dispatchShowModal({
            body: (<DeletePaymentMethodDialog paymentMethod={paymentMethod} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <>
            <table className="min-w-full divide-y divide-gray-600">
                <thead>
                    <tr>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-8"
                        >
                            Type
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Number
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Details
                        </th>
                        <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                        >
                            Status
                        </th>
                        <th scope="col" className="relative px-6 py-3">
                            <span className="sr-only">Edit</span>
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {customerAccount.paymentMethods.map((paymentMethod, index) => {
                        if (!paymentMethod) {
                            return (<></>);
                        }

                        let paymentMethodType;
                        let paymentMethodIcon;
                        let paymentTerms;

                        switch (paymentMethod.paymentMethodType) {
                            case 'Card': {
                                switch (paymentMethod.cardBrand) {
                                    case 'Visa':
                                        paymentMethodType = 'Visa credit card';
                                        paymentMethodIcon = faCcVisa;
                                        break;
                                    case 'Mastercard':
                                        paymentMethodType = 'Mastercard credit card';
                                        paymentMethodIcon = faCcMastercard;
                                        break;
                                    default:
                                        paymentMethodType = 'Credit card';
                                        paymentMethodIcon = faCreditCard;
                                        break;
                                }
                                break;
                            }
                            case 'SepaDebit': {
                                paymentMethodType = 'SEPA direct debit';
                                paymentMethodIcon = faBank
                                break;
                            }
                            case 'OnAccount': {
                                paymentMethodType = 'On account';
                                paymentMethodIcon = faFileInvoice

                                switch (paymentMethod.paymentTerms) {
                                    case 'UponReceipt': {
                                        paymentTerms = 'Due net, upon receipt';
                                        break;
                                    }
                                    case 'Net7': {
                                        paymentTerms = 'Due net, 7 days';
                                        break;
                                    }
                                    case 'Net15': {
                                        paymentTerms = 'Due net, 15 days';
                                        break;
                                    }
                                    case 'Net30': {
                                        paymentTerms = 'Due net, 30 days';
                                        break;
                                    }
                                    case 'Net60': {
                                        paymentTerms = 'Due net, 60 days';
                                        break;
                                    }
                                }
                                break;
                            }
                            default: {
                                paymentMethodType = 'Unknown';
                                paymentMethodIcon = faSquareQuestion
                            }
                        }

                        return (
                            <tr key={index}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900">
                                        {paymentMethodIcon && <FontAwesomeIcon icon={paymentMethodIcon} className="h-6 w-6"/>}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="text-sm text-gray-900" title={paymentMethod.id}>
                                        {paymentMethodType}
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {paymentMethod.lastFourDigits && (
                                        <div className="text-sm text-gray-900">•••• {paymentMethod.lastFourDigits}</div>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    {paymentMethod.paymentMethodType === "Card" && (
                                        <div className="text-sm text-gray-900">
                                            {paymentMethod.expirationDate}
                                        </div>
                                    )}
                                    {paymentMethod.paymentMethodType === "OnAccount" && (
                                        <div className="text-sm text-gray-900">
                                            {paymentTerms}
                                        </div>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                    {customerAccount.defaultPaymentMethod?.id === paymentMethod.id && (
                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Default</span>
                                    )}
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    {customerAccount.defaultPaymentMethod?.id !== paymentMethod.id && (
                                        <ActionButton onClick={() => {
                                            showSetDefaultPaymentMethodDialog(paymentMethod)
                                        }} icon={faStar}/>
                                    )}
                                    <ActionButton onClick={() => {
                                        showDeletePaymentMethodDialog(paymentMethod)
                                    }} icon={faTrashCan}/>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </>
    )
}

export default connector(DetailedPaymentMethodList)
