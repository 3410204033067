/**
 * @generated SignedSource<<73a8409a1248ff8ceca410602d93cd0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteSettlementDialog_settlement$data = {
  readonly cutOffAt: any;
  readonly id: string;
  readonly title: {
    readonly en: string;
  };
  readonly " $fragmentType": "DeleteSettlementDialog_settlement";
};
export type DeleteSettlementDialog_settlement$key = {
  readonly " $data"?: DeleteSettlementDialog_settlement$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteSettlementDialog_settlement">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteSettlementDialog_settlement",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TranslatedString",
      "kind": "LinkedField",
      "name": "title",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "en",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "cutOffAt",
      "storageKey": null
    }
  ],
  "type": "Settlement",
  "abstractKey": null
};

(node as any).hash = "b150f8e1aa35c333668e909973be74dc";

export default node;
