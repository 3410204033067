import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-regular-svg-icons";

type ListSearchProps = {
    handleSearchTermChange: ((searchTerm: string) => void)
    placeholder: string
}

const ListSearch: React.FC<ListSearchProps> = ({handleSearchTermChange, placeholder}) => {
    return (
        <form className="mt-6 flex space-x-4" action="#">
            <div className="flex-1 min-w-0">
                <label htmlFor="search" className="sr-only">Search</label>
                <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FontAwesomeIcon icon={faSearch} className="h-5 w-5 text-gray-400" aria-hidden="true"/>
                    </div>
                    <input autoFocus={true} type="search"
                           className="focus:ring-red-500 focus:border-red-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                           placeholder={placeholder} onChange={e => handleSearchTermChange(e.target.value)}/>
                </div>
            </div>
        </form>
    );
}

export default ListSearch;
