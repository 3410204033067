/**
 * @generated SignedSource<<06d8a1de38059b54087fca7263a0f30e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteProjectDialog_project$data = {
  readonly id: string;
  readonly projectName: string;
  readonly projectNumber: string | null;
  readonly " $fragmentType": "DeleteProjectDialog_project";
};
export type DeleteProjectDialog_project$key = {
  readonly " $data"?: DeleteProjectDialog_project$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteProjectDialog_project">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteProjectDialog_project",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "projectNumber",
      "storageKey": null
    }
  ],
  "type": "Project",
  "abstractKey": null
};

(node as any).hash = "8ebc63a6156bb7749328a2c714911d37";

export default node;
