import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";

type InitiateInvoicePaymentDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const InitiateInvoicePaymentDialog: React.FC<InitiateInvoicePaymentDialogProps> = (props) => {

    const invoice = useFragment(
        graphql`
            fragment InitiateInvoicePaymentDialog_invoice on Invoice {
                id
                invoiceNumber
                paymentMethod {
                    paymentMethodType
                    cardBrand
                    lastFourDigits
                }
                sentAt
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation InitiateInvoicePaymentDialogMutation(
                        $input: InitiateInvoicePaymentInput!
                    ) {
                        initiateInvoicePayment(input: $input) {
                            id
                            updatedAt
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        invoiceIdentifier: invoice.id
    }

    const currentPaymentMethodDescription = invoice.paymentMethod.paymentMethodType === 'SepaDebit' ?
        'SEPA Direct Debit drawing from bank account ending with ' + invoice.paymentMethod.lastFourDigits
        : invoice.paymentMethod.paymentMethodType === 'Card' ?
            invoice.paymentMethod.cardBrand + ' card ending with ' + invoice.paymentMethod.lastFourDigits
            : invoice.paymentMethod.paymentMethodType

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Initiate Payment" submitLabel="Initiate Payment">
            <Section>
                <Row>
                    <div className="text-gray-900">
                        <p>Trigger automatic payment of invoice {invoice.invoiceNumber} using {currentPaymentMethodDescription}?</p>
                    </div>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default InitiateInvoicePaymentDialog;
