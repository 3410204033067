import * as React from "react";
import {useContext, useState} from "react";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useRelayEnvironment} from "react-relay/hooks";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import CabanaConfigurationContext from "../../configuration/CabanaConfigurationContext";
import {loadStripe} from "@stripe/stripe-js";
import {commitMutation, graphql} from "react-relay";
import {SetUpStripePaymentMethodDialogCreateSetupIntentMutation} from "../../__relay_artifacts__/SetUpStripePaymentMethodDialogCreateSetupIntentMutation.graphql";
import {useNavigate} from "react-router-dom";
import {faSpinnerThird} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type SetUpStripePaymentMethodDialogProps = {
    customerAccountIdentifier: string
    handleCloseDialog: () => {}
}

const SetUpStripePaymentMethodForm: React.FC<SetUpStripePaymentMethodDialogProps> = (props) => {
    const {customerAccountIdentifier, handleCloseDialog} = props;
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const handleSubmission = async (input) => {
        if (!stripe || !elements) {
            return;
        }
        const result = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: window.location.href
            },
            redirect: 'if_required'
        });

        if (result.error) {
            // Show error to your customer (e.g., payment details incomplete)
            console.log('stripe.confirmPayment', result.error.message);
        } else {
            handleCloseDialog();
            navigate('/customers/' + customerAccountIdentifier + '/payment')
        }
    }

    const initialValues = {}
    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Set Up Payment Method" submitLabel="Submit">
            <Section>
                <Row>
                    <div className="min-h-96">
                        <PaymentElement/>
                    </div>
                </Row>
            </Section>
        </FormDialog>
    )
}

const SetUpStripePaymentMethodDialog: React.FC<SetUpStripePaymentMethodDialogProps> = (props) => {
    const {customerAccountIdentifier, handleCloseDialog} = props;
    const relayEnvironment = useRelayEnvironment();
    const cabanaConfiguration = useContext(CabanaConfigurationContext);

    const [paymentMethodSetupIntent, setPaymentMethodSetupIntent] = useState({id: '', clientSecret: ''});

    if (paymentMethodSetupIntent.id === '') {
        commitMutation<SetUpStripePaymentMethodDialogCreateSetupIntentMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation SetUpStripePaymentMethodDialogCreateSetupIntentMutation(
                        $input: CreatePaymentMethodSetupIntentInput!
                    ) {
                        createPaymentMethodSetupIntent(input: $input) {
                            id
                            clientSecret
                        }
                    }
                `,
                variables: {
                    input: {
                        customerAccountIdentifier: customerAccountIdentifier
                    }
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    } else {
                        setPaymentMethodSetupIntent(response.createPaymentMethodSetupIntent);
                    }
                },
                onError: (error) => {
                    // FIXME: Display error
                    handleCloseDialog();
                }
            }
        );
    }

    const stripe = loadStripe(cabanaConfiguration.stripe.publishableKey);
    const stripeElementsOptions = {
        clientSecret: paymentMethodSetupIntent.clientSecret
    }

    if (paymentMethodSetupIntent.clientSecret === '') {
        return (
            <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={() => {
            }} initialValues={{}} headline="Set Up Payment Method" submitLabel="Submit">
                <Section>
                    <Row>
                        <div className='min-h-96 text-center align-middle'>
                            <FontAwesomeIcon icon={faSpinnerThird} spin className="h-10 w-10 mt-40"/>
                        </div>
                    </Row>
                </Section>
            </FormDialog>
        );
    }

    return (
        <Elements stripe={stripe} options={stripeElementsOptions}>
            <SetUpStripePaymentMethodForm customerAccountIdentifier={customerAccountIdentifier} handleCloseDialog={handleCloseDialog}/>
        </Elements>
    )
}

export default SetUpStripePaymentMethodDialog;
