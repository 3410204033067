import * as React from "react";
import {useState} from "react";
import {commitMutation, graphql} from "react-relay";
import Panel from "./presentational/Panel";
import BreadcrumbNavigation from "./navigation/BreadcrumbNavigation";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import InvoiceHeader from "./InvoiceHeader";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import InvoiceLineItemsTable from "./InvoiceLineItemsTable";
import {countries} from "../Countries";
import {languages} from "../Languages";
import DataListItem from "./presentational/DataListItem";
import {FormattedDate, FormattedDateTimeRange} from "react-intl";
import {formatISO, parseISO} from "date-fns";
import EditableDataListField, {FieldDataType} from "./form/EditableDataListField";
import EditableDataListItem from "./form/EditableDataListItem";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload, faPaperclip, faTrashCan} from "@fortawesome/pro-regular-svg-icons";
import Dropzone from "react-dropzone";
import {faUpload} from "@fortawesome/pro-light-svg-icons";
import {DraftInvoiceViewDownloadInvoiceAttachmentMutation} from "../__relay_artifacts__/DraftInvoiceViewDownloadInvoiceAttachmentMutation.graphql";
import ActionButton from "./presentational/button/ActionButton";
import {Navigate, useOutletContext} from "react-router";
import {DraftInvoiceViewDownloadPdfMutation} from "../__relay_artifacts__/DraftInvoiceViewDownloadPdfMutation.graphql";
import {faFilePdf} from "@fortawesome/pro-solid-svg-icons";
import FinalizeInvoiceDialog from "./dialog/FinalizeInvoiceDialog";

type DraftInvoiceViewParams = {};

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type DraftInvoiceViewProps = DraftInvoiceViewParams & ConnectedProps<typeof connector>;

const handleUpdateTimeOfPerformance = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation DraftInvoiceViewUpdateTimeOfPerformanceMutation($input: UpdateInvoiceTimeOfPerformanceInput!) {
                    updateInvoiceTimeOfPerformance(input: $input) {
                        id
                        timeOfPerformanceStart
                        timeOfPerformanceEnd
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdatePurchaseOrderReference = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation DraftInvoiceViewUpdatePurchaseOrderReferenceMutation($input: UpdateInvoicePurchaseOrderReferenceInput!) {
                    updateInvoicePurchaseOrderReference(input: $input) {
                        id
                        purchaseOrderReference
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleUpdateCustomerOrderNotes = (input, setEditingActive, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation DraftInvoiceViewUpdateCustomerOrderNotesMutation($input: UpdateInvoiceCustomerOrderNotesInput!) {
                    updateInvoiceCustomerOrderNotes(input: $input) {
                        id
                        customerOrderNotes
                    }
                }
            `,
            variables: {
                input: input
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setEditingActive(false);
            },
            onError: (error) => {
                console.error(error);
                setEditingActive(false);
            }
        }
    )
}

const handleAttachmentUploads = (acceptedFiles: File[], invoiceIdentifier: string, relayEnvironment) => {
    acceptedFiles.forEach((file, index) => {
        const fileReader = new FileReader();
        fileReader.onload = () => {
            if (fileReader.readyState === 2) {
                const input = {
                    id: invoiceIdentifier,
                    upload: {
                        filename: file.name,
                        data: fileReader.result,
                        lastModifiedAt: formatISO(new Date(file.lastModified))
                    }
                }
                commitMutation(
                    relayEnvironment,
                    {
                        mutation: graphql`
                            mutation DraftInvoiceViewAddInvoiceAttachmentMutation($input: UploadInvoiceAttachmentInput!) {
                                uploadInvoiceAttachment(input: $input) {
                                    id
                                    attachments {
                                        sha1
                                        filename
                                        lastModifiedAt
                                        size
                                    }
                                }
                            }
                        `,
                        variables: {
                            input: input
                        },
                        onCompleted: (response, errors) => {
                            if (errors && errors.length > 0) {
                                console.error(errors);
                            }
                        },
                        onError: (error) => {
                            console.error(error);
                        }
                    }
                )
            }
        };
        fileReader.readAsDataURL(file);
    })
}

const handleDownloadAttachment = (invoiceIdentifier: string, sha1: string, relayEnvironment) => {
    commitMutation<DraftInvoiceViewDownloadInvoiceAttachmentMutation>(
        relayEnvironment,
        {
            mutation: graphql`
                mutation DraftInvoiceViewDownloadInvoiceAttachmentMutation($input: DownloadInvoiceAttachmentInput!) {
                    downloadInvoiceAttachment(input: $input) {
                        uri
                        filename
                    }
                }
            `,
            variables: {
                input: {
                    id: invoiceIdentifier,
                    sha1: sha1
                }
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                if (response.downloadInvoiceAttachment?.uri) {
                    window.location.href = response.downloadInvoiceAttachment?.uri;
                }
            },
            onError: (error) => {
                console.error(error);
            }
        }
    )
}

const handleRemoveAttachment = (invoiceIdentifier: string, sha1: string, relayEnvironment) => {
    commitMutation(
        relayEnvironment,
        {
            mutation: graphql`
                mutation DraftInvoiceViewRemoveInvoiceAttachmentMutation($input: RemoveInvoiceAttachmentInput!) {
                    removeInvoiceAttachment(input: $input) {
                        id
                        attachments {
                            sha1
                            filename
                            lastModifiedAt
                            size
                        }
                    }
                }
            `,
            variables: {
                input: {
                    id: invoiceIdentifier,
                    sha1: sha1
                }
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
            },
            onError: (error) => {
                console.error(error);
            }
        }
    )
}

const handleDownloadPdf = (invoiceIdentifier: string, setIsDownloadingPdf, relayEnvironment) => {
    setIsDownloadingPdf(true);
    commitMutation<DraftInvoiceViewDownloadPdfMutation>(
        relayEnvironment,
        {
            mutation: graphql`
                mutation DraftInvoiceViewDownloadPdfMutation(
                    $id: ID!
                ) {
                    downloadInvoicePdf(
                        input: {
                            id: $id
                        }
                    ) {
                        uri
                        filename
                    }
                }
            `,
            variables: {
                id: invoiceIdentifier
            },
            onCompleted: (response, errors) => {
                if (errors && errors.length > 0) {
                    console.error(errors);
                }
                setIsDownloadingPdf(false);
                if (response.downloadInvoicePdf?.uri) {
                    window.location.href = response.downloadInvoicePdf?.uri;
                }
            },
            onError: (error) => {
                setIsDownloadingPdf(false);
                console.error(error);
            }
        }
    )
}

const DraftInvoiceView: React.FC<DraftInvoiceViewProps> = (props) => {
    const {dispatchShowModal, dispatchHideModal} = props
    const relayEnvironment = useRelayEnvironment();
    const outletContext: any = useOutletContext();
    const {queryRef} = outletContext;

    const [isDownloadingPdf, setIsDownloadingPdf] = useState(false);

    const invoice = useFragment(
        graphql`
            fragment DraftInvoiceView_invoice on Invoice {
                ...InvoiceHeader_invoice
                ...InvoiceLineItemsTable_invoice
                ...FinalizeInvoiceDialog_invoice
                id
                type
                state
                invoiceNumber
                invoiceDate
                timeOfPerformanceStart
                timeOfPerformanceEnd
                language
                taxType
                taxTypeNote
                customerAccount {
                    customerNumber
                    vatId
                    taxStatus
                }
                address {
                    businessName
                    addressLine1
                    addressLine2
                    postalCode
                    city
                    country
                }
                paymentNote
                paymentMethod {
                    id
                    paymentMethodType
                    paymentServiceType
                    paymentTerms
                    cardBrand
                    expirationDate
                    lastFourDigits
                }
                purchaseOrderReference
                customerOrderNotes
                attachments {
                    sha1
                    filename
                    size
                }
                relatedInvoice {
                    id
                    invoiceNumber
                }
            }
        `,
        queryRef
    );

    if (invoice == null) {
        return <></>;
    }

    const breadcrumbNavigationItems = [
        {label: 'Invoices', to: '/invoices'},
        {label: invoice.id, to: '/invoices/' + invoice.id + '/edit'},
    ]

    if (invoice.state !== 'DRAFT') {
        return (
            <Navigate to={'/invoices/' + invoice.id}/>
        )
    }

    const showFinalizeInvoiceDialog = () => {
        dispatchShowModal({
            body: (<FinalizeInvoiceDialog handleCloseDialog={dispatchHideModal} queryRef={invoice}/>)
        })
    };

    return (
        <article>
            <BreadcrumbNavigation items={breadcrumbNavigationItems}/>
            <InvoiceHeader invoice={invoice}/>
            <div className="max-w-5xl mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8 py-8">
                <Panel buttons={
                    <>
                        <ActionButton icon={faFilePdf} iconSize={"lg"} isSubmitting={isDownloadingPdf} title="Download PDF" onClick={() => {
                            handleDownloadPdf(invoice.id, setIsDownloadingPdf, relayEnvironment)
                        }
                        }/>
                        <ActionButton label="Finalize..." onClick={() => {
                            showFinalizeInvoiceDialog();
                        }}/>
                    </>
                }>
                    <div className="flex mb-4 justify-between">
                        <div className="w-96 h-48 bg-gray-50 p-4 border border-gray-200">
                            <span className="font-bold">{invoice.address.businessName}</span><br/>
                            {invoice.address.addressLine1}<br/>
                            {invoice.address.addressLine2 && (<>{invoice.address.addressLine2}<br/></>)}
                            <br/>
                            {invoice.address.postalCode} {invoice.address.city}<br/>
                            {countries[invoice.address.country]}
                        </div>
                        <div className="px-4">
                            <dl className="py-2">
                                <DataListItem label="Document language" condensed={true}>{languages[invoice.language]}</DataListItem>
                            </dl>
                            <dl className="py-2">
                                <DataListItem label="Invoice number" condensed={true}>{invoice.invoiceNumber ?? '-'}</DataListItem>
                                <DataListItem label="Invoice date" condensed={true}><FormattedDate value={invoice.invoiceDate}/></DataListItem>
                                {invoice.relatedInvoice !== null && (<DataListItem label="Related invoice" condensed={true}>{invoice.relatedInvoice.invoiceNumber ?? '-'}</DataListItem>)}
                                <DataListItem label="Customer number" condensed={true}>{invoice.customerAccount.customerNumber}</DataListItem>
                                <DataListItem label="Customer VAT ID" condensed={true}>{invoice.customerAccount.vatId ?? '-'}</DataListItem>
                            </dl>
                            <dl className="py-2">
                                <EditableDataListItem label="Period of performance" fields={(
                                    <>
                                        <EditableDataListField fieldDataType={FieldDataType.date} name="timeOfPerformanceStart"/>
                                        <EditableDataListField fieldDataType={FieldDataType.date} name="timeOfPerformanceEnd"/>
                                    </>
                                )}
                                                      initialValues={{
                                                          id: invoice.id,
                                                          timeOfPerformanceStart: invoice.timeOfPerformanceStart,
                                                          timeOfPerformanceEnd: invoice.timeOfPerformanceEnd
                                                      }}
                                                      handleSubmission={(values, setEditingActive) => handleUpdateTimeOfPerformance(values, setEditingActive, relayEnvironment)}
                                >
                                    <FormattedDateTimeRange from={parseISO(invoice.timeOfPerformanceStart)} to={parseISO(invoice.timeOfPerformanceEnd)}/>
                                </EditableDataListItem>
                                <EditableDataListItem label="PO reference" fields={(
                                    <EditableDataListField fieldDataType={FieldDataType.text} name="purchaseOrderReference"/>
                                )} initialValues={{
                                    id: invoice.id,
                                    purchaseOrderReference: invoice.purchaseOrderReference
                                }} handleSubmission={(values, setEditingActive) => handleUpdatePurchaseOrderReference(values, setEditingActive, relayEnvironment)}>
                                    {invoice.purchaseOrderReference}
                                </EditableDataListItem>
                                <EditableDataListItem label="Customer order notes" fields={(
                                    <EditableDataListField fieldDataType={FieldDataType.text} name="customerOrderNotes"/>
                                )} initialValues={{
                                    id: invoice.id,
                                    customerOrderNotes: invoice.customerOrderNotes
                                }} handleSubmission={(values, setEditingActive) => handleUpdateCustomerOrderNotes(values, setEditingActive, relayEnvironment)}>
                                    {invoice.customerOrderNotes}
                                </EditableDataListItem>
                            </dl>
                        </div>
                    </div>
                </Panel>
                <Panel>
                    <InvoiceLineItemsTable queryRef={invoice}/>
                </Panel>

                <Panel headline="Notes">
                    {invoice.paymentNote && (
                        <p className="mt-1 text-sm text-gray-900">{invoice.paymentNote}</p>
                    )}
                    <dl className="py-2">
                        {invoice.taxTypeNote && (
                            <p className="mt-1 text-sm text-gray-900">{invoice.taxTypeNote}</p>
                        )}
                    </dl>
                </Panel>

                <Panel headline="Attachments">
                    <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                        {invoice.attachments.map((attachment) => {
                            let isSubmitting = false
                            return (
                                <li
                                    key={attachment.sha1}
                                    className="flex items-center justify-between py-3 pl-3 pr-4 text-sm group"
                                >
                                    <div className="flex w-0 flex-1 items-center">
                                        <FontAwesomeIcon className="h-5 w-5 flex-shrink-0 text-gray-400" icon={faPaperclip}/>
                                        <span className="ml-2 w-0 flex-1 truncate">{attachment.filename}</span>
                                    </div>
                                    <div className="ml-4 flex-shrink-0">
                                        <button type="button" tabIndex={-1} onClick={(event) => handleDownloadAttachment(invoice.id, attachment.sha1, relayEnvironment)} className="py-3 px-4 text-sm font-medium text-gray-700 invisible group-hover:visible">
                                            <FontAwesomeIcon icon={faDownload}/>
                                        </button>
                                        <button type="button" tabIndex={-1} disabled={isSubmitting} onClick={(event) => handleRemoveAttachment(invoice.id, attachment.sha1, relayEnvironment)} className="py-3 px-4 text-sm font-medium text-gray-700 invisible group-hover:visible">
                                            <FontAwesomeIcon icon={faTrashCan}/>
                                        </button>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>
                    <Dropzone
                        accept={{
                            'application/pdf': ['.pdf']
                        }}
                        onDrop={acceptedFiles => handleAttachmentUploads(acceptedFiles, invoice.id, relayEnvironment)}
                    >
                        {({getRootProps, getInputProps}) => (
                            <div className="flex w-full justify-center rounded-md border-2 border-dashed border-gray-300 mt-8 px-6 pt-5 pb-6" {...getRootProps()}>
                                <div className="space-y-1 text-center">
                                    <FontAwesomeIcon className="mx-auto h-12 w-12 text-gray-400" icon={faUpload}/>
                                    <div className="flex text-sm text-gray-600">
                                        <label htmlFor="file-upload" className="relative cursor-pointer rounded-md bg-white font-medium text-grey-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-red-500 focus-within:ring-offset-2 hover:text-grey-500"
                                        >
                                            <span>Upload attachments</span>
                                            <input {...getInputProps()} />
                                        </label>
                                        <p className="pl-1">or drag and drop</p>
                                    </div>
                                    <p className="text-xs text-gray-500">PDF up to 10MB</p>
                                </div>
                            </div>
                        )}
                    </Dropzone>
                </Panel>
            </div>
        </article>
    );
}

export default connector(DraftInvoiceView);
