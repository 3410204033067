import * as React from "react";
import {formatISO, parseISO} from "date-fns";
import {FieldAttributes} from "formik/dist/Field";
import {Field, useFormikContext} from "formik";

type DateFieldProps = {}

// TODO: Implement time zone support, so that the user does not see the
//       time zone, but the correct (company) time zone is used
const DateField: React.FC<FieldAttributes<DateFieldProps>> = (props) => {
    const {value, ...attributes} = props
    const form = useFormikContext()

    let preparedValue = value ?? form.getFieldProps(props.name).value ?? formatISO(new Date())

    const date = parseISO(preparedValue)

    if (!isNaN(date.valueOf())) {
        preparedValue = formatISO(date)
    }

    const handleOnBlur = ((event) => {
        const updatedValue = event.currentTarget.value
        if (!updatedValue) {
            form.setFieldValue(event.currentTarget.name, null)
        } else {
            form.setFieldValue(event.currentTarget.name, updatedValue)
        }
    })

    return (
        <Field value={preparedValue} type="text" onBlur={handleOnBlur} className="text-right" {...attributes} />
    );
}

export default DateField;
