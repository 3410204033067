import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Field from "../form/Field";
import Row from "../form/Row";
import Label from "../form/Label";
import * as yup from "yup";
import FormDialog from "../dialog/FormDialog";
import type {CreateTermsAndConditionsDocumentDialogMutation} from "../../__relay_artifacts__/CreateTermsAndConditionsDocumentDialogMutation.graphql";
import {useRelayEnvironment} from "react-relay/hooks";
import Checkbox from "../form/Checkbox";

type Props = {
    handleCloseDialog: () => {}
}

const CreateTermsAndConditionsDocumentDialog: React.FC<Props> = ({handleCloseDialog}) => {
    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation<CreateTermsAndConditionsDocumentDialogMutation>(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation CreateTermsAndConditionsDocumentDialogMutation(
                        $input: CreateTermsAndConditionsDocumentInput!
                    ) {
                        createTermsAndConditionsDocument(input: $input) {
                            id
                            code
                            title
                            version
                            startDate
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    handleCloseDialog();
                }
            }
        )
    }
    const validationSchema = yup.object().shape({
        title: yup.string()
            .min(2, 'This title is too short')
            .max(255, 'This title is too long')
            .required('This field is required'),
        code: yup.string()
            .matches(/^[a-z0-9-]{2,255}$/, 'Invalid document code')
            .required('This field is required'),
        version: yup.string()
            .matches(/^[a-zA-Z0-9,+~. -]{2,20}$/, 'Invalid version')
            .required('This field is required'),
        uri: yup.string()
            .matches(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/, 'Invalid URI')
            .required('This field is required'),
        startDate: yup.string()
            .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/, 'Invalid date')
            .required('This field is required'),
        publishDate: yup.string()
            .matches(/^[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}:[0-9]{2}$/, 'Invalid date'),
        published: yup.bool()
    });

    const initialValues = {
        published: false
    }

    return (
        <FormDialog handleCloseDialog={handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} validationSchema={validationSchema} headline="Create Terms & Conditions" submitLabel="Create">
            <Section>
                <Row>
                    <Label>Code</Label>
                    <Field name="code" type="text" placeholder="tos-general" autoFocus={true}/>
                </Row>
                <Row>
                    <Label>Title</Label>
                    <Field name="title" type="text" placeholder="General Terms of Service"/>
                </Row>
                <Row>
                    <Label>Version</Label>
                    <Field name="version" type="text" placeholder="v1.2.3"/>
                </Row>
                <Row>
                    <Label>URI</Label>
                    <Field name="uri" type="text" placeholder="https://example.com"/>
                </Row>
                <Row>
                    <Label>Start date</Label>
                    <Field name="startDate" type="text" placeholder="YYYY-MM-DD HH:MM:SS"/>
                </Row>
                <Row>
                    <Label>Publish date</Label>
                    <Field name="publishDate" type="text" placeholder="YYYY-MM-DD HH:MM:SS"/>
                </Row>
                <Row>
                    <Label>Published</Label>
                    <Checkbox name="published"/>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default CreateTermsAndConditionsDocumentDialog;
