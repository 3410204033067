/**
 * @generated SignedSource<<833b4989f8fd36e12591204288ce7369>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ConfigurationViewQuery$variables = {};
export type ConfigurationViewQuery$data = {
  readonly customerAccounts: {
    readonly totalCount: number;
  } | null;
  readonly termsAndConditionsDocuments: {
    readonly totalCount: number;
  } | null;
};
export type ConfigurationViewQuery = {
  response: ConfigurationViewQuery$data;
  variables: ConfigurationViewQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "totalCount",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CustomerAccountConnection",
    "kind": "LinkedField",
    "name": "customerAccounts",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "TermsAndConditionsDocumentConnection",
    "kind": "LinkedField",
    "name": "termsAndConditionsDocuments",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConfigurationViewQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConfigurationViewQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f02384f5e7aae1d2377f98dc878b50d0",
    "id": null,
    "metadata": {},
    "name": "ConfigurationViewQuery",
    "operationKind": "query",
    "text": "query ConfigurationViewQuery {\n  customerAccounts {\n    totalCount\n  }\n  termsAndConditionsDocuments {\n    totalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "b7f6c90da64d673b619dc8754c5f7db9";

export default node;
