/**
 * @generated SignedSource<<266b0dca225f284882500169964b59a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UpdateContactRolesInput = {
  contactIdentifier: string;
  contactRoles?: ReadonlyArray<ContactRoleInput> | null;
  customerAccountIdentifier: string;
};
export type ContactRoleInput = {
  roleIdentifier: string;
};
export type UpdateContactRolesDialogMutation$variables = {
  input: UpdateContactRolesInput;
};
export type UpdateContactRolesDialogMutation$data = {
  readonly updateContactRoles: {
    readonly contacts: {
      readonly edges: ReadonlyArray<{
        readonly roles: ReadonlyArray<{
          readonly label: {
            readonly en: string | null;
          };
          readonly roleIdentifier: string;
        }> | null;
      } | null> | null;
    } | null;
    readonly customerName: string;
    readonly id: string;
  };
};
export type UpdateContactRolesDialogMutation = {
  response: UpdateContactRolesDialogMutation$data;
  variables: UpdateContactRolesDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CustomerAccount",
    "kind": "LinkedField",
    "name": "updateContactRoles",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "customerName",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerAccountContactConnection",
        "kind": "LinkedField",
        "name": "contacts",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccountContactEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "CustomerAccountContactRole",
                "kind": "LinkedField",
                "name": "roles",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "roleIdentifier",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Label",
                    "kind": "LinkedField",
                    "name": "label",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "en",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateContactRolesDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateContactRolesDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1231c6faf4c650d086a515273814e63c",
    "id": null,
    "metadata": {},
    "name": "UpdateContactRolesDialogMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateContactRolesDialogMutation(\n  $input: UpdateContactRolesInput!\n) {\n  updateContactRoles(input: $input) {\n    id\n    customerName\n    contacts {\n      edges {\n        roles {\n          roleIdentifier\n          label {\n            en\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ef9f38bfbb36cd27477a83d35ba1f2f";

export default node;
