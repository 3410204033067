import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import Hint from "../presentational/Hint";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";

type DeleteCustomerDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const DeleteCustomerDialog: React.FC<DeleteCustomerDialogProps> = (props) => {

    const navigate = useNavigate();
    const customerAccount = useFragment(
        graphql`
            fragment DeleteCustomerDialog_customerAccount on CustomerAccount {
                id
                customerName
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation DeleteCustomerDialogMutation(
                        $input: DeleteCustomerAccountInput!
                    ) {
                        deleteCustomerAccount(input: $input) {
                            id @deleteRecord
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                    new Promise(resolve => setTimeout(resolve, 3000)).then(() => navigate('/customers'));
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        id: customerAccount.id
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Delete Customer Account" submitLabel="Delete" dangerous={true}>
            <Section>
                <Row>
                    <div className="text-gray-900 font-medium">
                        Really delete "{customerAccount.customerName}"?
                    </div>
                </Row>
                <Row><Hint>There are no safeguards implemented yet. Please only delete customer accounts which were never really been used.</Hint></Row>
            </Section>
        </FormDialog>
    )
}

export default DeleteCustomerDialog;
