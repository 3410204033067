import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";

type DeletePaymentMethodDialogProps = {
    paymentMethod: any,
    handleCloseDialog: () => {}
}

const DeletePaymentMethodDialog: React.FC<DeletePaymentMethodDialogProps> = (props) => {

    const paymentMethod = useFragment(
        graphql`
            fragment DeletePaymentMethodDialog_paymentMethod on PaymentMethod {
                id
                paymentMethodType
                cardBrand
                lastFourDigits
            }
        `,
        props.paymentMethod
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation DeletePaymentMethodDialogMutation(
                        $input: DetachPaymentMethodInput!
                    ) {
                        detachPaymentMethod(input: $input) {
                            id @deleteRecord
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        id: paymentMethod.id
    }

    let paymentMethodType;
    switch (paymentMethod.paymentMethodType) {
        case 'Card': {
            switch (paymentMethod.cardBrand) {
                case 'Visa':
                    paymentMethodType = 'Visa credit card';
                    break;
                case 'Mastercard':
                    paymentMethodType = 'Mastercard credit card';
                    break;
                default:
                    paymentMethodType = 'credit card';
                    break;
            }
            break;
        }
        case 'SepaDebit': {
            paymentMethodType = 'SEPA direct debit';
            break;
        }
        case 'OnAccount': {
            paymentMethodType = 'payment method on account';
            break;
        }
        default: {
            paymentMethodType = 'unknown payment method';
        }
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Delete Payment Method" submitLabel="Delete" dangerous={true}>
            <Section>
                <Row>
                    <div className="text-gray-900 font-medium">
                        Really delete {paymentMethodType}{paymentMethod.lastFourDigits ? " ending with " + paymentMethod.lastFourDigits : ""}?
                    </div>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default DeletePaymentMethodDialog;
