/**
 * @generated SignedSource<<1e696ff8a06fe35c31dffaaa3f558ce5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CreateProjectDialogCustomerAccountsQuery$variables = {
  searchTerm: string;
};
export type CreateProjectDialogCustomerAccountsQuery$data = {
  readonly customerAccounts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: "CustomerAccount";
        readonly customerName: string;
        readonly customerNumber: string;
        readonly id: string;
      } | null;
    }>;
    readonly totalCount: number;
  } | null;
};
export type CreateProjectDialogCustomerAccountsQuery = {
  response: CreateProjectDialogCustomerAccountsQuery$data;
  variables: CreateProjectDialogCustomerAccountsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 2
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      }
    ],
    "concreteType": "CustomerAccountConnection",
    "kind": "LinkedField",
    "name": "customerAccounts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerAccountEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CustomerAccount",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "customerNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateProjectDialogCustomerAccountsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateProjectDialogCustomerAccountsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8db3e60f8cb56d7542cdd53d26cb276f",
    "id": null,
    "metadata": {},
    "name": "CreateProjectDialogCustomerAccountsQuery",
    "operationKind": "query",
    "text": "query CreateProjectDialogCustomerAccountsQuery(\n  $searchTerm: String!\n) {\n  customerAccounts(searchTerm: $searchTerm, first: 2) {\n    totalCount\n    edges {\n      node {\n        __typename\n        id\n        customerName\n        customerNumber\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "827d547462d9031548ec098292d8e877";

export default node;
