import * as React from "react";
import {useState} from "react";
import Panel from "./presentational/Panel";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import {graphql} from "react-relay";
import Headline from "./presentational/Headline";
import {faPlusCircle} from "@fortawesome/pro-regular-svg-icons";
import CustomerAccountList from "./CustomerAccountList";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import ActionButton from "./presentational/button/ActionButton";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import CreateCustomerDialog from "./dialog/CreateCustomerDialog";
import CustomersAccountsQuery from "../__relay_artifacts__/CustomersViewCustomerAccountsQuery.graphql";
import ListSearch from "./presentational/ListSearch";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type Props = {
    prepared: any
};

type CustomersViewProps = ConnectedProps<typeof connector> & Props;

const CustomersView: React.FC<CustomersViewProps> = (props) => {
    const {dispatchShowModal, dispatchHideModal} = props
    const [searchTerm, setSearchTerm] = useState('');

    const customerAccounts = usePreloadedQuery(
        graphql`
            query CustomersViewCustomerAccountsQuery {
                ...CustomerAccountList_query
            }
        `,
        props.prepared.customerAccountsQuery,
    );

    const searchAPIDebounced = AwesomeDebouncePromise(
        searchTerm => {
            setSearchTerm(searchTerm)
        },
        500
    );

    const handleSearchTermChange = async (searchTerm) => {
        await searchAPIDebounced(searchTerm);
    };

    const showCreateCustomerDialog = () => {
        dispatchShowModal({
            body: (<CreateCustomerDialog handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <div className="lg:mt-6 mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <Panel>
                <div className="px-6 pt-6 pb-4">
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <Headline>Customers</Headline>
                        </div>
                        <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                            <ActionButton icon={faPlusCircle} title="Create customer" onClick={() => {
                                showCreateCustomerDialog()
                            }}/>
                        </div>
                    </div>
                    <ListSearch handleSearchTermChange={handleSearchTermChange} placeholder="Search Customers"/>
                </div>
                <CustomerAccountList queryRef={customerAccounts} searchTerm={searchTerm}/>
            </Panel>
        </div>
    )
}


export default connector(CustomersView);

function prepare_CustomersView(params, relayEnvironment) {
    return {
        customerAccountsQuery: loadQuery(
            relayEnvironment,
            CustomersAccountsQuery,
            {},
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_CustomersView};
