import React, {useState} from 'react'
import Modal from "./components/dialog/Modal";
import Navigation from "./components/Navigation";
import ProfileDropdownPanel from "./components/ProfileDropdownPanel";
import {withAuthenticationRequired} from "@auth0/auth0-react";
import {Outlet} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/pro-solid-svg-icons";
import Dinero from "dinero.js";
import {IntlProvider} from "react-intl";

const navigationItems = [
    {label: "Customers", path: "/customers"},
    {label: "Contacts", path: "/contacts"},
    {label: "Projects", path: "/projects"},
    {label: "Subscriptions", path: "/subscriptions"},
    {label: "Settlements", path: "/settlements"},
    {label: "Invoices", path: "/invoices"},
];

type AppProps = {}

const secondaryNavigationItems = [
    {label: "Configuration", path: "/configuration/contracts"},
]

const App: React.FC<AppProps> = (props) => {
    const [sidebarOpen, setSidebarOpen] = useState(false)

    // TODO: How can defaultFormats be defined for IntlProvider, so that
    //       timeZone and date format are set correctly?
    const defaultLocale = 'de-DE'

    Dinero.globalLocale = defaultLocale
    Dinero.defaultCurrency = 'EUR'

    return (
        <>
            <IntlProvider locale={defaultLocale}>
                <div className="relative h-screen flex overflow-hidden bg-gray-100">
                    <Navigation setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} navigationItems={navigationItems} secondaryNavigationItems={secondaryNavigationItems}/>
                    <div className="flex-1 overflow-auto focus:outline-none">
                        {/* could maybe become an AppHeader component */}
                        <div className="flex shrink-0 relative z-10 bg-gray-100 border-b border-gray-200  shadow-lg">
                            <button type="button" className="px-4 h-16 border-r border-gray-200 text-gray-400 lg:hidden" onClick={() => setSidebarOpen(true)}>
                                <span className="sr-only">Open sidebar</span>
                                <FontAwesomeIcon icon={faBars} className="w-4 h-4" aria-hidden="true"/>
                            </button>
                            <div className="flex-1 px-4 flex justify-between sm:px-6 max-w-5xl lg:mx-auto lg:px-8">
                                <div className="flex w-full md:ml-0">
                                </div>
                                {/*<UniversalSearch/>*/}
                                <ProfileDropdownPanel/>
                            </div>
                        </div>
                        <main className="flex-1 relative pb-8 z-0 overflow-y-auto">
                            <Outlet/>
                        </main>
                    </div>
                </div>
                <Modal/>
            </IntlProvider>
        </>
    )
}

export default withAuthenticationRequired(App);
