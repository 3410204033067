import * as React from "react";
import {useCallback} from "react";
import {graphql} from "react-relay";
import {Outlet} from "react-router";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import {loadQuery, usePreloadedQuery, useRelayEnvironment} from "react-relay/hooks";
import InvoiceViewQuery, {InvoiceViewQuery as InvoiceViewQueryType} from "../__relay_artifacts__/InvoiceViewQuery.graphql";

type InvoiceViewParams = {
    prepared: any
};

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type InvoiceViewProps = InvoiceViewParams & ConnectedProps<typeof connector>;

const InvoiceView: React.FC<InvoiceViewProps> = (props) => {
    const relayEnvironment = useRelayEnvironment();
    const {invoice} = usePreloadedQuery<InvoiceViewQueryType>(
        graphql`
            query InvoiceViewQuery($id: ID!) {
                invoice(id: $id) {
                    id
                    state
                    ...DraftInvoiceView_invoice
                    ...FinalInvoiceView_invoice
                }
            }
        `,
        props.prepared.invoiceViewQuery,
    );

    const refetch = useCallback(() => {
        loadQuery(
            relayEnvironment,
            InvoiceViewQuery,
            {
                id: invoice?.id,
            },
            {fetchPolicy: 'network-only'},
        )
    }, [
        invoice?.id,
        relayEnvironment
    ])

    if (invoice == null) {
        return <></>;
    }
    if (invoice.state === 'INVOICE_NUMBER_PENDING') {
        return <p>Assigning invoice number</p>;
    }

    return (
        <Outlet context={{
            queryRef: invoice,
            refetch: refetch
        }}/>
    );
}

export default connector(InvoiceView);

function prepare_InvoiceView(params, relayEnvironment) {
    return {
        invoiceViewQuery: loadQuery(
            relayEnvironment,
            InvoiceViewQuery,
            {
                id: params.id,
            },
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_InvoiceView};
