import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import {useNavigate} from "react-router-dom";

type DeleteInvoiceDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const DeleteInvoiceDialog: React.FC<DeleteInvoiceDialogProps> = (props) => {

    const navigate = useNavigate();
    const invoice = useFragment(
        graphql`
            fragment DeleteInvoiceDialog_invoice on Invoice {
                id
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation DeleteInvoiceDialogMutation(
                        $input: DeleteInvoiceInput!
                    ) {
                        deleteInvoice(input: $input) {
                            id @deleteRecord
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                    navigate('/invoices')
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        id: invoice.id
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Delete Invoice" submitLabel="Delete" dangerous={true}>
            <Section>
                <Row>
                    <div className="text-gray-900 font-medium">
                        Really delete "{invoice.id}"?
                    </div>
                </Row>
            </Section>
        </FormDialog>
    )
}

export default DeleteInvoiceDialog;
