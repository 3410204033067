import * as React from "react"
import {useEffect} from "react"
import {graphql} from "react-relay";
import LoadMoreButton from "./presentational/LoadMoreButton";
import ContactListItem from "./ContactListItem";
import {usePaginationFragment} from "react-relay/hooks";

type ContactListProps = {
    queryRef: any
    searchTerm?: string
}

const ContactList: React.FC<ContactListProps> = (props) => {
    const {data, hasNext, loadNext, isLoadingNext, refetch} = usePaginationFragment(
        graphql`
            fragment ContactList_query on Query
            @argumentDefinitions(
                #                cursor: { type: "String" }
                first: { type: "Int", defaultValue: 100 }
                after: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "ContactsPaginationQuery") {
                contacts(first: $first, after: $after, searchTerm: $searchTerm)
                @connection(key: "ContactList_contacts") {
                    totalCount
                    edges {
                        node {
                            ...ContactListItem_contact
                        }
                    }
                }
            }
        `,
        props.queryRef
    );

    useEffect(() => {
        refetch({searchTerm: props.searchTerm}, {fetchPolicy: 'store-and-network'});
    }, [props.searchTerm, refetch])

    if (!data.contacts || data.contacts.totalCount === 0) {
        return (<></>);
    }

    return (
        <nav className="flex-1 min-h-0 relative overflow-y-auto" aria-label="Contacts">
            <ul className="relative z-0 divide-y divide-gray-200">
                {data.contacts.edges.map(
                    (edge, index) => {
                        return <ContactListItem key={index} contact={edge.node}/>
                    }
                )}
            </ul>
            {hasNext && <LoadMoreButton onClick={() => loadNext(100)} isLoadingNext={isLoadingNext}/>}
        </nav>
    )
}

export default ContactList;
