/**
 * @generated SignedSource<<7182b0e559c51bb2c51d141dad839df6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateSubscriptionInput = {
  activateAt: string;
  billingProfileIdentifier?: string | null;
  customerAccountIdentifier: string;
  customerOrderNotes?: string | null;
  orderedAt: string;
  purchaseOrderReference?: string | null;
  settlementType: string;
  subscriptionServiceIdentifier: string;
  subscriptionServiceReference: string;
  subscriptionServiceReferenceLabel?: string | null;
  subscriptionServiceReferenceUri?: string | null;
};
export type CreateSubscriptionDialogMutation$variables = {
  input: CreateSubscriptionInput;
};
export type CreateSubscriptionDialogMutation$data = {
  readonly createSubscription: {
    readonly id: string;
  };
};
export type CreateSubscriptionDialogMutation = {
  response: CreateSubscriptionDialogMutation$data;
  variables: CreateSubscriptionDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "createSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateSubscriptionDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateSubscriptionDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "be2f0809e40b95bb1519b15cc8a48657",
    "id": null,
    "metadata": {},
    "name": "CreateSubscriptionDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CreateSubscriptionDialogMutation(\n  $input: CreateSubscriptionInput!\n) {\n  createSubscription(input: $input) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ad21b096c3e00f2ed501bbaba662a0db";

export default node;
