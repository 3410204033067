/**
 * @generated SignedSource<<35b00f558ea3026a1ff55b38f73ed043>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CancelSubscriptionInput = {
  id: string;
};
export type CancelSubscriptionDialogMutation$variables = {
  input: CancelSubscriptionInput;
};
export type CancelSubscriptionDialogMutation$data = {
  readonly cancelSubscription: {
    readonly expiresAt: any | null;
    readonly id: string;
    readonly state: string;
  };
};
export type CancelSubscriptionDialogMutation = {
  response: CancelSubscriptionDialogMutation$data;
  variables: CancelSubscriptionDialogMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Subscription",
    "kind": "LinkedField",
    "name": "cancelSubscription",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "state",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "expiresAt",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CancelSubscriptionDialogMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CancelSubscriptionDialogMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d0283b2d8886e8681a5583daeb5bdc46",
    "id": null,
    "metadata": {},
    "name": "CancelSubscriptionDialogMutation",
    "operationKind": "mutation",
    "text": "mutation CancelSubscriptionDialogMutation(\n  $input: CancelSubscriptionInput!\n) {\n  cancelSubscription(input: $input) {\n    id\n    state\n    expiresAt\n  }\n}\n"
  }
};
})();

(node as any).hash = "05b5f81f27d29aecf93d18182d05bd5a";

export default node;
