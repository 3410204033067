import * as React from "react";
import {connect, ConnectedProps} from "react-redux";
import {useOutletContext} from "react-router";
import {hideModal, showModal} from "../store/actions";
import NoData from "./presentational/NoData";
import {useRefetchableFragment} from "react-relay/hooks";
import {graphql} from "react-relay";
import DetailedPaymentMethodList from "./DetailedPaymentMethodList";
import Panel from "./presentational/Panel";
import SetUpStripePaymentMethodDialog from "./dialog/SetUpStripePaymentMethodDialog";
import {faUsers} from "@fortawesome/pro-regular-svg-icons";
import ActionButton from "./presentational/button/ActionButton";
import SetUpOnAccountPaymentMethodDialog from "./dialog/SetUpOnAccountPaymentMethodDialog";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type CustomerPaymentViewProps = Props & ConnectedProps<typeof connector>;

const CustomerPaymentView: React.FC<CustomerPaymentViewProps> = (props) => {
    const queryRef: any = useOutletContext();
    const {dispatchShowModal, dispatchHideModal} = props
    const [customerAccount] = useRefetchableFragment(
        graphql`
            fragment CustomerPaymentView_customerAccount on CustomerAccount
            @refetchable(queryName: "CustomerPaymentViewRefreshQuery") {
                id
                customerName
                defaultPaymentMethod {
                    id
                }
                paymentMethods {
                    id
                }
                ...DetailedPaymentMethodList_customerAccount
            }
        `,
        queryRef
    );

    const showSetUpOnAccountPaymentMethodDialog = () => {
        dispatchShowModal({
            body: (<SetUpOnAccountPaymentMethodDialog handleCloseDialog={dispatchHideModal} customerAccountIdentifier={customerAccount.id}/>)
        })
    };

    const showSetUpStripePaymentMethodDialog = () => {
        dispatchShowModal({
            body: (<SetUpStripePaymentMethodDialog handleCloseDialog={dispatchHideModal} customerAccountIdentifier={customerAccount.id}/>)
        })
    };

    return (
        <Panel headline="Payment Methods" fullWidthChildren={true}>
            {customerAccount.paymentMethods.length === 0 &&
                <NoData
                    icon={faUsers}
                    headline="No payment methods found"
                    description="Set up a credit card or SEPA direct debit mandate for this customer"
                />
            }
            {customerAccount.paymentMethods.length > 0 && (
                <DetailedPaymentMethodList queryRef={customerAccount}/>
            )}
            <div className="text-center py-10">
                <ActionButton onClick={e => showSetUpStripePaymentMethodDialog()} label="Add card or SEPA"/>
                <ActionButton onClick={e => showSetUpOnAccountPaymentMethodDialog()} label="Add on account"/>
            </div>
        </Panel>
    )
}

export default connector(CustomerPaymentView);
