import React from 'react';
import {CabanaConfiguration} from "./CabanaConfiguration";

const CabanaConfigurationContext = React.createContext<CabanaConfiguration>({
    auth0: {
        domain: '',
        clientId: '',
        redirectUri: '',
        audience: '',
    },
    graphql: {
        uri: ''
    },
    sentry: {
        dsn: '',
        environment: '',
    },
    stripe: {
        publishableKey: '',
    }
});

export default CabanaConfigurationContext;
