import * as React from "react";
import {FieldAttributes} from "formik/dist/Field";
import {Field, useFormikContext} from "formik";

type NumberFieldProps = {}

const NumberField: React.FC<FieldAttributes<NumberFieldProps>> = (props) => {
    const {value, ...attributes} = props
    const form = useFormikContext()
    let preparedValue = value ?? ''

    if (typeof value === 'number') {
        preparedValue = value.toString().replace('.', ',')
    }

    const handleOnBlur = ((event) => {
        const updatedValue = parseFloat(event.currentTarget.value.replace(',', '.'))
        if (isNaN(updatedValue)) {
            form.setFieldValue(event.currentTarget.name, null)
        } else {
            form.setFieldValue(event.currentTarget.name, updatedValue)
        }
    })

    return (
        <Field value={preparedValue} type="text" onBlur={handleOnBlur} {...attributes} />
    );
}

export default NumberField;
