import * as React from "react";
import {useOutletContext} from "react-router";
import Panel from "./presentational/Panel";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import AddContactDialog from "./dialog/AddContactDialog";
import {faUsers} from '@fortawesome/pro-duotone-svg-icons'
import NoData from "./presentational/NoData";
import DetailedContactList from "./DetailedContactList";
import ActionButton from "./presentational/button/ActionButton";
import {useFragment} from "react-relay/hooks";
import {graphql} from "react-relay";

type Props = {}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type CustomerContactsViewProps = Props & ConnectedProps<typeof connector>;

const CustomerContactsView: React.FC<CustomerContactsViewProps> = (props) => {
    const queryRef: any = useOutletContext();
    const customerAccount = useFragment(
        graphql`
            fragment CustomerContactsView_customerAccount on CustomerAccount {
                id
                customerName
                contacts {
                    totalCount
                    edges {
                        roles {
                            roleIdentifier
                        }
                        node {
                            id
                            __typename
                        }
                    }
                }
                ...DetailedContactList_customerAccount
                ...AddContactDialog_customerAccount
            }
        `,
        queryRef
    );

    const {dispatchShowModal, dispatchHideModal} = props
    const showAddContactDialog = (customerAccount) => {
        dispatchShowModal({
            body: (<AddContactDialog customerAccount={customerAccount} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    const personContacts = customerAccount.contacts.edges.filter((edge) => {
        return (edge && edge.node && edge.node.__typename === 'PersonContact')
    });
    const teamContacts = customerAccount.contacts.edges.filter((edge) => {
        return (edge && edge.node && edge.node.__typename === 'TeamContact')
    });

    return (
        <Panel headline="Customer Contacts">
            {customerAccount.contacts.totalCount === 0 &&
                <NoData
                    icon={faUsers}
                    headline="No contacts"
                    description="Assign the first contact to this customer."
                    newButtonLabel="Add contact"
                    onNewButton={e => showAddContactDialog(customerAccount)}
                />
            }
            {personContacts.length > 0 &&
                <Panel headline="People">
                    <DetailedContactList queryRef={customerAccount} typeNameFilter="PersonContact"/>
                </Panel>
            }
            {teamContacts.length > 0 &&
                <Panel headline="Teams">
                    <DetailedContactList queryRef={customerAccount} typeNameFilter="TeamContact"/>
                </Panel>
            }
            {customerAccount.contacts.totalCount > 0 &&
                <div className="text-center py-10">
                    <ActionButton onClick={e => showAddContactDialog(customerAccount)} label="Add contact"/>
                </div>
            }
        </Panel>
    )
}

export default connector(CustomerContactsView);
