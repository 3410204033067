/**
 * @generated SignedSource<<ec1ee8cc4f45113e9b5264270353cabe>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AddContactDialogContactsQuery$variables = {
  searchTerm: string;
};
export type AddContactDialogContactsQuery$data = {
  readonly contacts: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly emailAddress: string | null;
        readonly familyName?: string;
        readonly givenName?: string;
        readonly id: string;
        readonly teamName?: string;
      } | null;
    } | null> | null;
    readonly totalCount: number | null;
  } | null;
};
export type AddContactDialogContactsQuery = {
  response: AddContactDialogContactsQuery$data;
  variables: AddContactDialogContactsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Literal",
        "name": "first",
        "value": 2
      },
      {
        "kind": "Variable",
        "name": "searchTerm",
        "variableName": "searchTerm"
      }
    ],
    "concreteType": "CustomerAccountContactConnection",
    "kind": "LinkedField",
    "name": "contacts",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "totalCount",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CustomerAccountContactEdge",
        "kind": "LinkedField",
        "name": "edges",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "teamName",
                    "storageKey": null
                  }
                ],
                "type": "TeamContact",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "givenName",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "familyName",
                    "storageKey": null
                  }
                ],
                "type": "PersonContact",
                "abstractKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emailAddress",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddContactDialogContactsQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddContactDialogContactsQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "166f92574c9d78e162bd83504f572751",
    "id": null,
    "metadata": {},
    "name": "AddContactDialogContactsQuery",
    "operationKind": "query",
    "text": "query AddContactDialogContactsQuery(\n  $searchTerm: String!\n) {\n  contacts(searchTerm: $searchTerm, first: 2) {\n    totalCount\n    edges {\n      node {\n        __typename\n        id\n        ... on TeamContact {\n          teamName\n        }\n        ... on PersonContact {\n          givenName\n          familyName\n        }\n        emailAddress\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "82a79e0100cd9901521d13c94e5ff387";

export default node;
