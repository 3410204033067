import * as React from "react";
import {createContext, ReactElement, SetStateAction, useEffect, useState} from "react";
import {Form, Formik} from "formik";
import {FormikValues} from "formik/dist/types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil, faX} from "@fortawesome/pro-light-svg-icons";
import {AnyObjectSchema} from "yup";
import ActionButton from "../presentational/button/ActionButton";

type EditablePropertiesProps = {
    label: string
    fields?: ReactElement<{ values: FormikValues }>
    initialValues: object
    validationSchema?: AnyObjectSchema
    handleSubmission: (
        values: FormikValues,
        setEditingActive: SetStateAction<any>
    ) => void | Promise<any>
}

const EditableDataListItem: React.FC<React.PropsWithChildren<EditablePropertiesProps>> = ({label, fields, initialValues, validationSchema, handleSubmission, children}) => {

    const [editingActive, setEditingActive] = useState(false)

    const escapeListener = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            setEditingActive(false)
        }
    }

    useEffect(() => {
        document.addEventListener("keydown", escapeListener, false);
        return () => {
            document.removeEventListener("keydown", escapeListener, false);
        };
    }, []);

    const FormikValuesContext = createContext({});

    if (editingActive) {
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values) => {
                    handleSubmission(values, setEditingActive)
                }}
                validationSchema={validationSchema}
            >
                {({isSubmitting, dirty, values}) => (
                    <Form className="group py-1 sm:grid sm:grid-cols-3 sm:gap-4 cursor-pointer sm:py-2 bg-gray-100">
                        <dt className="text-sm font-medium text-gray-500 sm:py-3">{label}</dt>
                        <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                            <div className="grow">
                                <FormikValuesContext.Provider value={values}>
                                    {fields}
                                </FormikValuesContext.Provider>
                            </div>
                            <div className="ml-4 shrink-0">
                                <ActionButton isSubmitting={isSubmitting} disabled={!dirty} label="Save"/>
                                <button type="button" disabled={isSubmitting} onClick={e => setEditingActive(false)} className="py-2 px-4 text-sm font-medium text-gray-700">
                                    <FontAwesomeIcon icon={faX}/>
                                </button>
                            </div>
                        </dd>
                    </Form>
                )}
            </Formik>
        );
    }

    return (
        <div className="group py-1 sm:grid sm:grid-cols-3 sm:gap-4 cursor-pointer sm:pt-2 hover:bg-gray-10" onClick={() => {
            setEditingActive(true)
        }}>
            <dt className="text-sm font-medium text-gray-500">{label}</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className="grow">{children}</span>
                <span className="mx-4 shrink-0 invisible group-hover:visible">
                    <FontAwesomeIcon icon={faPencil}/>
                </span>
            </dd>
        </div>
    )
}

export default EditableDataListItem;
