/**
 * @generated SignedSource<<5a89c065d64beda29f2ad9d1aa9a4cc4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvoiceType = "REGULAR" | "REVERSE" | "%future added value";
export type TaxType = "GROSS" | "INTRA_COMMUNITY_SERVICE" | "NET" | "THIRD_PARTY_COUNTRY_SERVICE" | "VAT_FREE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvoiceLineItemsTable_invoice$data = {
  readonly id: string;
  readonly language: string;
  readonly lineItems: ReadonlyArray<{
    readonly description: string;
    readonly price: number | null;
    readonly quantity: number | null;
    readonly taxRatePercentage: number | null;
    readonly unitLabel: string | null;
  } | null>;
  readonly taxType: TaxType;
  readonly type: InvoiceType;
  readonly " $fragmentType": "InvoiceLineItemsTable_invoice";
};
export type InvoiceLineItemsTable_invoice$key = {
  readonly " $data"?: InvoiceLineItemsTable_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceLineItemsTable_invoice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceLineItemsTable_invoice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "language",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "taxType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "InvoiceLineItem",
      "kind": "LinkedField",
      "name": "lineItems",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "quantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "unitLabel",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "price",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxRatePercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};

(node as any).hash = "38e3e6e061c460d8b891a2a0a5dd200f";

export default node;
