/**
 * @generated SignedSource<<c007f20dafec9147775593ce865b2a62>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteCustomerDialog_customerAccount$data = {
  readonly customerName: string;
  readonly id: string;
  readonly " $fragmentType": "DeleteCustomerDialog_customerAccount";
};
export type DeleteCustomerDialog_customerAccount$key = {
  readonly " $data"?: DeleteCustomerDialog_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeleteCustomerDialog_customerAccount">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeleteCustomerDialog_customerAccount",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};

(node as any).hash = "1ecf52573a6938e6912d285f1d009352";

export default node;
