import * as React from "react";

const Label = (props) => {
    return (
        <label className="block font-medium text-gray-900 py-3">
            {props.children}
        </label>

    )
}
export default Label;
