import * as React from "react"
import {useEffect} from "react"
import {graphql} from "react-relay";
import {usePaginationFragment} from "react-relay/hooks";
import LoadMoreButton from "./presentational/LoadMoreButton";
import ActionButton from "./presentational/button/ActionButton";
import {faFileMagnifyingGlass, faPencil, faTrashCan} from "@fortawesome/pro-regular-svg-icons";
import DeleteInvoiceDialog from "./dialog/DeleteInvoiceDialog";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import TableHeader from "./presentational/table/TableHeader";
import Table from "./presentational/table/Table";
import TableData, {TextAlignment} from "./presentational/table/TableData";
import {resolvePath, useNavigate} from "react-router-dom";
import Dinero from "dinero.js";
import {FormattedDate} from "react-intl";
import StatusBadge from "./presentational/StatusBadge";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});
type Props = {
    queryRef: any // FIXME: Any type we can define here? It's actually: Readonly<{ ' $data'?: unknown; ' $fragmentRefs': unknown; }> | null
    searchTerm?: string
}

type InvoiceListProps = ConnectedProps<typeof connector> & Props;

const InvoiceList: React.FC<InvoiceListProps> = (props) => {
    const {dispatchShowModal, dispatchHideModal} = props
    const navigate = useNavigate();
    const {data, hasNext, loadNext, isLoadingNext, refetch} = usePaginationFragment(
        graphql`
            fragment InvoiceList_query on Query
            @argumentDefinitions(
                first: { type: "Int", defaultValue: 100 }
                after: { type: "String" }
                searchTerm: { type: "String", defaultValue: "" }
            )
            @refetchable(queryName: "InvoicesPaginationQuery") {
                invoices(first: $first, after: $after, searchTerm: $searchTerm, sort: CREATED_AT_DESC)
                @connection(key: "InvoiceList_invoices") {
                    totalCount
                    edges {
                        node {
                            id
                            invoiceNumber
                            state
                            invoiceDate
                            createdAt
                            totalGrossAmount
                            customerAccount {
                                id
                                customerName
                            }
                            ...DeleteInvoiceDialog_invoice
                        }
                    }
                }
            }
        `,
        props.queryRef
    );

    useEffect(() => {
        refetch({searchTerm: props.searchTerm}, {fetchPolicy: 'store-and-network'});
    }, [props.searchTerm, refetch])

    if (!data.invoices || data.invoices.totalCount === 0) {
        return (<></>);
    }

    return (
        <div className="flex-1 min-h-0 relative overflow-y-auto" aria-label="Invoices">
            <Table>
                <thead>
                    <tr>
                        <TableHeader className="hidden sm:table-cell">State</TableHeader>
                        <TableHeader className="hidden sm:table-cell">Invoice Date</TableHeader>
                        <TableHeader>Invoice No.</TableHeader>
                        <TableHeader>Customer</TableHeader>
                        <TableHeader textAlignment={TextAlignment.Right}>Invoice Total</TableHeader>
                        <TableHeader className="hidden sm:table-cell"/>
                    </tr>
                </thead>
                <tbody>
                    {data.invoices.edges.map(
                        (edge, index) => {
                            if (!edge.node) { // why can this happen?
                                return null;
                            }
                            const invoice = edge.node;
                            const {customerAccount} = invoice;
                            const {customerName} = customerAccount;
                            const showDeleteInvoiceDialog = () => {
                                dispatchShowModal({
                                    body: (<DeleteInvoiceDialog handleCloseDialog={dispatchHideModal} queryRef={invoice}/>)
                                })
                            };

                            const totalGrossAmount = Dinero({amount: invoice.totalGrossAmount})
                            return (
                                <tr key={index} className={(index % 2 === 0 ? 'bg-white' : 'bg-gray-50') + ' hover:bg-gray-100 group'}>
                                    <TableData className="hidden sm:table-cell">
                                        <StatusBadge value={invoice.state} states={{
                                            green: ['PAID', 'REFUNDED'],
                                            yellow: ['OPEN', 'VOID', 'REVERSED'],
                                            red: ['UNCOLLECTIBLE'],
                                            gray: ['DRAFT', 'INVOICE_NUMBER_PENDING']
                                        }}/>
                                    </TableData>
                                    <TableData className="hidden sm:table-cell"><FormattedDate value={edge.node.invoiceDate} day="2-digit" month="2-digit" year="numeric"/></TableData>
                                    <TableData>{invoice.state === 'DRAFT' ? '-' : invoice.invoiceNumber  }</TableData>
                                    <TableData>{customerName}</TableData>
                                    <TableData textAlignment={TextAlignment.Right}>
                                        {totalGrossAmount.toFormat()}
                                    </TableData>
                                    <TableData textAlignment={TextAlignment.Right}>
                                        {invoice.state === 'DRAFT' &&
                                            <>
                                                <ActionButton additionalClassname="invisible group-hover:visible" icon={faTrashCan} title="Delete invoice" onClick={() => {
                                                    showDeleteInvoiceDialog()
                                                }}/>
                                                <ActionButton additionalClassname="invisible group-hover:visible" icon={faPencil} title="Edit invoice" onClick={(event) => {
                                                    let to = "/invoices/" + invoice.id;
                                                    if (invoice.state === 'DRAFT') {
                                                        to += "/edit";
                                                    }
                                                    if (event.shiftKey || event.metaKey) {
                                                        event.preventDefault()
                                                        window.open(resolvePath(to).pathname)
                                                    } else {
                                                        navigate(to)
                                                    }
                                                }}/>
                                            </>
                                        }
                                        {invoice.state !== 'DRAFT' &&
                                            <ActionButton additionalClassname="invisible group-hover:visible" icon={faFileMagnifyingGlass} title="Show invoice" onClick={(event) => {
                                                let to = "/invoices/" + invoice.id;
                                                if (event.shiftKey || event.metaKey) {
                                                    event.preventDefault()
                                                    window.open(resolvePath(to).pathname)
                                                } else {
                                                    navigate(to)
                                                }
                                            }}/>
                                        }
                                    </TableData>
                                </tr>
                            )
                        }
                    )}
                </tbody>
            </Table>
            {hasNext && <LoadMoreButton onClick={() => loadNext(100)} isLoadingNext={isLoadingNext}/>}
        </div>
    )
}

export default connector(InvoiceList);
