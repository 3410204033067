import * as React from "react";
import {faAt, faGear, faPhone} from "@fortawesome/pro-regular-svg-icons";
import ActionButton from "./presentational/button/ActionButton";
import DataTypeIcon from "./presentational/DataTypeIcon";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import RemoveContactDialog from "./dialog/RemoveContactDialog";
import {graphql} from "react-relay";
import UpdateContactRolesDialog from "./dialog/UpdateContactRolesDialog";
import {useFragment} from "react-relay/hooks";

type Role = {
    roleIdentifier: string
    label: {
        en: string
        de: string
    }
}

type DetailedContactListItemConnectedProps = {
    contact: any
    roles: Role[]
    customerAccount: any
    index?: number
}

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type DetailedContactListItemProps = DetailedContactListItemConnectedProps & ConnectedProps<typeof connector>

const DetailedContactListItem: React.FC<DetailedContactListItemProps> = (props) => {

    const contact = useFragment(
        graphql`
            fragment DetailedContactListItem_contact on Contact {
                id
                __typename
                ... on PersonContact {
                    givenName
                    familyName
                }
                ... on TeamContact {
                    teamName
                }
                emailAddress
                phoneNumber
                ...RemoveContactDialog_contact
                ...UpdateContactRolesDialog_contact
            }
        `,
        props.contact
    );

    const customerAccount = useFragment(
        graphql`
            fragment DetailedContactListItem_customerAccount on CustomerAccount {
                ...RemoveContactDialog_customerAccount
                ...UpdateContactRolesDialog_customerAccount
            }
        `,
        props.customerAccount
    );

    const {id, teamName, givenName, familyName, emailAddress, phoneNumber} = contact

    const {dispatchShowModal, dispatchHideModal} = props;
    const showRemoveContactDialog = () => {
        dispatchShowModal({
            body: (<RemoveContactDialog contact={contact} customerAccount={customerAccount} handleCloseDialog={dispatchHideModal}/>)
        })
    };
    const showUpdateContactRolesDialog = () => {
        dispatchShowModal({
            body: (<UpdateContactRolesDialog customerAccount={customerAccount} contact={contact} roles={props.roles} handleCloseDialog={dispatchHideModal}/>)
        })
    };

    return (
        <li className={"flex items-center px-4 py-4 sm:px-6" + (props.index && props.index % 2 ? " bg-gray-50" : "")}>
            <div className="min-w-0 flex-1 flex items-center">
                <div className="shrink-0">
                        <span className="inline-flex items-center justify-center h-12 w-12 rounded-full bg-gray-500">
                           <span className="text-xs font-medium leading-none text-white">{teamName ? teamName.substr(0, 1) : familyName?.substr(0, 1)}</span>
                        </span>
                </div>
                <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                        <p className="text-md font-medium text-gray-900 truncate">{teamName ? teamName : givenName + " " + familyName}</p>
                        <ul>
                            {props.roles && props.roles.map((role, index) => {
                                return <li className="inline-flex items-center mr-1 px-2 rounded text-sm font-medium bg-gray-800 bg-opacity-10 text-gray-800" key={index}>{role.label.en}</li>
                            })}
                            <ActionButton onClick={showUpdateContactRolesDialog} icon={faGear} title="Edit roles"/>
                        </ul>
                    </div>
                    <div className="hidden md:block">
                        <p className="flex items-center text-sm text-gray-900">
                            <DataTypeIcon icon={faAt} title="Email address"/>
                            <span className="truncate">{emailAddress}</span>
                        </p>
                        {phoneNumber &&
                            <p className="flex items-center text-sm text-gray-900">
                                <DataTypeIcon icon={faPhone} title="Phone number"/>
                                <span className="truncate">{phoneNumber}</span>
                            </p>
                        }
                    </div>
                </div>
            </div>
            <div>
                <ActionButton onClick={showRemoveContactDialog} label="Remove"/>
                <ActionButton linkTo={"/contacts/" + id} label="Show"/>
            </div>
        </li>
    )
}

export default connector(DetailedContactListItem)
