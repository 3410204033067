/**
 * @generated SignedSource<<3d841ef41f7b4a4a318730dc5cc4021b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type FinalInvoiceViewRefreshPdfMutation$variables = {
  id: string;
};
export type FinalInvoiceViewRefreshPdfMutation$data = {
  readonly downloadInvoicePdf: {
    readonly filename: string;
    readonly uri: string;
  } | null;
};
export type FinalInvoiceViewRefreshPdfMutation = {
  response: FinalInvoiceViewRefreshPdfMutation$data;
  variables: FinalInvoiceViewRefreshPdfMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "TemporaryDownload",
    "kind": "LinkedField",
    "name": "downloadInvoicePdf",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "uri",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "filename",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FinalInvoiceViewRefreshPdfMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FinalInvoiceViewRefreshPdfMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "a9bf32bc34f6a77b911a215d6cbffb96",
    "id": null,
    "metadata": {},
    "name": "FinalInvoiceViewRefreshPdfMutation",
    "operationKind": "mutation",
    "text": "mutation FinalInvoiceViewRefreshPdfMutation(\n  $id: ID!\n) {\n  downloadInvoicePdf(input: {id: $id}) {\n    uri\n    filename\n  }\n}\n"
  }
};
})();

(node as any).hash = "a236e2d75e5372a8c6e4a2abbd596e93";

export default node;
