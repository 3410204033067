import * as React from "react";
import {useState} from "react";
import Panel from "./presentational/Panel";
import {loadQuery, usePreloadedQuery} from "react-relay/hooks";
import {graphql} from "react-relay";
import Headline from "./presentational/Headline";
import SettlementList from "./SettlementList";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import {connect, ConnectedProps} from "react-redux";
import {hideModal, showModal} from "../store/actions";
import type {SettlementsViewSettlementsQuery as SettlementsViewSettlementsQueryType} from "../__relay_artifacts__/SettlementsViewSettlementsQuery.graphql";
import SettlementsViewSettlementsQuery from "../__relay_artifacts__/SettlementsViewSettlementsQuery.graphql";
import ListSearch from "./presentational/ListSearch";

const connector = connect(undefined, {
    dispatchShowModal: showModal,
    dispatchHideModal: hideModal
});

type Props = {
    prepared: any
};

type SettlementsViewProps = ConnectedProps<typeof connector> & Props;

const SettlementsView: React.FC<SettlementsViewProps> = (props) => {
    const [searchTerm, setSearchTerm] = useState('');

    const settlements = usePreloadedQuery<SettlementsViewSettlementsQueryType>(
        graphql`
            query SettlementsViewSettlementsQuery {
                ...SettlementList_query
            }
        `,
        props.prepared.settlementsQuery,
    );

    const searchAPIDebounced = AwesomeDebouncePromise(
        searchTerm => {
            setSearchTerm(searchTerm)
        },
        500
    );

    const handleSearchTermChange = async (searchTerm: string) => {
        searchAPIDebounced(searchTerm);
    };

    return (
        <div className="lg:mt-6 mx-auto lg:px-8 grid grid-cols-1 gap-x-4 gap-y-8">
            <Panel>
                <div className="px-6 pt-6 pb-4">
                    <div className="md:flex md:items-center md:justify-between">
                        <div className="flex-1 min-w-0">
                            <Headline>Settlements</Headline>
                        </div>
                    </div>
                    <ListSearch handleSearchTermChange={handleSearchTermChange} placeholder="Search Settlements"/>
                </div>
                <SettlementList queryRef={settlements} searchTerm={searchTerm}/>
            </Panel>
        </div>
    )
}

export default connector(SettlementsView);

function prepare_SettlementsView(params, relayEnvironment) {
    return {
        settlementsQuery: loadQuery(
            relayEnvironment,
            SettlementsViewSettlementsQuery,
            {},
            {fetchPolicy: 'store-and-network'},
        ),
    }
}

export {prepare_SettlementsView};
