/**
 * @generated SignedSource<<453d61c5270113a7e4d0c91798489705>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubscriptionsPaginationQuery$variables = {
  after?: string | null;
  first?: number | null;
  searchTerm?: string | null;
};
export type SubscriptionsPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"SubscriptionList_query">;
};
export type SubscriptionsPaginationQuery = {
  response: SubscriptionsPaginationQuery$data;
  variables: SubscriptionsPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 100,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": "",
    "kind": "LocalArgument",
    "name": "searchTerm"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "searchTerm",
  "variableName": "searchTerm"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  {
    "kind": "Literal",
    "name": "sort",
    "value": "ORDERED_AT_DESC"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubscriptionsPaginationQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "SubscriptionList_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubscriptionsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "SubscriptionConnection",
        "kind": "LinkedField",
        "name": "subscriptions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "totalCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "SubscriptionEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Subscription",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SubscriptionService",
                    "kind": "LinkedField",
                    "name": "subscriptionService",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionServiceReferenceLabel",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "CustomerAccount",
                    "kind": "LinkedField",
                    "name": "customerAccount",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customerName",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "customerNumber",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "state",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionServiceReference",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "subscriptionServiceReferenceUri",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orderedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "activatedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "filters": [
          "searchTerm",
          "sort"
        ],
        "handle": "connection",
        "key": "SubscriptionList_subscriptions",
        "kind": "LinkedHandle",
        "name": "subscriptions"
      }
    ]
  },
  "params": {
    "cacheID": "389e131a6f78410b2a7b7615b5f9f3a1",
    "id": null,
    "metadata": {},
    "name": "SubscriptionsPaginationQuery",
    "operationKind": "query",
    "text": "query SubscriptionsPaginationQuery(\n  $after: String\n  $first: Int = 100\n  $searchTerm: String = \"\"\n) {\n  ...SubscriptionList_query_2s884C\n}\n\nfragment SubscriptionListItem_subscription on Subscription {\n  id\n  state\n  customerAccount {\n    customerName\n    customerNumber\n    id\n  }\n  subscriptionService {\n    name\n    id\n  }\n  subscriptionServiceReference\n  subscriptionServiceReferenceLabel\n}\n\nfragment SubscriptionList_query_2s884C on Query {\n  subscriptions(first: $first, after: $after, searchTerm: $searchTerm, sort: ORDERED_AT_DESC) {\n    totalCount\n    edges {\n      node {\n        ...TerminateSubscriptionDialog_subscription\n        ...SubscriptionListItem_subscription\n        id\n        state\n        customerAccount {\n          id\n          customerName\n          customerNumber\n        }\n        subscriptionService {\n          id\n          name\n        }\n        subscriptionServiceReference\n        subscriptionServiceReferenceLabel\n        subscriptionServiceReferenceUri\n        orderedAt\n        activatedAt\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment TerminateSubscriptionDialog_subscription on Subscription {\n  id\n  subscriptionService {\n    name\n    id\n  }\n  subscriptionServiceReferenceLabel\n  customerAccount {\n    customerName\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "299e0a635ae366e6dc9284800d47c07d";

export default node;
