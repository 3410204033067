/**
 * @generated SignedSource<<d78126ab61d07242d880852f5b27b28d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type InvoiceType = "REGULAR" | "REVERSE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type InvoiceHeader_invoice$data = {
  readonly customerAccount: {
    readonly customerName: string;
  };
  readonly id: string;
  readonly invoiceNumber: string | null;
  readonly relatedInvoiceIdentifier: string | null;
  readonly type: InvoiceType;
  readonly " $fragmentType": "InvoiceHeader_invoice";
};
export type InvoiceHeader_invoice$key = {
  readonly " $data"?: InvoiceHeader_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"InvoiceHeader_invoice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InvoiceHeader_invoice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "CustomerAccount",
      "kind": "LinkedField",
      "name": "customerAccount",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customerName",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "relatedInvoiceIdentifier",
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};

(node as any).hash = "42d04d6eaf87f168455b3ab2e3470fab";

export default node;
