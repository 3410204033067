import * as React from "react";

const Row = (props) => {
    return (
        <div className="space-y-1 px-4">
            {props.children}
        </div>
    )
};

export default Row;
