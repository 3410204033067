/**
 * @generated SignedSource<<f32cf2e144e993303c608bac1167504a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InitiateInvoicePaymentDialog_invoice$data = {
  readonly id: string;
  readonly invoiceNumber: string | null;
  readonly paymentMethod: {
    readonly cardBrand: string | null;
    readonly lastFourDigits: string | null;
    readonly paymentMethodType: string;
  };
  readonly sentAt: any | null;
  readonly " $fragmentType": "InitiateInvoicePaymentDialog_invoice";
};
export type InitiateInvoicePaymentDialog_invoice$key = {
  readonly " $data"?: InitiateInvoicePaymentDialog_invoice$data;
  readonly " $fragmentSpreads": FragmentRefs<"InitiateInvoicePaymentDialog_invoice">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitiateInvoicePaymentDialog_invoice",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "paymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodType",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "cardBrand",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lastFourDigits",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sentAt",
      "storageKey": null
    }
  ],
  "type": "Invoice",
  "abstractKey": null
};

(node as any).hash = "537c9008aa09cd1627276373f178efb4";

export default node;
