import * as React from "react";
import {commitMutation, graphql} from "react-relay";
import Section from "../form/Section";
import Row from "../form/Row";
import FormDialog from "../dialog/FormDialog";
import {useFragment, useRelayEnvironment} from "react-relay/hooks";
import Hint from "../presentational/Hint";
import {FormattedDate} from "react-intl";

type SendInvoiceDialogProps = {
    queryRef: any,
    handleCloseDialog: () => {}
}

const SendInvoiceDialog: React.FC<SendInvoiceDialogProps> = (props) => {

    const invoice = useFragment(
        graphql`
            fragment SendInvoiceDialog_invoice on Invoice {
                id
                sentAt
            }
        `,
        props.queryRef
    );

    const relayEnvironment = useRelayEnvironment();

    const handleSubmission = (input) => {
        commitMutation(
            relayEnvironment,
            {
                mutation: graphql`
                    mutation SendInvoiceDialogMutation(
                        $input: SendInvoiceInput!
                    ) {
                        sendInvoice(input: $input) {
                            id
                            updatedAt
                        }
                    }
                `,
                variables: {
                    input: input
                },
                onCompleted: (response, errors) => {
                    if (errors && errors.length > 0) {
                        console.error(errors);
                    }
                    props.handleCloseDialog();
                },
                onError: (error) => {
                    console.error(error);
                    props.handleCloseDialog();
                }
            }
        )
    }

    const initialValues = {
        id: invoice.id
    }

    return (
        <FormDialog handleCloseDialog={props.handleCloseDialog} handleSubmission={handleSubmission} initialValues={initialValues} headline="Send Invoice" submitLabel="Send">
            <Section>
                <Row>
                    <div className="text-gray-900">
                        <p>Send this invoice to the configured recipients?</p>
                    </div>
                    {invoice.sentAt && (
                        <Hint className="py-4">
                            This invoice was already sent on <FormattedDate value={invoice.sentAt}/>.
                        </Hint>
                    )}
                </Row>
            </Section>
        </FormDialog>
    )
}

export default SendInvoiceDialog;
