import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular} from '@fortawesome/fontawesome-svg-core/import.macro'
import Link from "./Link";

type BreadcrumbNavigationItem = {
    label: string
    to: string
}

type BreadcrumbNavigationProps = {
    items: BreadcrumbNavigationItem[]
}

const BreadcrumbNavigation: React.FC<BreadcrumbNavigationProps> = ({items}) => {
    return (
        <div>
            <div className="max-w-5xl mx-auto py-4 sm:px-2 lg:px-4">
                <nav className="flex shrink-0 relative z-10" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-4">
                        {items.map((item, index) => (
                            <li key={item.label}>
                                <div className="flex items-center">
                                    {index > 0 && <FontAwesomeIcon icon={regular("chevron-right")} className="shrink-0 h-3 text-gray-400" aria-hidden="true"/>}
                                    <Link
                                        key={item.to}
                                        to={item.to}
                                        className={'ml-4 text-sm text-gray-400 hover:text-gray-700'}
                                    >{item.label}</Link>
                                </div>
                            </li>
                        ))}
                    </ol>
                </nav>
            </div>
        </div>
    )
}

export default BreadcrumbNavigation;
