import * as React from "react";

const CabanaLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
    return (
        <svg
            viewBox="0 0 950 292"
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit={2}
            fill="#ffffff"
            strokeWidth="0"
            {...props}
        >
            <g id="g10">
                <g>
                    <path id="path18" d="M38.121,37.458l-0,17.338l50.637,-0l0,25.667l-50.637,-0l-0,43.354l-32.771,-0l-0,-115.496l92.771,-0l-0,29.137l-60,0Z"/>
                    <rect id="path20" x="109.925" y="0" width="31.733" height="123.813"/>
                    <g id="g24">
                        <path id="path32" d="M204.963,54.104c-11.613,0 -17.688,9.196 -17.688,22.892c0,13.7 6.075,22.712 17.688,22.712c11.791,0 17.687,-9.012 17.687,-22.712c0,-13.696 -5.896,-22.892 -17.687,-22.892m-0,71.792c-31.213,-0 -51.33,-19.071 -51.33,-48.729c0,-29.825 20.117,-49.075 51.33,-49.075c31.212,-0 51.329,19.25 51.329,49.075c-0,29.658 -20.117,48.729 -51.329,48.729"/>
                        <path id="path36" d="M374.746,123.817l-31.383,-0l-13.875,-53.413l-14.221,53.413l-31.388,-0l-25.841,-93.813l34.862,0l9.883,53.404l14.221,-53.404l25.317,0l14.046,53.404l10.058,-53.404l34.683,0l-26.362,93.813Z"/>
                        <path id="path40" d="M469.275,123.817l0,-55.667c0,-6.412 -2.254,-12.312 -11.962,-12.312c-7.288,-0 -13.705,4.337 -13.705,14.05l0,53.929l-31.733,-0l0,-93.813l31.733,0l0,8.667c7.113,-6.763 16.825,-10.579 26.363,-10.579c21.675,-0 31.042,15.433 31.042,32.775l-0,62.95l-31.738,-0Z"/>
                        <path id="path44"
                              d="M567.958,80.983c-2.775,3.121 -7.458,3.471 -13.004,4.334c-5.033,0.87 -10.754,2.429 -10.754,9.541c0,6.067 4.167,8.667 10.404,8.667c8.15,-0 13.354,-4.508 13.354,-13.525l0,-9.017Zm26.705,44.221c-8.846,0 -16.996,-3.3 -20.459,-10.579c-6.075,8.15 -17.867,11.271 -28.442,11.271c-19.075,-0 -32.77,-10.229 -32.77,-29.479c-0,-23.063 19.762,-26.359 34.854,-27.917c13.354,-1.392 20.112,-2.083 20.112,-7.979c0,-5.896 -7.108,-6.938 -12.483,-6.938c-9.017,0 -20.983,2.946 -29.829,8.321l-9.538,-23.754c13.529,-7.108 27.396,-10.058 41.967,-10.058c24.796,-0 41.617,8.675 41.617,33.471l-0,38.149c-0,3.121 1.562,3.813 3.812,3.813c1.738,0 3.3,-0.342 4.342,-1.213l3.116,19.251c-3.987,2.599 -11.266,3.641 -16.3,3.641"/>
                        <path id="path48" d="M687.971,116.704c-6.938,5.375 -16.129,9.192 -28.438,9.192c-12.487,-0 -22.025,-3.817 -27.75,-11.792c-4.333,-6.071 -5.895,-13.008 -5.895,-22.717l-0,-37.804l-14.217,0l-0,-23.583l14.217,-0l-0,-21.675l31.737,-0l0,21.675l25.146,-0l-0,23.583l-25.146,0l0,37.975c0,5.554 3.646,6.938 7.629,6.938c4.509,-0 8.15,-1.734 11.271,-4.334l11.446,22.542Z"/>
                        <path id="path50" d="M701.163,30l31.733,0l-0,93.813l-31.733,-0l-0,-93.813Zm-0,-30l31.733,0l-0,20.292l-31.733,-0l-0,-20.292Z"/>
                        <path id="path54" d="M811.458,123.817l-32.258,-0l-35.896,-93.813l35.384,0l16.987,57.046l16.65,-57.046l35.029,0l-35.896,93.813Z"/>
                        <path id="path58" d="M896.613,50.808c-9.538,0 -16.296,4.855 -17.167,14.913l34.162,-0c-0.691,-9.888 -7.454,-14.913 -16.995,-14.913m47.687,33.992l-64.854,0c0.692,10.054 8.671,15.083 20.117,15.083c10.575,0 18.9,-4.333 24.45,-10.404l20.637,18.034c-10.579,10.404 -25.317,18.383 -46.996,18.383c-34.854,-0 -52.371,-20.813 -52.371,-48.383c0,-28.438 18.559,-49.421 50.638,-49.421c30.862,-0 48.729,19.25 48.729,48.383c-0,2.95 -0.179,6.067 -0.35,8.325"/>
                    </g>
                </g>
            </g>
            <path d="M109.713,197.171c-11.83,-20.233 -30.005,-28.463 -50.238,-28.463c-33.779,0 -57.267,23.663 -57.267,58.984c0,35.491 23.488,59.325 57.438,59.325c23.491,-0 40.467,-11.488 50.754,-30.692l-22.633,-11.833c-6.517,12.175 -14.746,18.862 -27.609,18.862c-18.516,0 -29.833,-15.433 -29.833,-35.837c-0,-20.059 11.317,-35.146 29.833,-35.146c12.004,-0 21.092,5.312 27.95,17.316l21.605,-12.516Z"/>
            <path
                d="M174.696,251.179c-0,11.488 -7.371,16.804 -16.975,16.804c-7.546,0 -12.858,-3.258 -12.858,-10.287c-0,-8.229 6.516,-9.946 13.029,-10.975c7.204,-1.2 13.033,-1.542 16.804,-6l-0,10.458Zm-43.721,-29.833c8.571,-5.658 19.717,-8.571 28.975,-8.571c6.517,0 14.746,1.713 14.746,8.742c-0,6.687 -7.888,7.375 -22.292,8.746c-14.229,1.37 -33.604,6.17 -33.604,28.291c0,18.346 13.029,28.463 31.721,28.463c10.629,-0 22.462,-3.259 28.975,-12.346c2.746,8.058 10.633,11.658 19.375,11.658c4.287,0 10.633,-0.858 14.233,-3.087l-2.746,-16.459c-1.029,0.859 -2.741,1.2 -4.458,1.2c-3.254,0 -4.8,-1.371 -4.8,-5.658l0,-39.092c0,-23.321 -15.946,-31.891 -39.262,-31.891c-14.234,-0 -27.092,3.258 -39.609,10.458l8.746,19.546Z"/>
            <path d="M225.275,162.533l0,122.425l26.233,0l0,-10.458c7.546,8.4 16.975,12.517 28.121,12.517c25.721,-0 41.667,-20.746 41.667,-47.838c-0,-27.262 -15.946,-47.837 -41.667,-47.837c-11.146,-0 -20.575,4.112 -28.121,12.516l0,-41.325l-26.233,0Zm26.233,76.817c0,-13.717 7.375,-25.375 21.434,-25.375c14.062,0 20.575,11.658 20.575,25.375c-0,13.717 -6.513,25.033 -20.575,25.033c-14.229,0 -21.434,-11.145 -21.434,-25.033Z"/>
            <path
                d="M387.479,251.179c0,11.488 -7.375,16.804 -16.975,16.804c-7.546,0 -12.858,-3.258 -12.858,-10.287c-0,-8.229 6.512,-9.946 13.029,-10.975c7.2,-1.2 13.033,-1.542 16.804,-6l0,10.458Zm-43.725,-29.833c8.575,-5.658 19.721,-8.571 28.979,-8.571c6.517,0 14.746,1.713 14.746,8.742c0,6.687 -7.887,7.375 -22.291,8.746c-14.23,1.37 -33.605,6.17 -33.605,28.291c0,18.346 13.03,28.463 31.721,28.463c10.629,-0 22.459,-3.259 28.975,-12.346c2.742,8.058 10.629,11.658 19.375,11.658c4.288,0 10.629,-0.858 14.234,-3.087l-2.746,-16.459c-1.029,0.859 -2.742,1.2 -4.459,1.2c-3.258,0 -4.8,-1.371 -4.8,-5.658l0,-39.092c0,-23.321 -15.945,-31.891 -39.262,-31.891c-14.233,-0 -27.092,3.258 -39.608,10.458l8.741,19.546Z"/>
            <path d="M464.292,284.958l-0,-52.125c-0,-11.829 8.058,-18.175 17.833,-18.175c11.658,0 14.229,7.888 14.229,15.605l0,54.695l26.238,0l-0,-60.696c-0,-18.52 -10.117,-32.92 -30.867,-32.92c-10.458,-0 -20.229,4.458 -27.433,12.516l-0,-10.458l-26.234,-0l0,91.558l26.234,0Z"/>
            <path
                d="M593.233,251.179c0,11.488 -7.375,16.804 -16.975,16.804c-7.546,0 -12.862,-3.258 -12.862,-10.287c-0,-8.229 6.516,-9.946 13.033,-10.975c7.2,-1.2 13.029,-1.542 16.804,-6l0,10.458Zm-43.725,-29.833c8.575,-5.658 19.717,-8.571 28.98,-8.571c6.512,0 14.745,1.713 14.745,8.742c0,6.687 -7.887,7.375 -22.291,8.746c-14.234,1.37 -33.609,6.17 -33.609,28.291c0,18.346 13.034,28.463 31.721,28.463c10.634,-0 22.463,-3.259 28.979,-12.346c2.742,8.058 10.63,11.658 19.375,11.658c4.288,0 10.63,-0.858 14.23,-3.087l-2.742,-16.459c-1.029,0.859 -2.746,1.2 -4.458,1.2c-3.259,0 -4.8,-1.371 -4.8,-5.658l-0,-39.092c-0,-23.321 -15.946,-31.891 -39.267,-31.891c-14.229,-0 -27.092,3.258 -39.608,10.458l8.745,19.546Z"/>
        </svg>
    )
};

export default CabanaLogo;
