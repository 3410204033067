import React from 'react';
import {connect, ConnectedProps} from 'react-redux';
import {RootState} from "../../store/reducers";
import {hideModal} from "../../store/actions";
import * as ReactDOM from "react-dom";

const mapStateToProps = (state: RootState) => ({
    modal: state.modal.modal,
});

const mapDispatchToProps = {
    dispatchHideModal: hideModal,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type ModalProps = {} & ConnectedProps<typeof connector>;

class Modal extends React.Component<ModalProps> {
    private readonly el: HTMLDivElement;
    private readonly modalInnerRef: React.RefObject<HTMLDivElement>;
    private readonly dispatchHideModal: CallableFunction;

    constructor(props) {
        super(props);
        this.el = document.createElement('div');
        this.modalInnerRef = React.createRef();
        this.dispatchHideModal = props.dispatchHideModal;
    }

    private escapeListener = (e: KeyboardEvent) => {
        if (this.props.modal && e.key === 'Escape') {
            this.dispatchHideModal();
        }
    }

    componentDidMount() {
        const modalRoot = document.getElementById('modal-root');
        if (modalRoot) {
            modalRoot.appendChild(this.el);
            document.addEventListener('keyup', this.escapeListener);
            // document.addEventListener('click', this.clickListener);
        }
    }

    componentWillUnmount() {
        const modalRoot = document.getElementById('modal-root');
        if (modalRoot) {
            modalRoot.removeChild(this.el);
            document.removeEventListener('keyup', this.escapeListener);
            // document.removeEventListener('click', this.clickListener);
        }
    }

    render() {
        if (!this.props.modal) {
            return null;
        }

        const body = ReactDOM.createPortal(this.props.modal.body, this.el);
        return (
            <div>
                {body}
            </div>
        );
    }
}

export default connector(Modal);
