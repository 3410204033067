/**
 * @generated SignedSource<<68d6fe6f2ec64c3acc7963a2a5cc982a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CustomerPaymentView_customerAccount$data = {
  readonly customerName: string;
  readonly defaultPaymentMethod: {
    readonly id: string;
  } | null;
  readonly id: string;
  readonly paymentMethods: ReadonlyArray<{
    readonly id: string;
  }>;
  readonly " $fragmentSpreads": FragmentRefs<"DetailedPaymentMethodList_customerAccount">;
  readonly " $fragmentType": "CustomerPaymentView_customerAccount";
};
export type CustomerPaymentView_customerAccount$key = {
  readonly " $data"?: CustomerPaymentView_customerAccount$data;
  readonly " $fragmentSpreads": FragmentRefs<"CustomerPaymentView_customerAccount">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./CustomerPaymentViewRefreshQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "CustomerPaymentView_customerAccount",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "defaultPaymentMethod",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PaymentMethod",
      "kind": "LinkedField",
      "name": "paymentMethods",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DetailedPaymentMethodList_customerAccount"
    }
  ],
  "type": "CustomerAccount",
  "abstractKey": null
};
})();

(node as any).hash = "f140a8ff2dbb19390caa6f770b7eadc0";

export default node;
