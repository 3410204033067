import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import * as React from "react";
import {CSSProperties} from "react";
import {faTriangleExclamation} from "@fortawesome/pro-duotone-svg-icons";

type WarningProps = {
    className?: string
}

const Warning: React.FC<React.PropsWithChildren<WarningProps>> = (props) => {
    const {className, children} = props;
    const iconStyle = {
        "--fa-primary-color": 'rgb(0,0,0)',
        "--fa-secondary-color": 'rgb(253, 224, 71)',
        "--fa-secondary-opacity": 1.0,
    }
    return (
        <div className={"flex " + className ?? ""}>
            <div className="shrink-0">
                <FontAwesomeIcon icon={faTriangleExclamation} className="h-5 w-5" style={iconStyle as CSSProperties}/>
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-gray-700">
                    {children}
                </p>
            </div>
        </div>
    );
}

export default Warning;
