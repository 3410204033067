import * as React from "react";
import {ErrorMessage, useField} from 'formik';


const asFormikField = (WrappedComponent, fieldName) => {
    return (props) => {
        const fieldProps = useField(fieldName);
        const fieldMetaProps = fieldProps[1];
        const fieldHelperProps = fieldProps[2];

        return (
            <>
                <WrappedComponent initialValue={fieldMetaProps.value} currentValue={fieldMetaProps.value} onValueChange={fieldHelperProps.setValue} {...props} />
                <ErrorMessage name={fieldName} component="p" className="mt-2 text-sm text-red-600"/>
            </>
        )
    }
}

export default asFormikField;
