import * as React from "react";
import {createFragmentContainer, graphql} from "react-relay";
import {ProjectBillingMode, ProjectHeader_project$data} from "../__relay_artifacts__/ProjectHeader_project.graphql";
import StatusBadge from "./presentational/StatusBadge";

type ProjectHeaderProps = {
    project: ProjectHeader_project$data,
};

const getBillingModeLabel = (billingMode: ProjectBillingMode): string => {
    switch (billingMode) {
        case 'TIME_AND_MATERIALS':
            return 'Time and materials';
        case 'FIXED_FEE':
            return 'Fixed fee';
        default:
            return 'Non-billable';
    }
}

const ProjectHeader: React.FC<ProjectHeaderProps> = ({project}) => {
    return (
        <div className="mt-6 max-w-5xl mx-auto">
            <div className="ml-4 mt-4 flex flex-wrap items-center justify-between">
                <div className="ml-4 flex items-center">
                    <div>
                        <h2 className="text-xl font-medium text-gray-700">{project.customerAccount.customerName}</h2>
                        <h1 className="text-2xl font-bold text-gray-900">
                            {project.projectName}
                            <StatusBadge
                                value={project.billingMode}
                                states={{gray: ['NON_BILLABLE'], green: ['TIME_AND_MATERIALS', 'FIXED_FEE']}}
                                label={getBillingModeLabel(project.billingMode)}
                                size="base"
                                className="mx-2"
                            />
                        </h1>
                        <p className="text-sm font-medium text-gray-500">{project.id}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default createFragmentContainer(ProjectHeader, {
        project: graphql`
            fragment ProjectHeader_project on Project {
                id
                projectName
                projectNumber
                customerAccount {
                    customerName
                }
                billingMode
            }
        `
    }
);
